import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DataGrid, ErrorAlert } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { z } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridColDef, GridActionsColDef } from '@mui/x-data-grid-premium';
import { AreaAutocomplete } from '../../../components/Form/AreaAutocomplete';
import { CategoryAutocomplete } from '../../../components/Form/CategoryAutocomplete';
import { DepartmentAutocomplete } from '../../../components/Form/DepartmentAutocomplete';
import { ProcessAutocomplete } from '../../../components/Form/ProcessAutocomplete';
import { ScenarioAutocomplete } from '../../../components/Form/ScenarioAutocomplete';
import { SubprocessAutocomplete } from '../../../components/Form/SubprocessAutocomplete';
import { UserAutocomplete } from '../../../components/Form/UserAutocomplete';
import { AreaSchema } from '../../../entities/Area';
import { CategorySchema } from '../../../entities/Category';
import { DepartmentSchema } from '../../../entities/Department';
import { ProcessSchema, ProcessTaskForm } from '../../../entities/Process';
import { ScenarioSchema } from '../../../entities/Scenario';
import { SubprocessSchema } from '../../../entities/Subprocess';
import { Task, TaskCreateRequestSchema, TaskSchema } from '../../../entities/Task';
import { UserRoleName } from '../../../entities/User';
import { useAreaColDef } from '../../../hooks/useAreaColDef';
import { useCategoryColDef } from '../../../hooks/useCategoryColDef';
import { useDepartmentColDef } from '../../../hooks/useDepartmentColDef';
import { useProcessColDef } from '../../../hooks/useProcessColDef';
import { useScenarioColDef } from '../../../hooks/useScenarioColDef';
import { useSubprocessColDef } from '../../../hooks/useSubprocessColDef';
import { useUsersColDefs } from '../../../hooks/useUsersColDefs';
import { useBatchUpdateTasksMutation } from '../../../services/taskApi';
import { useReadUserListQuery } from '../../../services/userApi';

type TaskBatchEditDialogProps = Omit<DialogProps, 'onClose'> & {
  tasks: Task[];
  onClose: (value?: ProcessTaskForm) => void;
  onClosed: () => void;
  open: boolean;
};

const message = 'Field required';

const BatchTaskFormSchema = z
  .object({
    area: AreaSchema.optional(),
    category: CategorySchema.optional(),
    department: DepartmentSchema.optional(),
    process: ProcessSchema.optional(),
    scenario: ScenarioSchema.optional(),
    ownerUsername: z.string().optional(),
    backupUsername: z.string().optional(),
    reviewerUsername: z.string().optional(),
    subprocess: SubprocessSchema.optional(),
    enableArea: z.boolean(),
    enableOwner: z.boolean(),
    enableCategory: z.boolean(),
    enableDepartment: z.boolean(),
    enableBackup: z.boolean(),
    enableProcess: z.boolean(),
    enableReviewer: z.boolean(),
    enableScenario: z.boolean(),
    enableSubprocess: z.boolean(),
  })
  .refine((form) => !form.enableArea || form.area, { message, path: ['area'] })
  .refine((form) => !form.enableDepartment || form.department, {
    message,
    path: ['department'],
  })
  .refine((form) => !form.enableProcess || form.process, { message, path: ['process'] })
  .refine((form) => !form.enableSubprocess || form.subprocess, { message, path: ['subprocess'] })
  .refine((form) => (!form.enableProcess && !form.enableScenario) || form.scenario, { message, path: ['scenario'] })
  .refine((form) => (!form.enableProcess && !form.enableCategory) || form.category, { message, path: ['category'] })
  .refine((form) => !form.enableOwner || form.ownerUsername, {
    message,
    path: ['ownerUsername'],
  })
  .refine((form) => !form.enableBackup || form.backupUsername, {
    message,
    path: ['backupUsername'],
  })
  .refine((form) => !form.enableReviewer || form.reviewerUsername, {
    message,
    path: ['reviewerUsername'],
  });

type BatchTaskForm = z.infer<typeof BatchTaskFormSchema>;

export function TaskBatchEditDialog(props: TaskBatchEditDialogProps): JSX.Element {
  const { onClose, onClosed, tasks, ...dialogProps } = props;
  const form = useForm<BatchTaskForm>({
    defaultValues: {
      enableArea: false,
      enableOwner: false,
      enableCategory: false,
      enableDepartment: false,
      enableBackup: false,
      enableProcess: false,
      enableReviewer: false,
      enableScenario: false,
      enableSubprocess: false,
    },
    resolver: zodResolver(BatchTaskFormSchema),
  });

  const { control, watch } = form;
  const categoryColumn = useCategoryColDef();
  const scenarioColumn = useScenarioColDef();
  const areaColumn = useAreaColDef();
  const departmentColumn = useDepartmentColDef();
  const processColumn = useProcessColDef();
  const subprocessColumn = useSubprocessColDef();
  const { ownerColumn, backupColumn, reviewerColumn } = useUsersColDefs<Task>();

  const userList = useReadUserListQuery();

  const [batchUpdateTasks, batchUpdateTasksStatus] = useBatchUpdateTasksMutation();

  const process = watch('process');
  const subprocess = watch('subprocess');
  const area = watch('area');
  const department = watch('department');
  const scenario = watch('scenario');
  const category = watch('category');
  const ownerUsername = watch('ownerUsername');
  const backupUsername = watch('backupUsername');
  const reviewerUsername = watch('reviewerUsername');

  const enableProcess = watch('enableProcess');
  const enableSubprocess = watch('enableSubprocess');
  const enableArea = watch('enableArea');
  const enableDepartment = watch('enableDepartment');
  const enableScenario = watch('enableScenario') || enableProcess;
  const enableCategory = watch('enableCategory') || enableProcess;
  const enableOwner = watch('enableOwner');
  const enableBackup = watch('enableBackup');
  const enableReviewer = watch('enableReviewer');

  const { hasRole, username } = useAuth();

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      {
        field: 'id',
        headerName: '#',
        width: 70,
      },
      {
        field: 'description',
        headerName: 'Description',
        minWidth: 200,
        flex: 1,
      },
      {
        ...processColumn,
        valueGetter: (value, row: Task) => row.process?.id ?? '—',
        cellClassName: enableProcess ? 'edited' : undefined,
      },
      {
        ...subprocessColumn,
        valueGetter: (value, row: Task) => row.subprocess?.id ?? '—',
        cellClassName: enableSubprocess ? 'edited' : undefined,
      },
      {
        ...scenarioColumn,
        valueGetter: (value, row: Task) => row.scenario?.id ?? '—',
        cellClassName: enableScenario || enableProcess ? 'edited' : undefined,
      },
      {
        ...categoryColumn,
        valueGetter: (value, row: Task) => row.category?.name ?? '—',
        cellClassName: enableCategory || enableProcess ? 'edited' : undefined,
      },
      {
        ...areaColumn,
        valueGetter: (value, row: Task) => row.area?.id ?? '—',
        cellClassName: enableArea ? 'edited' : undefined,
      },
      {
        ...departmentColumn,
        valueGetter: (value, row: Task) => row.department?.id ?? '—',
        cellClassName: enableDepartment ? 'edited' : undefined,
      },
      { ...ownerColumn((row: Task) => row.owner), cellClassName: enableOwner ? 'edited' : undefined },
      { ...backupColumn((row: Task) => row.backup), cellClassName: enableBackup ? 'edited' : undefined },
      { ...reviewerColumn((row: Task) => row.reviewer), cellClassName: enableReviewer ? 'edited' : undefined },
    ],
    [
      processColumn,
      enableProcess,
      subprocessColumn,
      enableSubprocess,
      scenarioColumn,
      enableScenario,
      categoryColumn,
      enableCategory,
      areaColumn,
      enableArea,
      departmentColumn,
      enableDepartment,
      ownerColumn,
      enableOwner,
      backupColumn,
      enableBackup,
      reviewerColumn,
      enableReviewer,
    ],
  );

  const title = `Apply batch changes to ${tasks.length} tasks`;

  const editedRows: Task[] = [];
  for (let i = 0; i < tasks.length; i++) {
    const row = tasks[i];

    editedRows.push({
      ...row,
      process: enableProcess ? process ?? row.process : row.process,
      subprocess: enableSubprocess ? subprocess ?? row.subprocess : row.subprocess,
      area: enableArea ? area ?? row.area : row.area,
      department: enableDepartment ? department ?? row.department : row.department,
      scenario: enableScenario ? scenario ?? row.scenario : row.scenario,
      category: enableCategory ? category ?? row.category : row.category,
      owner: enableOwner ? userList.data?.find((u) => u.username === ownerUsername) ?? row.owner : row.owner,
      backup: enableBackup ? userList.data?.find((u) => u.username === backupUsername) ?? row.backup : row.backup,
      reviewer: enableReviewer
        ? userList.data?.find((u) => u.username === reviewerUsername) ?? row.reviewer
        : row.reviewer,
    });
  }

  const onSubmit = async () => {
    const updateRows = TaskCreateRequestSchema.extend({ id: TaskSchema.shape.id })
      .array()
      .parse(
        editedRows.map((row) => ({
          ...row,
          processId: row.process.id,
          subprocessId: row.subprocess?.id,
          categoryId: row.category?.id,
          areaId: row.area.id,
          departmentId: row.department?.id,
          scenarioId: row.scenario?.id,
          ownerUsername: row.owner?.username,
          backupUsername: row.backup?.username,
          reviewerUsername: row.reviewer?.username,
        })),
      );

    await batchUpdateTasks(updateRows);

    onClose();
  };

  return (
    <Dialog
      maxWidth="xl"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        onExited: () => {
          onClosed();
          form.reset();
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2} rowSpacing={1} paddingTop={2}>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableProcess"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={Boolean(value)} {...field} sx={{ marginTop: 0.75 }} />
                )}
              />
              <Controller
                control={control}
                name="process"
                render={({ field, fieldState: { error } }) => (
                  <ProcessAutocomplete
                    sx={{ minWidth: '20ch' }}
                    error={Boolean(error)}
                    helperText={error?.message}
                    fullWidth
                    processManager={hasRole(UserRoleName.MANAGER) && username ? username : undefined}
                    {...field}
                    disabled={!enableProcess}
                    onChange={(_, process) => field.onChange(process)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableSubprocess"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={Boolean(value)} {...field} sx={{ marginTop: 0.75 }} />
                )}
              />
              <Controller
                control={control}
                name="subprocess"
                render={({ field, fieldState: { error } }) => (
                  <SubprocessAutocomplete
                    error={Boolean(error)}
                    helperText={error?.message ?? ' '}
                    fullWidth
                    {...field}
                    onChange={(_, subprocess) => {
                      field.onChange(subprocess);
                    }}
                    disabled={!enableSubprocess}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableArea"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={Boolean(value)} {...field} sx={{ marginTop: 0.75 }} />
                )}
              />
              <Controller
                control={control}
                name="area"
                render={({ field, fieldState: { error } }) => (
                  <AreaAutocomplete
                    error={Boolean(error)}
                    helperText={error?.message ?? ' '}
                    fullWidth
                    {...field}
                    onChange={(_, area) => {
                      field.onChange(area);
                    }}
                    disabled={!enableArea}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableDepartment"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={Boolean(value)} {...field} sx={{ marginTop: 0.75 }} />
                )}
              />
              <Controller
                control={control}
                name="department"
                render={({ field, fieldState: { error } }) => (
                  <DepartmentAutocomplete
                    error={Boolean(error)}
                    helperText={error?.message ?? ' '}
                    fullWidth
                    {...field}
                    disabled={!enableDepartment}
                    onChange={(_, department) => {
                      field.onChange(department);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableScenario"
                render={({ field: { value, ...field } }) => (
                  <Checkbox
                    checked={Boolean(value) || enableProcess}
                    {...field}
                    disabled={enableProcess}
                    sx={{ marginTop: 0.75 }}
                  />
                )}
              />
              <Controller
                control={control}
                name="scenario"
                render={({ field, fieldState: { error } }) => (
                  <ScenarioAutocomplete
                    error={Boolean(error)}
                    helperText={error?.message ?? ' '}
                    process={process}
                    sx={{ minWidth: '20ch' }}
                    fullWidth
                    {...field}
                    disabled={!process || !enableScenario}
                    onChange={(_, scenario) => {
                      field.onChange(scenario);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableCategory"
                render={({ field: { value, ...field } }) => (
                  <Checkbox
                    checked={Boolean(value) || enableProcess}
                    {...field}
                    disabled={enableProcess}
                    sx={{ marginTop: 0.75 }}
                  />
                )}
              />
              <Controller
                control={control}
                name="category"
                render={({ field, fieldState: { error } }) => (
                  <CategoryAutocomplete
                    error={Boolean(error)}
                    helperText={error?.message}
                    process={process}
                    sx={{ minWidth: '20ch' }}
                    fullWidth
                    {...field}
                    disabled={!process || !enableCategory}
                    onChange={(_, category) => {
                      field.onChange(category);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} gutterBottom marginTop={1}>
                {'Assignees'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableOwner"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={Boolean(value)} {...field} sx={{ marginTop: 0.75 }} />
                )}
              />
              <Controller
                control={control}
                name="ownerUsername"
                render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                  <UserAutocomplete
                    label="Owner"
                    fullWidth
                    error={Boolean(error)}
                    onChange={(_, value) => onChange(value as string)}
                    helperText={error?.message || ' '}
                    {...field}
                    disabled={!enableOwner}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableBackup"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={Boolean(value)} {...field} sx={{ marginTop: 0.75 }} />
                )}
              />
              <Controller
                control={control}
                name="backupUsername"
                render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                  <UserAutocomplete
                    label="Backup"
                    fullWidth
                    error={Boolean(error)}
                    onChange={(_, value) => onChange(value as string)}
                    helperText={error?.message || ' '}
                    {...field}
                    disabled={!enableBackup}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} display="flex" alignItems="flex-start" gap={1}>
              <Controller
                control={control}
                name="enableReviewer"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={Boolean(value)} {...field} sx={{ marginTop: 0.75 }} />
                )}
              />
              <Controller
                control={control}
                name="reviewerUsername"
                render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                  <UserAutocomplete
                    label="Reviewer"
                    fullWidth
                    error={Boolean(error)}
                    onChange={(_, value) => onChange(value as string)}
                    helperText={error?.message || ' '}
                    {...field}
                    disabled={!enableReviewer}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography fontWeight={500} marginTop={1} marginBottom={1}>
            {'Changes preview'}
          </Typography>
          <Box sx={{ height: 200 }}>
            <DataGrid
              columns={columns}
              rows={editedRows ?? []}
              density="compact"
              disableAggregation
              pagination={false}
              disableColumnMenu
              disableColumnReorder
              disableRowGrouping
              slots={{
                footer: () => null,
              }}
              sx={{
                '.edited, .edited button': {
                  fontWeight: 500,
                  color: 'error.dark',
                },
              }}
            />
          </Box>
          {batchUpdateTasksStatus.error && <ErrorAlert error={batchUpdateTasksStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => onClose()} sx={{ marginRight: 'auto' }}>
            {'Cancel'}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={batchUpdateTasksStatus.isLoading}
            disabled={
              ![
                enableProcess,
                enableSubprocess,
                enableArea,
                enableDepartment,
                enableScenario,
                enableCategory,
                enableOwner,
                enableBackup,
                enableReviewer,
              ].some(Boolean)
            }
          >
            {'Apply changes'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
