import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadTurnbackListQuery } from '../../services/turnbackApi';

type TurnbackSelectProps = Omit<TextFieldProps, 'select'> & {
  disableClearable?: boolean;
};

function TurnbackSelectComponent(props: TurnbackSelectProps, ref: React.Ref<unknown>) {
  const { value, disabled, error, helperText, label, disableClearable, ...textFieldProps } = props;
  const turnbackList = useReadTurnbackListQuery();

  const apiError = turnbackList.error as ServerError | undefined;

  const list = useMemo(() => {
    const list: JSX.Element[] = [];
    if (!disableClearable || !turnbackList.data?.length) {
      list.push(
        <MenuItem value="" key="EMPTY">
          &nbsp;
        </MenuItem>,
      );
    }
    if (turnbackList.data?.length) {
      return list.concat(
        turnbackList.data.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        )),
      );
    }
    return list;
  }, [turnbackList.data, disableClearable]);

  return (
    <TextField
      select
      SelectProps={{ ref }}
      value={value && turnbackList.data?.length ? value : ''}
      disabled={disabled || turnbackList?.data?.length === 0 || turnbackList.isError || turnbackList.isFetching}
      error={error || Boolean(apiError)}
      label={label ?? 'Turnback'}
      helperText={helperText || apiError?.data?.message || apiError?.message}
      {...textFieldProps}
    >
      {list}
    </TextField>
  );
}

export const TurnbackSelect = forwardRef(TurnbackSelectComponent);
