import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { GanttTask, Task } from './Task';
import { User } from './User';
import { Timetable } from './UserTimetable';

export interface FromTo {
  from: Date;
  to: Date;
}

export interface UserPeriodMetrics {
  period: FromTo;
  user: User;
  userTimetable: Timetable;
  // -----
  tasks: GanttTask[];
  onTimeTasks: GanttTask[];
  completedTasks: GanttTask[];
  actualTasksDuration: Record<Task['id'], number>;
  scheduledTasksDuration: Record<Task['id'], number>;
  // -----
  metrics: {
    userWorkingSeconds: number;
    tasksActualDuration: number;
    tasksScheduledDuration: number;
    load: number;
    onTime: number;
  };
}

export interface PeopleLoadRow extends GridValidRowModel {
  id: string;
  periods: Record<string, UserPeriodMetrics>;
}

export enum PeopleLoadMetric {
  Load = 'load',
  OnTime = 'on_time',
}

export const PeopleLoadMetricNames = {
  [PeopleLoadMetric.Load]: 'Load',
  [PeopleLoadMetric.OnTime]: 'On time',
};

export enum PeopleLoadTimeSample {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export const PeopleLoadTimeSampleNames = {
  [PeopleLoadTimeSample.Daily]: 'Daily',
  [PeopleLoadTimeSample.Weekly]: 'Weekly',
  [PeopleLoadTimeSample.Monthly]: 'Monthly',
};
