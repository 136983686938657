import React from 'react';
import { Day } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Timetable } from '../entities/UserTimetable';
import { sortedDaysOfWeek } from '../pages/Admin/Task/TaskEditDialog/ScheduleForm/utils';
import { formatDuration, formatHour } from '../utils/date';
import { weekDayWorkingSeconds } from '../utils/users';

interface UserTimetableTableProps {
  timetable: Timetable;
  showOnlyDay?: number;
}

function UserTimetableTableComponent(props: UserTimetableTableProps) {
  const { timetable, showOnlyDay } = props;

  const days = showOnlyDay === undefined ? sortedDaysOfWeek : sortedDaysOfWeek.filter((d) => d.index === showOnlyDay);

  return (
    <TableContainer component={Paper} variant="outlined" sx={{ marginTop: 0.5 }}>
      <Table aria-label="timetable" size="small">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row"></TableCell>
            <TableCell align="right">{'Morning'}</TableCell>
            <TableCell align="right">{'Afternoon'}</TableCell>
            <TableCell align="right">{'Working time'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {days.map(({ index, day }) => {
            const dayTimetable = timetable[index];
            const workingSeconds = weekDayWorkingSeconds(timetable[index]);

            return timetable && dayTimetable ? (
              <TableRow key={day} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: 500 }}>
                  {enGB.localize?.day(dayTimetable.day as Day)}
                </TableCell>
                <TableCell align="right">{`${
                  dayTimetable.startMorning ? `${formatHour(dayTimetable.startMorning)}` : '—'
                }${dayTimetable.endMorning ? ` - ${formatHour(dayTimetable.endMorning)}` : ''}`}</TableCell>
                <TableCell align="right">{`${
                  dayTimetable.startAfternoon ? ` ${formatHour(dayTimetable.startAfternoon)}` : '—'
                }${dayTimetable.endAfternoon ? ` - ${formatHour(dayTimetable.endAfternoon)}` : ''}`}</TableCell>
                <TableCell align="right">{formatDuration(workingSeconds)}</TableCell>
              </TableRow>
            ) : null;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const UserTimetableTable = React.memo(UserTimetableTableComponent);
