import React, { memo, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { enGB } from 'date-fns/locale/en-GB';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Title } from '../Title';
import { days } from './DaySelector';

interface WorkDaySelectorProps {
  value: number | null;
  onChange: (workDay: number) => void;
  error: FieldError | undefined;
}

function WorkDaySelectorComponent(props: WorkDaySelectorProps) {
  const { onChange, value, error } = props;

  const [day, setDay] = useState(value ? Math.abs(value) : null);
  const [sign, setSign] = useState(value ? (value < 0 ? -1 : 1) : 1);

  useEffect(() => {
    if (day) {
      onChange(day * sign);
    }
  }, [day, onChange, sign]);

  return (
    <Stack direction="column">
      <Title title={'Workday:'}></Title>
      <Stack direction="column" sx={{ width: '100%', gap: 2 }}>
        <Stack direction="row" alignItems="center" gap={1} flex="0 0 auto">
          <Typography>{'On the '}</Typography>
          <Autocomplete
            disablePortal
            options={days}
            value={day ?? undefined}
            onChange={(e, value) => setDay(value)}
            renderInput={(params) => (
              <TextField {...params} placeholder="#" size="small" sx={{ input: { textAlign: 'right' } }} />
            )}
            getOptionLabel={(option) => enGB.localize?.ordinalNumber(option)}
            disableClearable
            ListboxProps={{
              style: {
                maxHeight: 200,
              },
            }}
            sx={{
              width: '10ch',
            }}
          />
          <Typography component="span">{'working day'}</Typography>
          <TextField select label="" value={sign} onChange={(e) => setSign(Number(e.target.value))} size="small">
            <MenuItem value={'1'}>{'from the start'}</MenuItem>
            <MenuItem value={'-1'}>{'from the end'}</MenuItem>
          </TextField>
          <Typography component="span">{' of the month'}</Typography>
        </Stack>
      </Stack>
      {error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </Stack>
  );
}

export const WorkDaySelector = memo(WorkDaySelectorComponent);
