import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Scenario } from '../entities/Scenario';

interface ScenarioLabelProps extends BoxProps {
  scenario: Pick<Scenario, 'color' | 'name'> | null | undefined;
}

function ScenarioLabelComponent(props: ScenarioLabelProps) {
  const { scenario, ...stackProps } = props;
  if (!scenario) {
    return <Typography sx={{ color: 'text.disabled' }}>{'—'}</Typography>;
  }
  return (
    <Box {...stackProps}>
      <Box
        sx={{
          background: `#${scenario.color}`,
          height: '1em',
          width: '1em',
          marginRight: 0.5,
          borderRadius: 0.5,
          display: 'inline-flex',
          alignSelf: 'center',
          top: '.125em',
          position: 'relative',
        }}
      />
      {scenario.name}
    </Box>
  );
}

export const ScenarioLabel = React.memo(ScenarioLabelComponent);
