import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { GanttTask } from '../entities/Task';
import { Turnback } from '../entities/Turnback';
import { useReadTurnbackListQuery } from '../services/turnbackApi';
import { emptyFilterValueOption } from '../utils/datagrid';

export function useTurnbackColDef(): GridColDef {
  const { data: turnbackList } = useReadTurnbackListQuery();

  const turnbackMap = useMemo(() => {
    const turnbackMap = new Map<Turnback['id'], Turnback>();
    if (turnbackList) {
      for (let i = 0; i < turnbackList.length; i++) {
        turnbackMap.set(turnbackList[i].id, turnbackList[i]);
      }
    }
    return turnbackMap;
  }, [turnbackList]);

  return {
    field: 'turnback',
    headerName: 'Turnback',
    type: 'singleSelect',
    valueOptions: turnbackList
      ? [emptyFilterValueOption].concat(
          turnbackList.map((turnback) => ({
            label: turnback.name,
            value: turnback.id,
          })),
        )
      : [],
    valueFormatter: (value: Turnback['id']) => turnbackMap.get(value)?.name,
    valueGetter: (value, row: GanttTask) => row.completedTask?.turnbackId ?? -1,
    renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, Turnback['id']>) =>
      (value && turnbackMap.get(value)?.name) ?? '—',
  };
}
