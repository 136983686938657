import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Process } from '../../entities/Process';
import { useReadProcessListQuery } from '../../services/processApi';

type ProcessAutocompleteProps = Omit<AutocompleteProps<Process, false, false, false>, 'options' | 'renderInput'> &
  Pick<TextFieldProps, 'error' | 'helperText' | 'label'> & { processManager?: string };

function ProcessAutocompleteComponent(props: ProcessAutocompleteProps, ref: React.Ref<HTMLDivElement>) {
  const { disabled, error, helperText, label, processManager: filterByManager, ...autocompleteProps } = props;
  const processList = useReadProcessListQuery();

  const apiError = processList.error as ServerError | undefined;

  const options = useMemo(() => {
    return [...(processList.data ?? [])]
      .filter((option) => (filterByManager ? option.managerList.find((u) => u.username === filterByManager) : true))
      .sort((a, b) => (a.name === 'OTHER' ? 1 : b.name === 'OTHER' ? -1 : a.name.localeCompare(b.name)));
  }, [filterByManager, processList.data]);

  return (
    <Autocomplete
      disabled={disabled || processList?.data?.length === 0 || processList.isError || processList.isFetching}
      {...autocompleteProps}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error || Boolean(apiError)}
          label={label ?? 'Process'}
          helperText={helperText || apiError?.data?.message || apiError?.message}
          ref={ref}
        />
      )}
      renderOption={(props, option) => (
        <Typography {...props}>
          {option.shortName}
          <Typography
            component="span"
            sx={{
              opacity: 0.7,
              fontSize: '0.8em',
              marginLeft: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {option.name}
          </Typography>
        </Typography>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

export const ProcessAutocomplete = forwardRef(ProcessAutocompleteComponent);
