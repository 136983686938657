import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Department } from '../../entities/Department';
import { useReadDepartmentListQuery } from '../../services/departmentApi';

type DepartmentAutocompleteProps = Omit<AutocompleteProps<Department, false, false, false>, 'options' | 'renderInput'> &
  Pick<TextFieldProps, 'error' | 'helperText' | 'label'>;

function DepartmentAutocompleteComponent(props: DepartmentAutocompleteProps, ref: React.Ref<HTMLDivElement>) {
  const { disabled, error, helperText, label, ...autocompleteProps } = props;
  const departmentList = useReadDepartmentListQuery();

  const apiError = departmentList.error as ServerError | undefined;

  const options = useMemo(() => {
    return [...(departmentList.data ?? [])].sort((a, b) =>
      a.name === 'OTHER' ? 1 : b.name === 'OTHER' ? -1 : a.name.localeCompare(b.name),
    );
  }, [departmentList.data]);

  return (
    <Autocomplete
      disabled={disabled || departmentList?.data?.length === 0 || departmentList.isError || departmentList.isFetching}
      {...autocompleteProps}
      options={options}
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error || Boolean(apiError)}
          label={label ?? 'Department'}
          helperText={helperText || apiError?.data?.message || apiError?.message}
          ref={ref}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

export const DepartmentAutocomplete = forwardRef(DepartmentAutocompleteComponent);
