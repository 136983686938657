import { useMemo } from 'react';
import { User } from '@top-solution/microtecnica-utils';
import { isFuture } from 'date-fns';
import { EscapeTypes } from '../entities/Escape';
import { Process } from '../entities/Process';
import { Scenario } from '../entities/Scenario';
import { Subprocess } from '../entities/Subprocess';
import { GanttTask, TaskStatus } from '../entities/Task';
import { UserRoleName } from '../entities/User';

export interface GanttTasksUserStats {
  total: number;
  completion: number;
}

export interface GanttTasksStats {
  cardColor: 'success' | 'error';
  finance: {
    total: number;
    onTime: number;
    completion: number;
    escapeCount: number;
  };
  supplier: {
    total: number;
    onTime: number;
    completion: number;
    escapeCount: number;
  };
  count: {
    open: GanttTask[];
    overdue: GanttTask[];
    notApplicable: GanttTask[];
    onTime: GanttTask[];
    late: GanttTask[];
    total: GanttTask[];
  };
  byUser: Record<User['username'], GanttTasksUserStats>;
}

interface UseGanttTasksStatsOptions {
  excludeFutureTasks: boolean;
  ignoreOtherScenario: boolean;
}

function isTaskFinance(ganttTask: GanttTask): boolean {
  return ganttTask.task.owner?.role !== UserRoleName.GUEST;
}

export function classifyTask(ganttTask: GanttTask, stats: GanttTasksStats, options: UseGanttTasksStatsOptions) {
  const status = ganttTask.status;

  // Ignore tasks without scenario
  if (!ganttTask.task.scenario) {
    return;
  }

  // Ignore tasks with OTHER scenario (not relevant for stats)
  if (options.ignoreOtherScenario && ganttTask.task.scenario.name === 'OTHER') {
    return;
  }

  if (options.excludeFutureTasks) {
    // Ignore future tasks (Overview is only for the past)
    if (isFuture(new Date(ganttTask.date))) {
      return;
    }
  }

  // Show N/A tasks only in the N/A entry, exclude it from any other counting / statistic
  if (ganttTask.completedTask?.status === TaskStatus.NotApplicable) {
    stats.count.notApplicable.push(ganttTask);
    return;
  }

  // Task does not have an owner (??)
  if (!ganttTask.task.owner) {
    return;
  }

  stats.count.total.push(ganttTask);

  switch (status) {
    case TaskStatus.OnTime: {
      if (ganttTask.task.scenario) {
        stats.count.onTime.push(ganttTask);
      }
      break;
    }
    case TaskStatus.Late: {
      stats.count.late.push(ganttTask);
      break;
    }
    case TaskStatus.Overdue: {
      stats.count.overdue.push(ganttTask);
      break;
    }
    case TaskStatus.Open: {
      stats.count.open.push(ganttTask);
      break;
    }
  }

  if (!stats.byUser[ganttTask.task.owner.username]) {
    stats.byUser[ganttTask.task.owner.username] = {
      completion: 0,
      total: 0,
    };
  }

  const taskFinance = isTaskFinance(ganttTask);

  if (taskFinance) {
    stats.finance.total = stats.finance.total + 1;
    stats.byUser[ganttTask.task.owner.username].total = stats.byUser[ganttTask.task.owner.username].total + 1;
    if (ganttTask.completedTask) {
      stats.finance.completion = stats.finance.completion + 1;
      stats.byUser[ganttTask.task.owner.username].completion =
        stats.byUser[ganttTask.task.owner.username].completion + 1;
      if (ganttTask.completedTask.status === TaskStatus.OnTime) {
        stats.finance.onTime = stats.finance.onTime + 1;
      }
    }
  } else {
    stats.supplier.total = stats.supplier.total + 1;
    stats.byUser[ganttTask.task.owner.username].total = stats.byUser[ganttTask.task.owner.username].total + 1;
    if (ganttTask.completedTask) {
      stats.supplier.completion = stats.supplier.completion + 1;
      stats.byUser[ganttTask.task.owner.username].completion =
        stats.byUser[ganttTask.task.owner.username].completion + 1;
      if (ganttTask.completedTask.status === TaskStatus.OnTime) {
        stats.supplier.onTime = stats.supplier.onTime + 1;
      }
    }
  }

  if (ganttTask.completedTask?.escapeTypeId !== undefined) {
    if (
      ganttTask.completedTask.escapeTypeId === EscapeTypes.Significant ||
      ganttTask.completedTask.escapeTypeId === EscapeTypes.Other
    ) {
      if (taskFinance) {
        stats.finance.escapeCount = stats.finance.escapeCount + 1;
      } else {
        stats.supplier.escapeCount = stats.supplier.escapeCount + 1;
      }
    }
  }
}

export function useGanttTasksStats(
  ganttTasks: GanttTask[],
  filters: {
    processId?: Process['id'];
    subprocessId?: Subprocess['id'];
    scenarioName?: Scenario['name'];
    ownerUsername?: User['username'];
  },
  options: UseGanttTasksStatsOptions,
): GanttTasksStats {
  return useMemo(() => {
    const tasksStats: GanttTasksStats = {
      cardColor: 'success',
      finance: {
        total: 0,
        onTime: 0,
        completion: 0,
        escapeCount: 0,
      },
      supplier: {
        total: 0,
        onTime: 0,
        completion: 0,
        escapeCount: 0,
      },
      count: {
        overdue: [],
        open: [],
        notApplicable: [],
        onTime: [],
        late: [],
        total: [],
      },
      byUser: {},
    };

    for (const ganttTask of ganttTasks) {
      if (filters.processId && ganttTask.task.process?.id !== filters.processId) {
        continue;
      }
      if (filters.scenarioName && ganttTask.task.scenario?.name !== filters.scenarioName) {
        continue;
      }
      if (filters.subprocessId && ganttTask.task.subprocess?.id !== filters.subprocessId) {
        continue;
      }
      if (filters.ownerUsername && ganttTask.task.owner?.username !== filters.ownerUsername) {
        continue;
      }
      classifyTask(ganttTask, tasksStats, options);
    }

    if (tasksStats.count.overdue.length > 0) {
      tasksStats.cardColor = 'error';
    }

    return tasksStats;
  }, [filters.ownerUsername, filters.processId, filters.scenarioName, filters.subprocessId, ganttTasks, options]);
}
