import { blue, deepPurple } from '@mui/material/colors';
import grey from '@mui/material/colors/grey';
import { enUS as coreLocale } from '@mui/material/locale';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { enUS as dataGridLocale } from '@mui/x-data-grid-premium/locales';
import { enUS as datePickersLocale } from '@mui/x-date-pickers-pro/locales';

const defaultTheme = createTheme();

const taskStatusConfig = {
  overdue: deepPurple.A700,
  onTime: defaultTheme.palette.success.main,
  late: defaultTheme.palette.error.main,
  notApplicable: defaultTheme.palette.text.secondary,
  open: defaultTheme.palette.warning.main,
};

const baseThemeConfig: ThemeOptions = {
  palette: {
    primary: {
      light: blue[400],
      main: blue[700],
      dark: blue[800],
    },
    secondary: deepPurple,
    background: {
      default: grey[50],
    },
    taskStatus: taskStatusConfig,
  },
};

export const theme = createTheme(baseThemeConfig, datePickersLocale, dataGridLocale, coreLocale);

const darkThemeConfig: ThemeOptions = {
  palette: {
    ...baseThemeConfig.palette,
    primary: {
      light: blue[100],
      main: blue[300],
      dark: blue[500],
    },
    mode: 'dark',
    taskStatus: taskStatusConfig,
  },
};

export const darkTheme = createTheme(darkThemeConfig, datePickersLocale, dataGridLocale, coreLocale);

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    taskStatus: Record<keyof typeof taskStatusConfig, string>;
  }
  interface PaletteOptions {
    taskStatus: Record<keyof typeof taskStatusConfig, string>;
  }
}
