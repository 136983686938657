import { DataGrid } from '@top-solution/microtecnica-mui';
import { alpha, styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid-premium';

const ODD_OPACITY_OFFSET = 0.02;

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  bgcolor: theme.palette.background.paper,
  [`.${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(
        theme.palette.primary.light,
        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
      ),
    },
  },
  // https://mui.com/x/react-data-grid/style/#striped-rows
  [`.${gridClasses.row}.odd`]: {
    backgroundColor: alpha(theme.palette.grey[100], 0.6),
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(
        theme.palette.primary.light,
        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity + ODD_OPACITY_OFFSET,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + ODD_OPACITY_OFFSET),
    },
  },
  [`.${gridClasses.pinnedColumns}`]: {
    [`.${gridClasses.row}.odd`]: {
      backgroundColor: alpha(theme.palette.common.black, theme.palette.mode === 'light' ? 0.03 : 0.2),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.light,
          theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity + ODD_OPACITY_OFFSET,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity + ODD_OPACITY_OFFSET),
      },
    },
  },
}));
