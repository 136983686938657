import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRenderCellParams, GridValidRowModel, getGridStringOperators } from '@mui/x-data-grid-premium';
import { UserRoleChip } from '../components/UserRoleChip';
import { UserTimetableTable } from '../components/UserTimetableTable';
import { User } from '../entities/User';
import { useReadUserListQuery } from '../services/userApi';
import { printUserName } from '../utils/users';

const valueGetter = (user?: User | null) => (user ? `${user.firstName} ${user.lastName} ${user.username}` : '');

function UserCell(props: { user?: User }) {
  const { user } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showContent, setShowContent] = useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowContent(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!user) {
    return '—';
  }

  return (
    <Box sx={{ marginX: -0.5 }}>
      <Button
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        size="small"
        sx={{ fontSize: 'inherit', color: 'inherit', textTransform: 'none', fontWeight: 400, lineHeight: 1.5 }}
      >
        {user.lastName ? `${user.firstName} ${user.lastName}` : user.username}
      </Button>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        TransitionProps={{
          onExited: () => setShowContent(false),
        }}
      >
        {showContent && (
          <Typography
            component="div"
            variant="body2"
            sx={{ padding: 1, listStyle: 'none', display: 'flex', flexDirection: 'column', gap: 0.5 }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography fontFamily="monospace" variant="body2">{`${user.username}`}</Typography>
              <UserRoleChip user={user} size="small" />
            </Stack>
            {user.email ? <li>{`${user.email}`}</li> : null}
            {user.timetable ? <UserTimetableTable timetable={user.timetable} /> : null}
          </Typography>
        )}
      </Popover>
    </Box>
  );
}

export function useUsersColDefs<T extends GridValidRowModel>(): {
  ownerColumn: (uservalueGetter: (row: T) => User | undefined | null) => GridColDef;
  backupColumn: (uservalueGetter: (row: T) => User | undefined | null) => GridColDef;
  reviewerColumn: (uservalueGetter: (row: T) => User | undefined | null) => GridColDef;
} {
  const { data: userList } = useReadUserListQuery();

  const userMap = useMemo(() => {
    const userMap = new Map<User['username'], User>();
    if (userList) {
      for (let i = 0; i < userList.length; i++) {
        userMap.set(valueGetter(userList[i]), userList[i]);
      }
    }
    return userMap;
  }, [userList]);

  const commonDefs: Partial<GridColDef> = useMemo(
    () => ({
      width: 130,
      groupable: false,
      filterOperators: getGridStringOperators().filter((f) => {
        return f.value === 'contains';
      }),
      valueGetter: (row: User) => (userMap.has(row.username) ? printUserName(userMap.get(row.username)) : row.username),
      renderCell: ({ value }: GridRenderCellParams<T, string>) => (
        <UserCell user={value && userMap.has(value) ? userMap.get(value) : undefined} />
      ),
    }),
    [userMap],
  );

  return useMemo(() => {
    const ownerColumn = (userValueGetter: (row: T) => User | undefined | null) => {
      return {
        field: 'owner',
        headerName: 'Owner',
        ...commonDefs,
        valueGetter: (value: unknown, row: T) => valueGetter(userValueGetter(row)),
      };
    };
    const backupColumn = (userValueGetter: (row: T) => User | undefined | null) => {
      return {
        field: 'backup',
        headerName: 'Backup',
        ...commonDefs,
        valueGetter: (value: unknown, row: T) => valueGetter(userValueGetter(row)),
      };
    };
    const reviewerColumn = (userValueGetter: (row: T) => User | undefined | null) => {
      return {
        field: 'reviewer',
        headerName: 'Reviewer',
        ...commonDefs,
        valueGetter: (value: unknown, row: T) => valueGetter(userValueGetter(row)),
      };
    };
    return {
      ownerColumn,
      backupColumn,
      reviewerColumn,
    };
  }, [commonDefs]);
}
