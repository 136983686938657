import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export const TAG_TYPES = {
  CALENDAR_TASK: 'ProcessTask',
  CALENDAR: 'Process',
  CATEGORY: 'Category',
  COMPLETED_TASK: 'CompletedTask',
  ESCAPE: 'escape',
  NOTIFICATIONS: 'Notification',
  PLAN_DAY: 'PlanDay',
  PLAN: 'Plan',
  PROCESS: 'Process',
  SCENARIO: 'Scenario',
  TASK: 'Task',
  TURNBACK: 'Turnback',
  USER_ROLE: 'UserRole',
  USER: 'User',
  WORKING_DAY: 'WorkingDay',
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});
