import { Day } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';

export function numberToBinaryString(n: number, digits: number) {
  return `${pad((n >>> 0).toString(2), digits)}`;
}

function pad(s: string, digits: number) {
  if (s.length >= digits) {
    return s;
  }
  return `${Array.from(new Array(digits - s.length))
    .map(() => '0')
    .join('')}${s}`;
}

export const sortedDaysOfWeek = [...new Array(7)].map((_, index) => ({
  index: (index + 1) % 7,
  day: `${enGB.localize?.day(((index + 1) % 7) as Day)}`,
}));
