import React, { memo } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { InformationOutlineIcon } from '../../../components/Icons';
import { Percent } from '../../../components/Value';
import { EscapeTypes } from '../../../entities/Escape';
import { TaskStatus, taskStatusLabels } from '../../../entities/Task';
import { GanttTasksStats } from '../../../hooks/useGanttTasksStats';
import { useOverviewQueryParams } from '../overviewQueryParams';

function StatButton(
  props: Omit<ButtonProps, 'color'> & {
    query?: Record<string, string>;
    description: string;
    color?: TypographyProps['color'];
    taskDetailsPath?: string;
    info?: string;
  },
) {
  const { description, query, color, children, taskDetailsPath, info, ...buttonProps } = props;
  const { pathname } = useLocation();
  const [qs] = useSearchParams();

  const linkQs = new URLSearchParams(qs);
  if (query) {
    for (const [key, value] of Object.entries(query)) {
      linkQs.set(key, value);
    }
  }

  return (
    <Button
      component={Link}
      to={taskDetailsPath ? `${pathname}/${taskDetailsPath}?${linkQs.toString()}` : ''}
      disabled={!taskDetailsPath}
      {...buttonProps}
      sx={{
        justifyContent: 'space-between',
        textTransform: 'none',
        color: 'text.primary',
        '&.Mui-disabled': {
          color: 'text.primary',
        },
        ...buttonProps.sx,
      }}
    >
      <Typography component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {description}
        {info ? (
          <Tooltip title={info}>
            <Box component="div">
              <IconButton size="small" sx={{ position: 'relative', bottom: '0.0625em' }}>
                <InformationOutlineIcon />
              </IconButton>
            </Box>
          </Tooltip>
        ) : null}
      </Typography>
      <Typography component="span" justifyContent="flex-end" fontWeight="bold" color={color}>
        {children}
      </Typography>
    </Button>
  );
}

export type TaskStatsCardProps = {
  title: string;
  color?: string;
  stats: GanttTasksStats;
  taskDetailsPath: string;
  detailPath: string;

  // process: Process;
  // ganttTasks: GanttTask[];
  // showTasksCount: boolean;
};

function TaskStatsCardComponent(props: TaskStatsCardProps): JSX.Element | null {
  const { title, color, stats, detailPath, taskDetailsPath } = props;

  const { showTasksCount, excludeFutureTasks } = useOverviewQueryParams();

  const financeOnTime = stats.finance.onTime / (stats.finance.completion || 1);
  const financeCompletion = stats.finance.completion / (stats.finance.total || 1);
  const supplierOnTime = stats.supplier.onTime / (stats.supplier.completion || 1);
  const supplierCompletion = stats.supplier.completion / (stats.supplier.total || 1);

  return (
    <Card elevation={3} sx={{ maxWidth: (theme) => theme.breakpoints.values.sm }}>
      <CardActionArea>
        <Typography
          variant="h6"
          component={Link}
          fontSize={'1.15rem'}
          title={title}
          to={detailPath}
          sx={{
            paddingX: 2,
            paddingY: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            bgcolor: `${stats.cardColor}.main`,
            color: 'white',
            textDecoration: 'none',
            display: 'flex',
          }}
        >
          {color ? (
            <Box
              sx={{
                background: `#${color}`,
                height: '1em',
                width: '1em',
                marginRight: 1,
                borderRadius: 0.5,
                display: 'inline-flex',
                alignSelf: 'center',
                top: '.125em',
                position: 'relative',
                border: '1px solid',
                borderColor: 'background.paper',
              }}
            />
          ) : null}
          {title}
        </Typography>
      </CardActionArea>
      <Box
        sx={{
          paddingX: 1.5,
          paddingY: 1,
          display: 'grid',
          gridTemplateColumns: '1fr 1px 1fr',
          gap: 1.5,
        }}
      >
        <Box>
          <Typography
            paddingX={1}
            fontWeight="bold"
            textTransform="uppercase"
            color="text.secondary"
            textAlign="center"
          >
            Finance
          </Typography>
          <Stack direction="column">
            <StatButton description={'On time'}>
              <Percent value={financeOnTime} />
            </StatButton>
            <StatButton description={'Completion'}>
              <Percent value={financeCompletion} />
            </StatButton>
            <StatButton
              description={'Escape count'}
              taskDetailsPath={taskDetailsPath}
              query={{ escapeNot: [EscapeTypes.NA, '-1'].join(',') }}
            >
              {stats.finance.escapeCount}
            </StatButton>
          </Stack>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <Typography
            paddingX={1}
            paddingTop={0.5}
            fontWeight="bold"
            textTransform="uppercase"
            color="text.secondary"
            textAlign="center"
          >
            Supplier
          </Typography>
          <Stack direction="column">
            <StatButton description={'On time'}>
              <Percent value={supplierOnTime} />
            </StatButton>
            <StatButton description={'Completion'}>
              <Percent value={supplierCompletion} />
            </StatButton>
            <StatButton
              description={'Escape count'}
              taskDetailsPath={taskDetailsPath}
              query={{ escapeNot: [EscapeTypes.NA, '-1'].join(',') }}
            >
              {stats.supplier.escapeCount}
            </StatButton>
          </Stack>
        </Box>
      </Box>
      {showTasksCount ? (
        <>
          <Box component="hr" sx={{ borderColor: 'divider', margin: 0 }} />
          <Box
            sx={{
              paddingX: 1.5,
              paddingY: 1,
              'dd, dt': { fontSize: '1.05rem' },
            }}
          >
            <Stack direction="column">
              <StatButton
                description={taskStatusLabels[TaskStatus.Overdue]}
                color={stats.count.overdue.length > 0 ? 'taskStatus.overdue' : 'text.disabled'}
                taskDetailsPath={taskDetailsPath}
                query={{ status: TaskStatus.Overdue }}
              >
                {stats.count.overdue.length}
              </StatButton>

              {excludeFutureTasks === false ? (
                <StatButton
                  description={taskStatusLabels[TaskStatus.Open]}
                  color={stats.count.open.length > 0 ? 'taskStatus.open' : 'text.disabled'}
                  taskDetailsPath={taskDetailsPath}
                  query={{ status: TaskStatus.Open }}
                >
                  {stats.count.open.length}
                </StatButton>
              ) : null}

              <StatButton
                description={taskStatusLabels[TaskStatus.NotApplicable]}
                color={stats.count.notApplicable.length > 0 ? 'taskStatus.notApplicable' : 'text.disabled'}
                taskDetailsPath={taskDetailsPath}
                query={{ status: TaskStatus.NotApplicable }}
                info={`N/A tasks are not considered in the calculation of "On time" and "Completion" statistics`}
                sx={{ width: '100%' }}
              >
                {stats.count.notApplicable.length}
              </StatButton>

              <StatButton
                description={taskStatusLabels[TaskStatus.OnTime]}
                color={stats.count.onTime.length > 0 ? 'taskStatus.onTime' : 'text.disabled'}
                taskDetailsPath={taskDetailsPath}
                query={{ status: TaskStatus.OnTime }}
              >
                {stats.count.onTime.length}
              </StatButton>

              <StatButton
                description={taskStatusLabels[TaskStatus.Late]}
                color={stats.count.late.length > 0 ? 'taskStatus.late' : 'text.disabled'}
                taskDetailsPath={taskDetailsPath}
                query={{ status: TaskStatus.Late }}
              >
                {stats.count.late.length}
              </StatButton>

              <StatButton
                description={'Total'}
                color={stats.count.total.length > 0 ? 'text.main' : 'text.disabled'}
                taskDetailsPath={taskDetailsPath}
                query={{}}
              >
                {stats.count.total.length}
              </StatButton>
            </Stack>
          </Box>
        </>
      ) : null}
    </Card>
  );
}

export const TaskStatsCard = memo(TaskStatsCardComponent);
