import React, { forwardRef, memo, Ref, useCallback, useMemo, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { User, UserRoleName } from '../../entities/User';
import { useReadUserListQuery } from '../../services/userApi';
import { printUserName } from '../../utils/users';

type UserAutocompleteProps<Multiple extends boolean | undefined> = Omit<
  AutocompleteProps<User['username'], Multiple, undefined, undefined>,
  'options' | 'renderInput' | 'onInputChange' | 'css'
> &
  Pick<TextFieldProps, 'label' | 'variant' | 'error' | 'helperText' | 'required' | 'sx'> & {
    filterByRoles?: UserRoleName[];
  };

function UserAutocompleteComponent<Multiple extends boolean | undefined>(
  props: UserAutocompleteProps<Multiple>,
  ref: Ref<unknown>,
): JSX.Element {
  const { label, error, value, helperText, required, variant, onChange, sx, filterByRoles, ...autocompleteProps } =
    props;
  const [needle, setNeedle] = useState('');
  const { data, error: requestError, isFetching } = useReadUserListQuery();

  const sortedUsers = useMemo(
    () =>
      [...(data ?? [])].sort((a, b) =>
        (a.lastName ?? a.firstName ?? a.username).localeCompare(b.lastName ?? b.firstName ?? b.username),
      ),
    [data],
  );

  const roleFilteredUsers = useMemo(
    () =>
      sortedUsers.filter((user) => {
        if (filterByRoles && !filterByRoles.includes(user.role)) {
          return false;
        }

        return true;
      }),
    [filterByRoles, sortedUsers],
  );

  const searchFilteredUsers = useMemo(
    () =>
      roleFilteredUsers.filter((user) => {
        const lcNeedle = needle.toLocaleLowerCase();
        return (
          user.username.indexOf(lcNeedle) >= 0 ||
          (user.firstName && user.firstName.toLocaleLowerCase().indexOf(lcNeedle) >= 0) ||
          (user.lastName && user.lastName.toLocaleLowerCase().indexOf(lcNeedle) >= 0) ||
          (user.email && user.email.indexOf(lcNeedle) >= 0)
        );
      }),
    [needle, roleFilteredUsers],
  );

  const resultsByUsername = useMemo<Map<User['username'], User> | null>(
    () => data?.reduce((map, user) => map.set(user.username, user), new Map<User['username'], User>()) || null,
    [data],
  );

  const options = useMemo(() => {
    let options: string[] = [];
    if (needle.length >= 3) {
      options = [...searchFilteredUsers.map(({ username }) => username)];
    } else {
      options = [...(roleFilteredUsers?.map(({ username }) => username) ?? [])];
    }
    return options;
  }, [needle.length, roleFilteredUsers, searchFilteredUsers]);

  const getOptionLabel = useCallback(
    (option: string) => {
      const user = resultsByUsername?.get(option);
      return printUserName(user);
    },
    [resultsByUsername],
  );

  const handleInputChange = useCallback((_: unknown, value: string) => {
    setNeedle(value);
  }, []);

  return (
    <Autocomplete
      autoComplete
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          inputRef={ref}
          error={Boolean(requestError) || error}
          helperText={(requestError && 'message' in requestError && requestError.message) || helperText || undefined}
          required={required}
          sx={sx}
        />
      )}
      noOptionsText={needle.length < 3 ? 'Digita almeno 3 caratteri' : undefined}
      loading={isFetching}
      {...autocompleteProps}
      onChange={(event, value, reason) => {
        onChange?.(event, value, reason);
      }}
    />
  );
}

export const UserAutocomplete = memo(forwardRef(UserAutocompleteComponent)) as typeof UserAutocompleteComponent;
