import React, { useState } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { ScheduleTabProps, YearlyScheduleForm, YearlyScheduleFormSchema } from '../../../../../../entities/Task';
import { useTaskScheduling } from '../../../../../../hooks/useTaskScheduling';
import { DebugTable } from '../DebugTable';
import { Preview } from '../Preview/Preview';
import { ScheduleActionButtons } from '../ScheduleActionButtons';
import { DaysSelector } from '../selectors/DaySelector';
import { MonthsSelector } from '../selectors/MonthsSelector';
import { ShiftSelector } from '../selectors/ShiftSelector';

const defaultValues: DefaultValues<YearlyScheduleForm> = {
  month: 0,
  day: undefined,
  shift: 1,
};

function YearlyTabComponent(props: ScheduleTabProps) {
  const [previewYear, setPreviewYear] = useState(new Date().getFullYear());
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  const { dayHasTask } = useTaskScheduling(previewYear, previewYear);

  const { control, handleSubmit, watch, setValue, reset } = useForm<YearlyScheduleForm>({
    defaultValues: props.initialValues ?? defaultValues,
    resolver: zodResolver(YearlyScheduleFormSchema),
  });

  const month = watch('month');
  const day = watch('day');
  const shift = watch('shift');

  return (
    <Stack
      component="form"
      direction="column"
      onSubmit={handleSubmit(props.onSubmit)}
      onReset={() => reset()}
      sx={{ flex: 1 }}
    >
      <DialogContent sx={{ paddingTop: 0 }}>
        <Stack direction="column" gap={2} paddingTop={2}>
          <Controller
            control={control}
            name="month"
            render={({ field, fieldState: { error } }) => <MonthsSelector {...field} error={error} />}
          />
          <Controller
            control={control}
            name="day"
            render={({ field, fieldState: { error } }) => (
              <DaysSelector value={field.value} onChange={(day) => field.onChange(day)} error={error} />
            )}
          />
          <Controller
            control={control}
            name="shift"
            render={({ field, fieldState: { error } }) => (
              <ShiftSelector value={field.value} onChange={(day) => field.onChange(day)} error={error} />
            )}
          />
          <Preview
            open={props.previewOpen}
            onToggle={props.onPreviewOpenChange}
            year={previewYear}
            onYearChange={(year) => setPreviewYear(year)}
            isDayChecked={(date) =>
              dayHasTask(
                {
                  schedule: {
                    day: day,
                    month: month,
                    occurrence: 0,
                    shift: shift,
                    weekDay: 0,
                    workDay: 0,
                  },
                  overrides: [],
                },
                date,
              ).hasTask
            }
          />
          {showDebugInfo ? (
            <DebugTable
              schedule={{
                month,
                day,
                shift,
                workDay: 0,
                occurrence: 0,
                weekDay: 0,
              }}
              onImport={(taskRecurrence) => {
                if (taskRecurrence.month) {
                  setValue('month', taskRecurrence.month);
                }
                if (taskRecurrence.day) {
                  setValue('day', taskRecurrence.day);
                }
                if (taskRecurrence.shift) {
                  setValue('shift', taskRecurrence.shift);
                }
              }}
            />
          ) : null}
        </Stack>
      </DialogContent>
      <ScheduleActionButtons
        onClose={props.onClose}
        onToggleDebugInfo={() => setShowDebugInfo((s) => !s)}
        dialogActions={props.dialogActions}
      />
    </Stack>
  );
}

export const YearlyTab = React.memo(YearlyTabComponent);
