import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { BugOutlineIcon } from '../../../../../components/Icons';

interface ScheduleActionButtonsProps {
  onClose: () => void;
  onToggleDebugInfo: () => void;
  dialogActions: React.ReactNode;
}

function ScheduleActionButtonsComponent(props: ScheduleActionButtonsProps) {
  const { onToggleDebugInfo, dialogActions } = props;
  return (
    <DialogActions sx={{ marginTop: 'auto', paddingX: 0 }}>
      {dialogActions}
      <Button color="inherit" onClick={onToggleDebugInfo}>
        <BugOutlineIcon />
      </Button>
      <Box sx={{ flex: 1, height: 1 }} />
      <Button type="submit" variant="contained">
        {'Next'}
      </Button>
    </DialogActions>
  );
}

export const ScheduleActionButtons = React.memo(ScheduleActionButtonsComponent);
