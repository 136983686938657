import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { FilterOperator, useAuth } from '@top-solution/microtecnica-utils';
import { add, isAfter } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  EditingTask,
  TaskConfigurationForm,
  TaskConfigurationFormSchema,
  TaskOverride,
  TaskOverrideCreateRequest,
  TimeScheduleForm,
} from '../../../../entities/Task';
import { UserRoleName } from '../../../../entities/User';
import {
  useCreateTaskOverrideMutation,
  useReadCompletedTaskListQuery,
  useUpdateTaskMutation,
} from '../../../../services/taskApi';
import { TaskConfigurationFormContent } from '../TaskEditDialog/TaskConfiguration/TaskConfigurationFormContent';

type TaskConfigurationStepProps = Omit<DialogProps, 'onClose'> & {
  onClose: (value?: TaskConfigurationForm) => void;
  day: Date;
  onBack: () => void;
  task: EditingTask;
  dialogActions: React.ReactNode;
  timeSchedule: TimeScheduleForm;
  overrideDay: Date;
  override: TaskOverride | undefined;
};

export function TaskConfigurationStep(props: TaskConfigurationStepProps): JSX.Element {
  const { onClose, timeSchedule, task, override, overrideDay, dialogActions } = props;
  const { hasRole, username } = useAuth();

  const initialValues = useMemo(() => {
    if (!task) {
      return {
        isMachineTask: false,
        isDeliverable: false,
        duration: 0,
      };
    }
    if (override) {
      return {
        ...task,
        ...override,
        categoryId: override.category?.id,
        backupUsername: override.backup?.username,
        reviewerUsername: override.reviewer?.username,
        ownerUsername: override.owner?.username,
        isMachineTask: Boolean(true),
        duration: override.duration ?? 0,
        durationMachine: override.durationMachine ?? 0,
        isDeliverable: override.isDeliverable ?? false,
      };
    }
    return {
      ...task,
      categoryId: task.category?.id,
      backupUsername: task.backup?.username,
      reviewerUsername: task.reviewer?.username,
      ownerUsername: task.owner?.username,
      isMachineTask: Boolean(true),
      duration: task.duration ?? 0,
      durationMachine: task.durationMachine ?? 0,
      isDeliverable: task.isDeliverable ?? false,
    };
  }, [override, task]);

  const form = useForm<TaskConfigurationForm>({
    defaultValues: {
      ...{
        ownerUsername: hasRole(UserRoleName.USER) ? username ?? undefined : undefined,
      },
      ...initialValues,
      // schedule: dateSchedule,
      // ...timeSchedule,
      confirmUserChange: true,
    },
    resolver: zodResolver(TaskConfigurationFormSchema),
  });
  const { handleSubmit, control, watch, setValue } = form;

  const [create, createStatus] = useCreateTaskOverrideMutation();
  const [update, updateStatus] = useUpdateTaskMutation();

  const isLoading = createStatus.isLoading || updateStatus.isLoading;
  const error = createStatus.error ?? updateStatus.error;

  const readCompletedTaskListQueryParams = useMemo(() => {
    return {
      filters: task?.owner
        ? [
            {
              field: 'ownerUsername',
              value: task?.owner?.username,
              operator: FilterOperator.equals,
            },
          ]
        : [],
      offset: 0,
      limit: 0,
    };
  }, [task?.owner]);

  const readCompletedTaskListQuery = useReadCompletedTaskListQuery(readCompletedTaskListQueryParams);

  const onSubmit = async (data: TaskConfigurationForm) => {
    let updatedDate = new Date();
    if (readCompletedTaskListQuery?.data) {
      for (const completedTask of readCompletedTaskListQuery.data) {
        if (completedTask.taskId === task?.id) {
          if (completedTask.scheduledDate && isAfter(new Date(completedTask.scheduledDate), updatedDate)) {
            updatedDate = add(new Date(completedTask.scheduledDate), { hours: 1 });
          }
        }
      }
    }

    const completeData: TaskOverrideCreateRequest = {
      ...data,
      processId: data.process.id,
      subprocessId: data.subprocess?.id,
      areaId: data.area.id,
      departmentId: data.department?.id,
      scenarioId: data.scenario?.id,
      categoryId: data.category?.id,
      ...timeSchedule,
      schedule: task.schedule,
      fatherId: task.id!,
      overrideDay: overrideDay.toISOString(),
      overrideOriginalDay: props.day.toISOString(),
    };

    if (isAfter(updatedDate, new Date())) {
      completeData.updatedDate = updatedDate.toISOString();
    }

    if (task?.id) {
      if (override?.id) {
        await update({
          ...completeData,
          id: override.id,
          fatherId: task?.id,
        }).unwrap();
      } else {
        await create({ ...completeData, fatherId: task?.id }).unwrap();
      }
    }

    onClose(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TaskConfigurationFormContent control={control} watch={watch} setValue={setValue} task={task} />
          {error && <ErrorAlert error={error} />}
        </DialogContent>
        <DialogActions>
          {dialogActions}
          <Box sx={{ flex: 1, height: 1 }} />
          <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
            {override ? 'Update Exception' : 'Create Exception'}
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
