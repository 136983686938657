import { Link } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { Layout } from '../../components/Layout';
import { useSectionList } from '../../hooks/useSectionList';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';

export function HomePage(): JSX.Element {
  const { userSectionList, adminSectionList } = useSectionList();

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      <Layout maxWidth="sm">
        <Paper>
          <List>
            {userSectionList.map((section) => (
              <ListItem disableGutters key={section.url}>
                <ListItemButton component={Link} to={section.url}>
                  <ListItemIcon>{section.icon}</ListItemIcon>
                  <ListItemText primary={section.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
        {adminSectionList.length > 0 ? (
          <Paper sx={{ marginTop: 2 }}>
            <List>
              {adminSectionList.map((section) => (
                <ListItem disableGutters key={section.url}>
                  <ListItemButton component={Link} to={section.url}>
                    <ListItemIcon>{section.icon}</ListItemIcon>
                    <ListItemText primary={section.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        ) : null}
      </Layout>
    </AuthGuard>
  );
}
