import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { isSameDay } from 'date-fns';
import { z } from 'zod';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { CardRadioGroup } from '../../../../../components/CardRadioGroup/CardRadioGroup';
import { RadioCard } from '../../../../../components/CardRadioGroup/RadioCard';
import { EditingTask, TaskOverride } from '../../../../../entities/Task';
import { formatDate } from '../../../../../utils/date';
import { ScheduleActionButtons } from './ScheduleActionButtons';

interface TaskScheduleProps {
  onSubmit: ({ day }: { day: Date }) => void;
  onClose: () => void;
  dialogActions: React.ReactNode;
  task: EditingTask;
  override: TaskOverride | undefined;
  day: Date;
}

export function ScheduleFormComponent(props: TaskScheduleProps) {
  const [overrideDateMode, setOverrideDateMode] = useState(
    props.override && !isSameDay(props.override.overrideDay, props.override.overrideOriginalDay) ? 'change' : 'same',
  );
  const { control, handleSubmit, reset } = useForm<{ day: Date }>({
    defaultValues: { day: props.override ? new Date(props.override.overrideDay) : props.day },
    resolver: zodResolver(z.object({ day: z.date() })),
  });

  const disabled = Boolean(props.override);

  return (
    <Stack
      component="form"
      direction="column"
      onSubmit={handleSubmit(props.onSubmit)}
      onReset={() => reset()}
      sx={{ flex: 1 }}
    >
      <Stack direction="column">
        <Container maxWidth="sm" sx={{ paddingY: 4 }}>
          <CardRadioGroup
            defaultValue="keep"
            value={overrideDateMode}
            onChange={(e, value) => setOverrideDateMode(value)}
          >
            <RadioCard title="Keep the scheduled date" value="same" disabled={disabled}>
              <Typography variant="body2">{`The task is due on ${formatDate(props.day, 'P')}`}</Typography>
            </RadioCard>
            <RadioCard title="Change the scheduled date" value="change" disabled={disabled}>
              <Stack direction="row" alignItems="baseline" gap={1}>
                <Typography variant="body2">{`This task will be rescheduled on`}</Typography>
                <Controller
                  control={control}
                  name="day"
                  render={({ field }) => <DatePicker {...field} disabled={disabled} />}
                />
              </Stack>
            </RadioCard>
          </CardRadioGroup>
        </Container>
        <ScheduleActionButtons onClose={props.onClose} dialogActions={props.dialogActions} />
      </Stack>
    </Stack>
  );
}

// export const TaskRecurrence = React.memo(TaskRecurrenceComponent);
