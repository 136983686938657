import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { DEFAULT_TARGET, formatPercentage } from '../../utils/valueHelpers';

type PercentProps = BoxProps & {
  value: number;
  target?: number;
  precision?: number;
};

function PercentComponent(props: PercentProps): JSX.Element {
  const { value, target, precision, sx, component, ...boxProps } = props;
  return (
    <Box
      component={component ?? 'span'}
      sx={{
        color: value < (target ?? DEFAULT_TARGET) ? 'error.main' : 'success.main',
        ...sx,
      }}
      {...boxProps}
    >
      {formatPercentage(value, precision)}
    </Box>
  );
}

export const Percent = memo(PercentComponent);
