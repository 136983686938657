import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sub } from 'date-fns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { usePlannerConfig } from '../../hooks/usePlannerConfig';
import { formatFromDate, formatToDate, dateRangePickerShortcutsItems } from '../../utils/date';

export function useOverviewQueryParams() {
  const [qs, setQs] = useSearchParams();

  const { config, patchConfig } = usePlannerConfig();

  const from = useMemo(() => {
    if (qs.has('from')) {
      return new Date(qs.get('from') as string);
    } else if (config?.pages?.overview?.params?.from) {
      return new Date(config?.pages?.overview?.params?.from);
    }
    return sub(new Date(), { months: 6 });
  }, [config, qs]);

  const to = useMemo(() => {
    if (qs.has('to')) {
      return new Date(qs.get('to') as string);
    } else if (config?.pages?.overview?.params?.to) {
      return new Date(config?.pages?.overview?.params?.to);
    }
    return new Date();
  }, [config, qs]);

  const showTasksCount = useMemo(() => {
    if (qs.has('showTasksCount')) {
      return qs.get('showTasksCount') === 'true' ?? false;
    } else if (config?.pages?.overview?.params?.showTasksCount !== undefined) {
      return config?.pages?.overview?.params.showTasksCount;
    }
    return false;
  }, [config, qs]);

  const showEmptyCards = useMemo(() => {
    if (qs.has('showEmptyCards')) {
      return qs.get('showEmptyCards') === 'true' ?? false;
    } else if (config?.pages?.overview?.params?.showEmptyCards !== undefined) {
      return config?.pages?.overview?.params.showEmptyCards;
    }
    return false;
  }, [config, qs]);

  const excludeFutureTasks = useMemo(() => {
    if (qs.has('excludeFutureTasks')) {
      return qs.get('excludeFutureTasks') === 'true' ?? false;
    } else if (config?.pages?.overview?.params?.excludeFutureTasks !== undefined) {
      return config?.pages?.overview?.params.excludeFutureTasks;
    }
    return false;
  }, [config, qs]);

  const setFromTo = useCallback(
    (from: Date, to: Date) => {
      setQs((prev) => {
        const params = new URLSearchParams(prev);
        params.set('from', formatFromDate(from));
        params.set('to', formatToDate(to));
        patchConfig({ pages: { overview: { params: { from: from.toISOString(), to: to.toISOString() } } } });
        return params;
      });
    },
    [patchConfig, setQs],
  );

  const setShowTasksCount = useCallback(
    (showTasksCount: boolean) => {
      setQs((prev) => {
        const params = new URLSearchParams(prev);
        params.set('showTasksCount', `${showTasksCount}`);
        patchConfig({ pages: { overview: { params: { showTasksCount } } } });
        return params;
      });
    },
    [patchConfig, setQs],
  );

  const setShowEmptyCards = useCallback(
    (showEmptyCards: boolean) => {
      setQs((prev) => {
        const params = new URLSearchParams(prev);
        params.set('showEmptyCards', `${showEmptyCards}`);
        patchConfig({ pages: { overview: { params: { showEmptyCards } } } });
        return params;
      });
    },
    [patchConfig, setQs],
  );

  const setExcludeFutureTasks = useCallback(
    (excludeFutureTasks: boolean) => {
      setQs((prev) => {
        const params = new URLSearchParams(prev);
        params.set('excludeFutureTasks', `${excludeFutureTasks}`);
        patchConfig({ pages: { overview: { params: { excludeFutureTasks } } } });
        return params;
      });
    },
    [patchConfig, setQs],
  );

  const searchParams = useMemo(() => {
    const params = new URLSearchParams();
    params.set('from', formatFromDate(from));
    params.set('to', formatToDate(to));
    params.set('showTasksCount', `${showTasksCount}`);
    return params.toString();
  }, [from, showTasksCount, to]);

  return useMemo(
    () => ({
      from,
      to,
      setFromTo,
      showTasksCount,
      setShowTasksCount,
      showEmptyCards,
      setShowEmptyCards,
      searchParams,
      excludeFutureTasks,
      setExcludeFutureTasks,
    }),
    [
      from,
      excludeFutureTasks,
      searchParams,
      setFromTo,
      setExcludeFutureTasks,
      setShowEmptyCards,
      setShowTasksCount,
      showEmptyCards,
      showTasksCount,
      to,
    ],
  );
}

export function HeaderDateFilters(props: Pick<ReturnType<typeof useOverviewQueryParams>, 'from' | 'to' | 'setFromTo'>) {
  return [
    <DateRangePicker
      key="dates"
      value={[new Date(props.from), new Date(props.to)]}
      disableAutoMonthSwitching
      onAccept={([fromDate, toDate]) => {
        if (fromDate && toDate) {
          props.setFromTo(fromDate, toDate);
        }
      }}
      slots={{
        fieldSeparator: () => null,
      }}
      slotProps={{
        textField: {
          variant: 'filled',
          size: 'small',
          sx: { width: '13ch' },
        },
        shortcuts: {
          items: dateRangePickerShortcutsItems,
        },
      }}
    />,
  ];
}
