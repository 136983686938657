import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { GanttTask } from '../entities/Task';
import { useReadAreaListQuery } from '../services/areaApi';

export function useAreaColDef(): GridColDef {
  const { data: areaList } = useReadAreaListQuery();

  return useMemo(
    () => ({
      field: 'area',
      headerName: 'Area',
      type: 'singleSelect',
      valueOptions: areaList?.map((area) => ({ label: area.slug, value: area.id })),
      valueGetter: (value, row: GanttTask) => row.task.area?.id,
      width: 110,
    }),
    [areaList],
  );
}
