import React from 'react';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { MenuDownIcon } from '../../../components/Icons';
import { useOverviewQueryParams } from '../overviewQueryParams';
import { OverviewTitle } from './OverviewTitle';

interface OverviewHeaderProps {
  showTasksCountCheckbox?: boolean;
  showEmptyCardsCheckbox?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

function OverviewHeaderComponent(props: OverviewHeaderProps) {
  const { children, showTasksCountCheckbox, showEmptyCardsCheckbox } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    showTasksCount,
    setShowTasksCount,
    showEmptyCards,
    setShowEmptyCards,
    excludeFutureTasks,
    setExcludeFutureTasks,
  } = useOverviewQueryParams();

  return (
    <Stack direction="row" justifyContent="center" gap={2} flexWrap="wrap">
      <OverviewTitle />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3} flexWrap="wrap">
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          endIcon={<MenuDownIcon />}
          variant="outlined"
          sx={{ height: 40 }}
        >
          {'View settings'}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{ color: 'inherit' }}
        >
          <MenuItem disableRipple>
            <FormControlLabel
              control={
                <Switch checked={excludeFutureTasks} onChange={() => setExcludeFutureTasks(!excludeFutureTasks)} />
              }
              label="Exclude future tasks"
            />
          </MenuItem>
          {showTasksCountCheckbox ? (
            <MenuItem disableRipple>
              <FormControlLabel
                control={<Switch checked={showTasksCount} onChange={() => setShowTasksCount(!showTasksCount)} />}
                label="Show tasks count in cards"
              />
            </MenuItem>
          ) : null}

          {showEmptyCardsCheckbox ? (
            <MenuItem disableRipple>
              <FormControlLabel
                control={<Switch checked={showEmptyCards} onChange={() => setShowEmptyCards(!showEmptyCards)} />}
                label="Show cards without tasks"
              />
            </MenuItem>
          ) : null}
        </Menu>
        <Stack direction="row" alignItems="center">
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
}

export const OverviewHeader = React.memo(OverviewHeaderComponent);
