import React from 'react';
import { usePlannerConfig } from '../hooks/usePlannerConfig';

export function ConfigGuard(props: { children: React.ReactNode }): React.ReactElement | null {
  const { isConfigLoaded } = usePlannerConfig();

  // Do not render anything until the user config is loaded
  if (!isConfigLoaded) {
    return null;
  }

  return <>{props.children}</>;
}
