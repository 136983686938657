import React from 'react';
import Paper from '@mui/material/Paper';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

function PopoverTableComponent(props: TableProps) {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table
        sx={{
          tableLayout: 'fixed',
          minWidth: 350,
          '& .MuiTableRow-root': {
            '& td, & th': {
              whiteSpace: 'nowrap',
            },
            '& .task': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: 'auto',
            },
            '& .duration': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '110px',
            },
            '& .badge': {
              width: 'calc(1em + 32px)',
            },
            '& .status': {
              width: 100,
            },
            '&:last-child td, &:last-child th': { border: 0 },
          },
        }}
        size="small"
      >
        <TableBody>{props.children}</TableBody>
      </Table>
    </TableContainer>
  );
}

export const PopoverTable = React.memo(PopoverTableComponent);
