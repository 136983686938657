import React from 'react';
import Box from '@mui/material/Box';

interface CardsGridProps {
  children: React.ReactNode[];
}

function CardsGridComponent(props: CardsGridProps) {
  const { children } = props;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(460px, 1fr))',
        gap: 1.5,
        paddingBottom: 1.5,
      }}
    >
      {children}
    </Box>
  );
}

export const CardsGrid = React.memo(CardsGridComponent);
