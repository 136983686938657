import { useMemo } from 'react';
import { createMultipleValuesFilterOperators } from '@top-solution/microtecnica-mui';
import Chip, { ChipProps } from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { GanttTask } from '../entities/Task';
import {
  occurrenceNames,
  occurrenceShortNames,
} from '../pages/Admin/Task/TaskEditDialog/ScheduleForm/selectors/OccurrenceSelector';
import { monthName, weekDayName } from '../utils/scheduling';

function MonoChip(props: ChipProps) {
  return <Chip {...props} sx={{ ...props.sx, fontFamily: 'monospace' }} />;
}

export const months = Array.from(new Array(12)).map((_, month) => month);
export const weekDays = Array.from(new Array(7)).map((_, weekDay) => weekDay);
export const occurrencies = Array.from(new Array(16))
  .map((_, occurrency) => occurrency)
  .filter((n) => n < 4 || n > 13);

export function useScheduleColDefs(): {
  monthColumn: GridColDef;
  dayColumn: GridColDef;
  occurrenceColumn: GridColDef;
  weekDayColumn: GridColDef;
  workDayColumn: GridColDef;
} {
  return useMemo(
    () => ({
      monthColumn: {
        field: 'months',
        headerName: 'Months',
        type: 'singleSelect',
        sortable: false,
        width: 540,
        valueOptions: months.map((_, month) => ({
          value: `${month}`,
          label: monthName(month) ?? '-',
        })),
        filterOperators: createMultipleValuesFilterOperators<number, GanttTask>((item) => item),
        valueGetter: (value, row: GanttTask) =>
          months
            .map((month) =>
              row.task.schedule ? ((row.task.schedule.month & Math.pow(2, month)) === 0 ? -1 : month) : -1,
            )
            .filter((v) => v >= 0)
            .map((v) => `${v}`),
        renderCell: ({ value }: GridRenderCellParams<GanttTask, string[]>) => {
          return (
            <Stack direction="row" alignItems="center" gap={0.25} height="100%">
              {months.map((month) => (
                <MonoChip
                  key={month}
                  size="small"
                  label={monthName(Number(month), true)}
                  sx={{ opacity: (value ?? []).includes(`${month}`) ? 1 : 0.3 }}
                />
              ))}
            </Stack>
          );
        },
        valueFormatter: (value: string[]) => {
          return value.map((month) => monthName(Number(month), true)).join(', ');
        },
      },
      dayColumn: {
        field: 'day',
        headerName: 'Day',
        type: 'number',
        sortable: false,
        width: 50,
        valueGetter: (value, row: GanttTask) => row.task.schedule?.day,
        renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, number>) => (!value ? '—' : value),
      },
      occurrenceColumn: {
        field: 'occurrence',
        headerName: 'Week',
        type: 'singleSelect',
        sortable: false,
        width: 305,
        valueOptions: occurrencies.map((occurrence) => ({
          label: occurrenceNames[Math.pow(2, occurrence)],
          value: `${occurrence}`,
        })),
        valueGetter: (value, row: GanttTask) =>
          occurrencies
            .map((occurrence) =>
              row.task.schedule
                ? (row.task.schedule.occurrence & Math.pow(2, occurrence)) === 0
                  ? -1
                  : occurrence
                : -1,
            )
            .filter((v) => v >= 0)
            .map((v) => `${v}`),
        renderCell: ({ value }: GridRenderCellParams<GanttTask, string[]>) => {
          return (
            <Stack direction="row" alignItems="center" gap={0.5} height="100%">
              {occurrencies.map((occurrence) => (
                <MonoChip
                  key={occurrence}
                  size="small"
                  label={occurrenceShortNames[Math.pow(2, occurrence)]}
                  sx={{ opacity: (value ?? []).includes(`${occurrence}`) ? 1 : 0.3 }}
                />
              ))}
            </Stack>
          );
        },
        filterable: true,
        filterOperators: createMultipleValuesFilterOperators((n) => `${n}`),
        valueFormatter: (value: string[]) => {
          return value.map((occurrence) => occurrenceShortNames[Math.pow(2, Number(occurrence))]).join(', ');
        },
      },
      weekDayColumn: {
        field: 'weekDay',
        headerName: 'Week day',
        type: 'singleSelect',
        sortable: false,
        width: 330,
        valueOptions: weekDays.map((_, weekDay) => ({
          value: `${weekDay}`,
          label: weekDayName(weekDay),
        })),
        filterOperators: createMultipleValuesFilterOperators<string, GanttTask>((item) => `${item}`),
        valueGetter: (value, row: GanttTask) =>
          weekDays
            .map((weekDay) =>
              row.task.schedule ? ((row.task.schedule.weekDay & Math.pow(2, weekDay)) === 0 ? -1 : weekDay) : -1,
            )
            .filter((v) => v >= 0)
            .map((v) => `${v}`),
        renderCell: ({ value }: GridRenderCellParams<GanttTask, string[]>) => {
          return (
            <Stack direction="row" alignItems="center" gap={0.5} height="100%">
              {weekDays.map((weekDay) => (
                <MonoChip
                  key={weekDay}
                  size="small"
                  label={weekDayName(weekDay, true)}
                  sx={{ opacity: (value ?? []).includes(`${weekDay}`) ? 1 : 0.3 }}
                />
              ))}
            </Stack>
          );
        },
        valueFormatter: (value: string[]) => {
          return value.map((weekDay) => weekDayName(Number(weekDay), true)).join(', ');
        },
      },
      workDayColumn: {
        field: 'workDay',
        headerName: 'Work day',
        type: 'number',
        sortable: false,
        valueGetter: (value, row: GanttTask) => row.task.schedule?.workDay,
        renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, number>) => (
          <Typography component="span" fontFamily="monospace">
            {!value ? '—' : value}
          </Typography>
        ),
      },
    }),
    [],
  );
}
