import { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PlusIcon } from '../../../components/Icons';
import { UserEditDialog } from './UserEditDialog';

type UserAddButtonProps = IconButtonProps & {
  onRefetch: () => void;
};

const title = 'Add Guest user';

export function UserAddButton(props: UserAddButtonProps): JSX.Element {
  const { onRefetch, ...iconButtonProps } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip title={title}>
        <IconButton color="primary" onClick={() => setOpen(true)} {...iconButtonProps}>
          <PlusIcon />
        </IconButton>
      </Tooltip>
      <UserEditDialog
        title={title}
        open={open}
        onClose={() => {
          setOpen(false);
          onRefetch();
        }}
      />
    </>
  );
}
