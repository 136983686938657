import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface DefinitionListProps {
  children: React.ReactNode[];
  sx?: TypographyProps['sx'];
}

function DefinitionListComponent(props: DefinitionListProps) {
  return (
    <Typography
      variant="body1"
      component="dl"
      paddingLeft={2}
      sx={{
        display: 'grid',
        gridTemplateColumns: '130px 1fr 130px 1fr',
        columnGap: 1,
        padding: 1,
        dt: {
          color: 'text.secondary',
          marginY: 0.25,
        },
        dd: {
          fontWeight: 500,
          display: 'flex',
          gap: '1ch',
          marginY: 0.25,
        },
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
}

export const DefinitionList = React.memo(DefinitionListComponent);
