import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { Month } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GridButton, GridButtonGroup } from '../../../../../../components/GridButtonGroup';
import { Title } from '../Title';

interface MonthsSelectorProps {
  value: number;
  onChange: (month: number) => void;
  error: FieldError | undefined;
}

function MonthsSelectorComponent(props: MonthsSelectorProps, ref: React.Ref<HTMLDivElement>) {
  const { value, onChange, error } = props;

  function setAllMonths(enabled: boolean) {
    onChange(enabled ? Math.pow(2, 12) - 1 : 0);
  }

  return (
    <Box ref={ref}>
      <Title title={'Months:'} onSelectNone={() => setAllMonths(false)} onSelectAll={() => setAllMonths(true)} />
      <GridButtonGroup sx={{ width: '100%' }} wrapAfter={6}>
        {Array.from(new Array(12)).map((_, month) => (
          <GridButton
            key={month}
            onClick={() => onChange(value ^ Math.pow(2, month))}
            checked={(value & Math.pow(2, month)) !== 0}
          >
            {enGB.localize?.month(month as Month)?.substring(0, 3)}
          </GridButton>
        ))}
      </GridButtonGroup>
      {error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </Box>
  );
}

export const MonthsSelector = forwardRef(MonthsSelectorComponent);
