import { useCallback, useMemo, useState } from 'react';
import { DataGridWrapper, ErrorAlert } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { endOfDay } from 'date-fns';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridRenderCellParams,
  GridPinnedColumnFields,
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import { ConfigGuard } from '../../../components/ConfigGuard';
import { DeleteConfirmDialog } from '../../../components/DeleteConfirmDialog';
import { DeleteIcon, DuplicateIcon, EditIcon } from '../../../components/Icons';
import { PAGE_TITLE_OFFSET_HEIGHT, PageTitle } from '../../../components/PageTitle';
import { ScenarioLabel } from '../../../components/ScenarioLabel';
import { StyledDataGrid } from '../../../components/StyledDataGrid';
import { EditingTask, Task, TaskOverride } from '../../../entities/Task';
import { UserRoleName } from '../../../entities/User';
import { useAreaColDef } from '../../../hooks/useAreaColDef';
import { useCategoryColDef } from '../../../hooks/useCategoryColDef';
import { useDepartmentColDef } from '../../../hooks/useDepartmentColDef';
import { useDocumentUrlColDef } from '../../../hooks/useDocumentUrlColDef';
import { usePlannerConfig } from '../../../hooks/usePlannerConfig';
import { useProcessColDef } from '../../../hooks/useProcessColDef';
import { useScenarioColDef } from '../../../hooks/useScenarioColDef';
import { months, occurrencies, useScheduleColDefs, weekDays } from '../../../hooks/useScheduleColDefs';
import { useSubprocessColDef } from '../../../hooks/useSubprocessColDef';
import { useUsersColDefs } from '../../../hooks/useUsersColDefs';
import { useBatchDeleteTasksMutation, useDeleteTaskMutation, useReadTaskListQuery } from '../../../services/taskApi';
import { stripedGetRowClassName } from '../../../utils/datagrid';
import { durationColumn, hourColumn } from '../../../utils/date';
import { TaskAddButton } from './TaskAddButton';
import { TaskBatchEditDialog } from './TaskBatchEditDialog';
import { TaskEditDialog } from './TaskEditDialog/TaskEditDialog';
import { TaskOverrideDialog } from './TaskOverrideDialog/TaskOverrideDialog';
import { TaskRowDetailPanel } from './TaskRowDetailPanel';

const pinnedColumnFields: GridPinnedColumnFields = { right: ['actions'] };

export function TaskListGrid(): JSX.Element {
  const categoryColumn = useCategoryColDef();
  const scenarioColumn = useScenarioColDef();
  const areaColumn = useAreaColDef();
  const departmentColumn = useDepartmentColDef();
  const processColumn = useProcessColDef();
  const subprocessColumn = useSubprocessColDef();
  const documentUrlColumn = useDocumentUrlColDef();
  const scheduleColDefs = useScheduleColDefs();
  const { ownerColumn, backupColumn, reviewerColumn } = useUsersColDefs<Task>();
  const { isAdmin, username, hasRole } = useAuth();
  const { datagridProps, datagridInitialState } = usePlannerConfig();

  const [taskToDelete, setTaskToDelete] = useState<Task | null>(null);
  const [taskToEdit, setTaskToEdit] = useState<EditingTask | null>(null);
  const [taskToAddOverride, setTaskToAddOverride] = useState<{
    task: Task;
    day: Date;
    override: TaskOverride | undefined;
  } | null>(null);
  const [overrideToDelete, setOverrideToDelete] = useState<TaskOverride | null>(null);
  const [batchTaskEditOpen, setBatchTaskEditOpen] = useState(false);
  const [batchTaskDeleteOpen, setBatchTaskDeleteOpen] = useState(false);
  const [deleteTask, deleteTaskStatus] = useDeleteTaskMutation();
  const [batchDeleteTasks, batchDeleteTasksStatus] = useBatchDeleteTasksMutation();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const readTaskListQueryParams = useMemo(
    () => ({
      limit: 0,
      offset: 0,
      excludeDeleted: endOfDay(new Date()).toISOString(),
      hasUpdatedTask: false,
    }),
    [],
  );

  const taskList = useReadTaskListQuery(readTaskListQueryParams);

  const filteredTaskList = useMemo(() => {
    if (!taskList.data) {
      return [];
    }
    if (hasRole(UserRoleName.GUEST)) {
      return [];
    }
    if (hasRole(UserRoleName.ADMIN)) {
      return taskList.data;
    }
    return taskList.data.filter((task) => {
      if (
        task.owner?.username === username ||
        task.reviewer?.username === username ||
        task.backup?.username === username
      ) {
        return true;
      }
      return false;
    });
  }, [hasRole, taskList.data, username]);

  async function handleDeleteConfirm() {
    if (taskToDelete) {
      await deleteTask({ id: taskToDelete.id }).unwrap();
      setTaskToDelete(null);
    }
  }

  async function handleOverrideDeleteConfirm() {
    if (overrideToDelete) {
      await deleteTask({ id: overrideToDelete.id }).unwrap();
      setOverrideToDelete(null);
    }
  }

  async function handleBatchDeleteConfirm() {
    if (rowSelectionModel.length) {
      await batchDeleteTasks(rowSelectionModel.map((rowId) => Number(rowId))).unwrap();
      setRowSelectionModel([]);
      setBatchTaskDeleteOpen(false);
    }
  }

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      {
        field: 'id',
        headerName: '#',
        width: 70,
        groupable: false,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 300,
        groupable: false,
      },
      documentUrlColumn,
      { field: 'isDeliverable', type: 'boolean', headerName: 'Deliverable', width: 50 },
      { ...processColumn, valueGetter: (value, row: Task) => row.process?.id ?? '—' },
      { ...subprocessColumn, valueGetter: (value, row: Task) => row.subprocess?.id ?? '—' },
      {
        ...scenarioColumn,
        valueGetter: (value, row: Task) => row.scenario?.name,
        renderCell: ({ row }: GridRenderCellParams<Task>) => {
          if (!row) {
            return '—';
          }

          const scenario = row.scenario;
          return scenario ? <ScenarioLabel scenario={scenario} /> : '—';
        },
      },
      { ...categoryColumn, valueGetter: (value, row: Task) => row.category?.name ?? '—' },
      { ...areaColumn, valueGetter: (value, row: Task) => row.area?.id ?? '—' },
      { ...departmentColumn, valueGetter: (value, row: Task) => row.department?.id ?? '—' },
      ownerColumn((row) => row.owner),
      backupColumn((row) => row.backup),
      reviewerColumn((row) => row.reviewer),
      {
        field: 'entryNumber',
        headerName: 'Journal Entry',
        type: 'number',
        valueGetter: (value) => (value && value > 0 ? value : undefined),
      },
      {
        field: 'entryType',
        headerName: 'Entry Type',
        width: 90,
      },
      {
        field: 'reversal',
        headerName: 'Reversal',
      },
      {
        ...scheduleColDefs.monthColumn,
        valueGetter: (value, row: Task) =>
          months
            .map((_, month) => ((row.schedule?.month & Math.pow(2, month)) === 0 ? -1 : month))
            .filter((v) => v >= 0)
            .map((v) => `${v}`),
      },
      {
        ...scheduleColDefs.dayColumn,
        valueGetter: (value, row: Task) => row.schedule?.day,
      },
      {
        ...scheduleColDefs.workDayColumn,
        valueGetter: (value, row: Task) => row.schedule?.workDay,
      },
      {
        ...scheduleColDefs.occurrenceColumn,
        valueGetter: (value, row: Task) =>
          occurrencies
            .map((_, occurrence) => ((row.schedule?.occurrence & Math.pow(2, occurrence)) === 0 ? -1 : occurrence))
            .filter((v) => v >= 0)
            .map((v) => `${v}`),
      },
      {
        ...scheduleColDefs.weekDayColumn,
        valueGetter: (value, row: Task) =>
          weekDays
            .map((_, weekDay) => ((row.schedule?.weekDay & Math.pow(2, weekDay)) === 0 ? -1 : weekDay))
            .filter((v) => v >= 0)
            .map((v) => `${v}`),
      },
      {
        ...hourColumn,
        field: 'hourStart',
        valueGetter: (value, row: Task) =>
          row.dueHour && row.dueHour > 0 ? row.dueHour - (row.duration === -1 ? 5 : row.duration) : undefined,
        headerName: 'Start',
        width: 60,
      },
      {
        ...hourColumn,
        field: 'dueHour',
        headerName: 'Due hour',
        width: 80,
      },
      {
        ...durationColumn,
        field: 'duration',
        headerName: 'Duration',
        width: 80,
      },
      {
        ...hourColumn,
        field: 'dueHourMachine',
        headerName: 'Due hour machine',
        width: 130,
      },
      {
        ...durationColumn,
        valueGetter: (value: number) => (value && value > -1 ? value : undefined),
        field: 'durationMachine',
        headerName: 'Duration machine',
        width: 130,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 120,
        renderHeader: () => <TaskAddButton />,
        getActions: ({ row }: GridRowParams<Task>) => {
          let canEdit = isAdmin || username === row.owner?.username || username === row.reviewer?.username;

          if (hasRole(UserRoleName.MANAGER)) {
            canEdit = false;
            for (let i = 0; i < row.process.managerList.length; i++) {
              if (row.process.managerList[i].username === username) {
                canEdit = true;
              }
            }
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              key="edit"
              label="Modifica"
              disabled={!canEdit}
              onClick={() => setTaskToEdit(row)}
            />,
            <GridActionsCellItem
              icon={<DuplicateIcon />}
              key="duplicate"
              label="Duplica"
              disabled={!canEdit}
              onClick={() => {
                setTaskToEdit({ ...row, id: undefined });
              }}
            />,
            <GridActionsCellItem
              className={`delete-task-${row.id}`}
              icon={<DeleteIcon />}
              key="delete"
              label="Delete"
              disabled={!canEdit}
              onClick={() => setTaskToDelete(row)}
            />,
          ];
        },
      },
    ],
    [
      documentUrlColumn,
      processColumn,
      subprocessColumn,
      scenarioColumn,
      categoryColumn,
      areaColumn,
      departmentColumn,
      ownerColumn,
      backupColumn,
      reviewerColumn,
      scheduleColDefs.monthColumn,
      scheduleColDefs.dayColumn,
      scheduleColDefs.workDayColumn,
      scheduleColDefs.occurrenceColumn,
      scheduleColDefs.weekDayColumn,
      isAdmin,
      username,
      hasRole,
    ],
  );

  const tasksById = useMemo(() => {
    const tasksById = new Map<string, Task>();
    if (filteredTaskList) {
      for (let i = 0; i < filteredTaskList.length; i++) {
        const task = filteredTaskList[i];
        tasksById.set(`${task.id}`, task);
      }
    }
    return tasksById;
  }, [filteredTaskList]);

  const tasksToBatchEdit = useMemo(() => {
    return rowSelectionModel.map((id) => tasksById.get(`${id}`)).filter((t) => t !== undefined) as Task[];
  }, [rowSelectionModel, tasksById]);

  const initialState = datagridInitialState((config) => config.pages?.tasks?.datagrid);

  const getDetailPanelContent = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
    ({ row }) => (
      <TaskRowDetailPanel
        row={row}
        onEditOverride={(day, override) => setTaskToAddOverride({ task: row, day, override })}
        onDeleteOverride={(override) => setOverrideToDelete(override)}
      />
    ),
    [],
  );

  const getDetailPanelHeight = useCallback(() => 500, []);

  return (
    <>
      <PageTitle title={'Tasks'}>
        {rowSelectionModel.length > 1 ? (
          <Stack direction="row" gap={1}>
            <Button size="small" variant="outlined" onClick={() => setBatchTaskEditOpen(true)}>
              {'Edit Tasks'}
            </Button>
            <Button size="small" variant="outlined" color="error" onClick={() => setBatchTaskDeleteOpen(true)}>
              {'Delete Tasks'}
            </Button>
          </Stack>
        ) : null}
      </PageTitle>
      <Alert severity="info" sx={{ marginBottom: 2 }}>
        {'Expand the task rows to see the schedule preview and manage exceptions'}
      </Alert>
      {taskList.error ? <ErrorAlert error={taskList.error} sx={{ marginBottom: 1 }} /> : null}
      <ConfigGuard>
        <DataGridWrapper offsetHeight={PAGE_TITLE_OFFSET_HEIGHT}>
          <StyledDataGrid
            columns={columns}
            pinnedColumns={pinnedColumnFields}
            rows={filteredTaskList}
            loading={taskList.isFetching}
            checkboxSelection
            disableRowSelectionOnClick
            disableRowGrouping
            initialState={{
              ...initialState,
              sorting: {
                sortModel: [
                  {
                    field: 'id',
                    sort: 'desc',
                  },
                ],
              },
            }}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            pagination
            paginationMode="client"
            disableAggregation
            getRowClassName={stripedGetRowClassName}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            onCellClick={() => {
              //
            }}
            {...datagridProps((datagridConfig) => ({
              pages: { tasks: { datagrid: datagridConfig } },
            }))}
          />
          {taskToEdit && (
            <TaskEditDialog
              title={`Edit task "${taskToEdit.description}"`}
              open={Boolean(taskToEdit)}
              task={taskToEdit}
              onClose={() => setTaskToEdit(null)}
            />
          )}
          {taskToAddOverride && (
            <TaskOverrideDialog
              title={`Edit task "${taskToAddOverride.task.description}"`}
              open={Boolean(taskToAddOverride)}
              task={taskToAddOverride.task}
              day={taskToAddOverride.day}
              onClose={() => setTaskToAddOverride(null)}
              override={taskToAddOverride.override}
            />
          )}
          {tasksToBatchEdit && (
            <TaskBatchEditDialog
              tasks={tasksToBatchEdit}
              open={batchTaskEditOpen}
              onClose={() => {
                setBatchTaskEditOpen(false);
              }}
              onClosed={() => {
                setRowSelectionModel([]);
              }}
            />
          )}
          {taskToDelete && (
            <DeleteConfirmDialog
              title={`Are you sure to delete task ${taskToDelete.description}?`}
              confirmText={'confirm'}
              open={Boolean(taskToDelete)}
              onConfirm={handleDeleteConfirm}
              onClose={() => setTaskToDelete(null)}
              error={deleteTaskStatus.error}
              inProgress={deleteTaskStatus.isLoading}
            />
          )}
          {batchTaskDeleteOpen && (
            <DeleteConfirmDialog
              title={`Are you sure you want to delete ${tasksToBatchEdit.length} tasks?`}
              confirmText={'confirm'}
              open={batchTaskDeleteOpen}
              onConfirm={handleBatchDeleteConfirm}
              onClose={() => setBatchTaskDeleteOpen(false)}
              error={batchDeleteTasksStatus.error}
              inProgress={batchDeleteTasksStatus.isLoading}
            />
          )}
          {overrideToDelete && (
            // <DeleteConfirmDialog
            //   title={`Are you sure to delete the task exception?`}
            //   open={Boolean(overrideToDelete)}
            //   onConfirm={handleDeleteConfirm}
            //   onClose={() => {
            //     setOverrideToDelete(null);
            //   }}
            //   error={deleteTaskStatus.error}
            //   inProgress={deleteTaskStatus.isLoading}
            // />
            <DeleteConfirmDialog
              title={`Are you sure to delete this task exception?`}
              confirmText={'confirm'}
              open={Boolean(overrideToDelete)}
              onConfirm={handleOverrideDeleteConfirm}
              onClose={() => setOverrideToDelete(null)}
              error={deleteTaskStatus.error}
              inProgress={deleteTaskStatus.isLoading}
            />
          )}
        </DataGridWrapper>
      </ConfigGuard>
    </>
  );
}
