import React, { useId, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { MenuDownIcon } from '../Icons';

interface BreadcrumbSplitMenuProps {
  options: { value: string | number; label: string }[];
  onOptionClick: (e: unknown, value: string | number) => void;
  to: string;
  children: React.ReactNode | React.ReactNode[];
}

export function BreadcrumbSplitMenu(props: BreadcrumbSplitMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuId = useId();
  const buttonId = useId();

  return (
    <>
      <Stack
        direction="row"
        sx={{
          overflow: 'hidden',
          '& .nav-button': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            boxShadow: 'none',
          },
          '& .nav-menu': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            minWidth: 0,
            paddingX: 0.75,
            boxShadow: 'none',
          },
        }}
      >
        <Button
          className="nav-button"
          component={Link}
          to={props.to}
          variant="contained"
          color="inherit"
          fullWidth
          sx={{ minWidth: 0 }}
        >
          <Box
            component="span"
            sx={{ flex: '0 1 auto', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {props.children}
          </Box>
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          className="nav-menu"
          id={buttonId}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="contained"
          color="inherit"
          sx={{ flexShrink: 0 }}
        >
          <MenuDownIcon />
        </Button>
      </Stack>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
      >
        {props.options.map((option) => (
          <MuiMenuItem
            key={option.value}
            onClick={(e) => {
              props.onOptionClick(e, option.value);
              handleClose();
            }}
          >
            {option.label}
          </MuiMenuItem>
        ))}
      </Menu>
    </>
  );
}
