import React from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { StackProps } from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import { CheckIcon } from './Icons';

interface ButtonGroupProps extends StackProps {
  children: React.ReactNode[];
  wrapAfter: number;
}

/**
 * Like MUI ButtonGroup, but wrapping.
 */
function GridButtonGroupComponent(props: ButtonGroupProps) {
  const { children, wrapAfter, sx } = props;

  if (!children) {
    return <></>;
  }

  const lastRow =
    children.length === wrapAfter
      ? 0
      : children.length % wrapAfter === 0
        ? props.wrapAfter + 1
        : children.length - (children.length % wrapAfter) + 1;

  return (
    <Box
      sx={{
        ...sx,
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, ${100 / wrapAfter}%)`,
        border: '1px solid',
        borderColor: (theme) => theme.palette.primary.main,
        borderRadius: 1,
        '.MuiButton-root': {
          borderBottom: '1px solid',
          borderRight: '1px solid',
          borderRadius: 0,
        },
        [`& .MuiButton-root:nth-of-type(n+${lastRow})`]: {
          borderBottom: 0,
        },
        [`& .MuiButton-root:nth-of-type(${wrapAfter}n)`]: {
          borderRight: 0,
        },
      }}
    >
      {children}
    </Box>
  );
}

export const GridButtonGroup = React.memo(GridButtonGroupComponent);

function GridButtonComponent(
  props: Pick<ButtonProps, 'children' | 'disabled'> & {
    checked: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, checked: boolean) => void;
  },
) {
  const { checked, onClick, disabled, children } = props;

  return (
    <Button
      variant="text"
      onClick={(e) => onClick?.(e, !checked)}
      disabled={disabled}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
        backgroundColor: checked ? (theme) => alpha(theme.palette.primary.main, 0.1) : undefined,
        cursor: onClick ? undefined : 'default',
        pointerEvents: onClick ? undefined : 'none',
        paddingLeft: 0,
      }}
    >
      <Box
        sx={{
          transition: (theme) => theme.transitions.create(['width', 'opacity']),
          width: checked ? '1em' : 0,
          display: 'flex',
          opacity: checked ? 1 : 0,
        }}
      >
        {checked ? <CheckIcon fontSize="small" sx={{ marginRight: 0.5 }} /> : <Box />}
      </Box>
      {children}
    </Button>
  );
}

export const GridButton = React.memo(GridButtonComponent);
