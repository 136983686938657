import { z } from 'zod';
import { DayTimetableSchema } from './UserTimetable';

export enum UserRoleName {
  GUEST = 'guest',
  USER = 'user',
  MANAGER = 'manager',
  ADMIN = 'admin',
  DELETED = 'deleted',
}

export const UserSchema = z.object({
  id: z.number(),
  role: z.nativeEnum(UserRoleName),
  email: z.string().optional(),
  username: z.string().min(1).max(255),
  firstName: z.string().max(255).optional(),
  lastName: z.string().max(255).optional(),
  timetable: DayTimetableSchema.array().nullable(),
});

export const GuestUserFormSchema = z.object({
  email: z.string(),
  username: z.string().optional(),
  firstName: z.string().max(255),
  lastName: z.string().max(255),
});

export const UserRoleSchema = z.object({
  id: z.number().min(-1),
  name: z.string(),
});

export type User = z.infer<typeof UserSchema>;
export type GuestUserForm = z.infer<typeof GuestUserFormSchema>;
export type UserRole = z.infer<typeof UserRoleSchema>;
