import { Subprocess, SubprocessSchema } from '../entities/Subprocess';
import { api } from './baseApi';

const url = 'subprocesses';

const subprocessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readSubprocessList: builder.query<Subprocess[], void>({
      query: () => ({ url: url }),
      transformResponse: (data) => SubprocessSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadSubprocessListQuery } = subprocessApi;
