import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { TaskStatus, taskStatusLabels } from '../entities/Task';

interface TaskStatusChipProps {
  value: TaskStatus;
  badge?: boolean;
}

function TaskStatusChipComponent(props: TaskStatusChipProps) {
  const { badge, value } = props;
  const theme = useTheme();

  const chipColor: Record<TaskStatus, string> = {
    [TaskStatus.Overdue]: theme.palette.taskStatus.overdue,
    [TaskStatus.OnTime]: theme.palette.taskStatus.onTime,
    [TaskStatus.Late]: theme.palette.taskStatus.late,
    [TaskStatus.NotApplicable]: theme.palette.taskStatus.notApplicable,
    [TaskStatus.Open]: theme.palette.taskStatus.open,
  };

  const color = chipColor[value];
  const label = taskStatusLabels[value ?? TaskStatus.Open];

  if (badge) {
    return (
      <Tooltip title={label}>
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            position: 'relative',
            width: '1em',
            height: '1em',
            top: '0.125em',
            borderRadius: '50%',
            backgroundColor: chipColor[value],
          }}
        />
      </Tooltip>
    );
  }

  return (
    <Chip variant="outlined" size="small" label={label} sx={{ fontWeight: 500, borderColor: color, color: color }} />
  );
}

export const TaskStatusChip = React.memo(TaskStatusChipComponent);
