import { NumberUtils } from '@top-solution/microtecnica-utils';

const numberUtils = new NumberUtils({});

export const DEFAULT_TARGET = 0.95;

export function formatPercentage(value: number, decimals = 0): string {
  return numberUtils.format(value, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    style: 'percent',
  });
}
