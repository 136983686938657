import { Category, CategorySchema } from '../entities/Category';
import { Process, ScenarioProcess, ScenarioProcessSchema } from '../entities/Process';
import { api, TAG_TYPES } from './baseApi';

const url = 'categories';

const categoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readCategoryList: builder.query<Array<Category & { process: ScenarioProcess }>, void>({
      query: () => ({ url: url }),
      transformResponse: (data) => CategorySchema.extend({ process: ScenarioProcessSchema }).array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.CATEGORY, id: 'LIST' }],
    }),
    createCategory: builder.mutation<void, Omit<Category, 'id'> & { processId: Process['id'] }>({
      query: (body) => ({ url: `${url}/`, method: 'POST', body }),
      invalidatesTags: () => [
        { type: TAG_TYPES.CATEGORY, id: 'LIST' },
        { type: TAG_TYPES.PROCESS, id: 'LIST' },
      ],
    }),
    updateCategory: builder.mutation<void, Category & { processId: Process['id'] }>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'PUT', body }),
      invalidatesTags: () => [
        { type: TAG_TYPES.CATEGORY, id: 'LIST' },
        { type: TAG_TYPES.PROCESS, id: 'LIST' },
      ],
    }),
    deleteCategory: builder.mutation<void, { categoryId: Category['id']; processId: Process['id'] }>({
      query: ({ categoryId }) => ({ url: `${url}/${categoryId}`, method: 'DELETE' }),
      invalidatesTags: () => [
        { type: TAG_TYPES.CATEGORY, id: 'LIST' },
        { type: TAG_TYPES.PROCESS, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useReadCategoryListQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;
