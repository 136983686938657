import { Day, Month, getDay } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';

export function hasMonth(date: Date, month: number): boolean {
  // Use exponentiation operator as faster replacement for Math.pow(2, x)
  return (month & (2 ** date.getMonth())) !== 0;
}

export function hasDay(date: Date, day: number): boolean {
  return day === date.getDate();
}

export function hasWeekDay(date: Date, weekdays: number): boolean {
  return (weekdays & Math.pow(2, ((getDay(date) || 7) + 6) % 7)) !== 0;
}

export function monthName(month: number, short = false) {
  const n = enGB.localize?.month(month as Month);
  if (short) {
    return n.substring(0, 3);
  }
  return n;
}

export function weekDayName(weekDay: number, short = false) {
  const n = enGB.localize?.day(((weekDay + 1) % 7) as Day);
  if (short) {
    return n.substring(0, 3);
  }
  return n;
}
