import { useMemo, useState } from 'react';
import { DataGridWrapper, DeleteConfirmDialog, ErrorAlert } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridPinnedColumnFields,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { ConfigGuard } from '../../../components/ConfigGuard';
import { PlusIcon, DeleteIcon, EditIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { PAGE_TITLE_OFFSET_HEIGHT, PageTitle } from '../../../components/PageTitle';
import { StyledDataGrid } from '../../../components/StyledDataGrid';
import { Turnback } from '../../../entities/Turnback';
import { usePlannerConfig } from '../../../hooks/usePlannerConfig';
import { useDeleteTurnbackMutation, useReadTurnbackListQuery } from '../../../services/turnbackApi';
import { stripedGetRowClassName } from '../../../utils/datagrid';
import { EditTurnbackDialog } from './EditTurnabackDialog';

const title = 'Manage Turnbacks';
const breadcrumbs = [{ title }];
const pinnedColumnFields: GridPinnedColumnFields = { right: ['actions'] };

export function TurnbackList(): JSX.Element {
  const turnbackList = useReadTurnbackListQuery();
  const [turnbackToEdit, setTurnbackToEdit] = useState<null | Turnback>(null);
  const [turnbackToDelete, setTurnbackToDelete] = useState<null | Turnback>(null);
  const [editingTurnback, setEditingTurnback] = useState(false);
  const [deletingTurnback, setDeletingTurnback] = useState(false);
  const { datagridProps, datagridInitialState } = usePlannerConfig();

  const [deleteTurnback, deleteTurnbackRequest] = useDeleteTurnbackMutation();

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 90,
        renderHeader: () => (
          <Tooltip title={title} placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                setTurnbackToEdit(null);
                setEditingTurnback(true);
              }}
            >
              <PlusIcon />
            </IconButton>
          </Tooltip>
        ),
        getActions: ({ row }: GridRowParams<Turnback>) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            key="edit"
            label="Edit turnback"
            onClick={() => {
              setTurnbackToEdit(row);
              setEditingTurnback(true);
            }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            key="edit"
            label="Delete turnback"
            onClick={() => {
              setTurnbackToDelete(row);
              setDeletingTurnback(true);
            }}
          />,
        ],
      },
    ],
    [],
  );

  const initialState = datagridInitialState((config) => config.pages?.turnbacks?.datagrid);

  return (
    <>
      <Layout
        title={title}
        breadcrumbs={breadcrumbs}
        maxWidth={false}
        sx={{ '.MuiDataGrid-cell .MuiChip-root': { marginRight: 0.125 } }}
      >
        <PageTitle title={title} />
        {turnbackList.error ? <ErrorAlert error={turnbackList.error} /> : null}
        <Stack direction="column" width="100%" gap={1}>
          <Box sx={{ flex: '1 1 0', minWidth: 0 }}>
            <ConfigGuard>
              <DataGridWrapper offsetHeight={PAGE_TITLE_OFFSET_HEIGHT}>
                <StyledDataGrid
                  initialState={initialState}
                  columns={columns}
                  pinnedColumns={pinnedColumnFields}
                  rows={turnbackList.data ?? []}
                  loading={turnbackList.isFetching}
                  getRowClassName={stripedGetRowClassName}
                  disableRowSelectionOnClick
                  disableRowGrouping
                  disableAggregation
                  disableColumnReorder
                  disableColumnSelector
                  {...datagridProps((datagridConfig) => ({
                    pages: { turnbacks: { datagrid: datagridConfig } },
                  }))}
                />
              </DataGridWrapper>
            </ConfigGuard>
          </Box>
        </Stack>
      </Layout>
      {turnbackToDelete && (
        <DeleteConfirmDialog
          open={deletingTurnback}
          onClose={() => setDeletingTurnback(false)}
          inProgress={deleteTurnbackRequest.isLoading}
          onConfirm={async () => {
            await deleteTurnback({ turnbackId: turnbackToDelete.id }).unwrap();
            setTurnbackToDelete(null);
            setDeletingTurnback(false);
          }}
          title={`Are you sure to delete the Turnback "${turnbackToDelete.name}"?`}
          description={`This turnback will be not available anymore for new tasks, but existing taks will be not impacted.`}
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          confirmColor="error"
          confirmText="Delete Turnback"
        />
      )}
      <EditTurnbackDialog
        turnback={turnbackToEdit ?? undefined}
        open={editingTurnback}
        onClose={() => setEditingTurnback(false)}
      />
    </>
  );
}
