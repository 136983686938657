import React from 'react';
import { useNavigate, useMatch, Outlet } from 'react-router-dom';
import { GridButtonGroup, GridButton } from '../../components/GridButtonGroup';
import { OverviewHeader } from './layout/OverviewHeader';
import { OverviewLayout } from './layout/OverviewLayout';
import { useOverviewQueryParams } from './overviewQueryParams';

function ScenarioProcessesPageComponent() {
  const navigate = useNavigate();
  const byUser = useMatch('overview/scenarios/:scenarioName/by-user') !== null;
  const { searchParams } = useOverviewQueryParams();

  return (
    <OverviewLayout>
      <OverviewHeader showTasksCountCheckbox showEmptyCardsCheckbox>
        <GridButtonGroup sx={{ width: 320 }} wrapAfter={2}>
          <GridButton key="processes" onClick={() => navigate(`./processes?${searchParams}`)} checked={!byUser}>
            {'Processes'}
          </GridButton>
          <GridButton key="by-user" onClick={() => navigate(`./by-user?${searchParams}`)} checked={byUser}>
            {'By user'}
          </GridButton>
        </GridButtonGroup>
      </OverviewHeader>
      <Outlet />
    </OverviewLayout>
  );
}

export const ScenarioProcessesPage = React.memo(ScenarioProcessesPageComponent);
