import { Route, Routes, useNavigate } from 'react-router-dom';
import { AuthCallback } from '@top-solution/microtecnica-utils';
import { NavigateWithQs } from './components/NavigateWithQs';
import { AdminRouter } from './pages/Admin';
import { ManageTasksPage } from './pages/Admin/Task/ManageTasksPage';
import { NotFoundPage } from './pages/Error/NotFoundPage';
import { UnauthorizedPage } from './pages/Error/UnauthorizedPage';
import { GanttPage } from './pages/Gantt/GanttPage';
import { HomePage } from './pages/Home';
import { CompletionByUser } from './pages/Overview/CompletionByUser';
import { OverviewTasksDatagrid } from './pages/Overview/components/OverviewTasksDatagrid';
import { ProcessesGrid } from './pages/Overview/grids/ProcessesGrid';
import { ScenariosGrid } from './pages/Overview/grids/ScenariosGrid';
import { SubProcessesGrid } from './pages/Overview/grids/SubProcessesGrid';
import { OverviewGanttTasksProvider } from './pages/Overview/OverviewGanttTasks';
import { OverviewPage } from './pages/Overview/OverviewPage';
import { ProcessSubprocessesPage } from './pages/Overview/ProcessSubprocessesPage';
import { ScenarioProcessesPage } from './pages/Overview/ScenarioProcessesPage';
import { SubprocessPage } from './pages/Overview/SubprocessPage';
import { TasksPage } from './pages/Overview/TasksPage';
import { PeopleLoadPage } from './pages/PeopleLoad/PeopleLoadPage';
import { PerformancePage } from './pages/Performance/PerformancePage';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="overview" element={<OverviewGanttTasksProvider />}>
        <Route path="" element={<OverviewPage />}>
          <Route index element={<NavigateWithQs to="./processes" replace />} />
          <Route path="processes" element={<ProcessesGrid />} />
          <Route path="scenarios" element={<ScenariosGrid />} />
        </Route>
        <Route path="processes/:processId" element={<ProcessSubprocessesPage rootRoute="processes/:processId" />}>
          <Route index element={<NavigateWithQs to="./subprocesses" replace />} />
          <Route path="subprocesses" element={<SubProcessesGrid />} />
          <Route path="by-user" element={<CompletionByUser />} />
          <Route path="tasks" element={<OverviewTasksDatagrid />} />
        </Route>
        <Route path="processes/:processId/by-user/:ownerUserName/tasks" element={<TasksPage />} />
        <Route
          path="processes/:processId/subprocesses/:subprocessId"
          element={<SubprocessPage rootRoute="processes/:processId/subprocesses/:subprocessId" />}
        >
          <Route index element={<NavigateWithQs to="./tasks" replace />} />
          <Route path="by-user" element={<CompletionByUser />} />
          <Route path="tasks" element={<OverviewTasksDatagrid />} />
        </Route>
        <Route
          path="processes/:processId/subprocesses/:subprocessId/by-user/:ownerUserName/tasks"
          element={<TasksPage />}
        />
        <Route path="scenarios/:scenarioName" element={<ScenarioProcessesPage />}>
          <Route index element={<NavigateWithQs to="./processes" replace />} />
          <Route path="processes" element={<ProcessesGrid />} />
          <Route path="by-user" element={<CompletionByUser />} />
          <Route path="tasks" element={<OverviewTasksDatagrid />} />
        </Route>
        <Route path="scenarios/:scenarioName/by-user/:ownerUserName/tasks" element={<TasksPage />} />
        <Route
          path="scenarios/:scenarioName/processes/:processId"
          element={<ProcessSubprocessesPage rootRoute="scenarios/:scenarioName/processes/:processId" />}
        >
          <Route index element={<NavigateWithQs to="./subprocesses" replace />} />
          <Route path="subprocesses" element={<SubProcessesGrid />} />
          <Route path="by-user" element={<CompletionByUser />} />
          <Route path="tasks" element={<OverviewTasksDatagrid />} />
        </Route>
        <Route
          path="scenarios/:scenarioName/processes/:processId/by-user/:ownerUserName/tasks"
          element={<TasksPage />}
        />
        <Route
          path="scenarios/:scenarioName/processes/:processId/subprocesses/:subprocessId"
          element={
            <SubprocessPage rootRoute="scenarios/:scenarioName/processes/:processId/subprocesses/:subprocessId" />
          }
        >
          <Route index element={<NavigateWithQs to="./tasks" replace />} />
          <Route path="by-user" element={<CompletionByUser />} />
          <Route path="tasks" element={<OverviewTasksDatagrid />} />
        </Route>
        <Route
          path="scenarios/:scenarioName/processes/:processId/subprocesses/:subprocessId/by-user/:ownerUserName/tasks"
          element={<TasksPage />}
        />
      </Route>
      <Route path="gantt" element={<GanttPage />} />
      <Route path="tasks" element={<ManageTasksPage />} />
      <Route path="people-load" element={<PeopleLoadPage />} />
      <Route path="performance" element={<PerformancePage />} />
      <Route path="admin/*" element={<AdminRouter />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
