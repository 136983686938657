import { OverviewTasksDatagrid } from './components/OverviewTasksDatagrid';
import { OverviewHeader } from './layout/OverviewHeader';
import { OverviewLayout } from './layout/OverviewLayout';

export function TasksPage() {
  return (
    <OverviewLayout>
      <OverviewHeader></OverviewHeader>
      <OverviewTasksDatagrid />
    </OverviewLayout>
  );
}
