import React from 'react';
import { ConfirmDialog, ConfirmDialogProps } from '@top-solution/microtecnica-mui';

function DeleteConfirmDialogComponent(props: ConfirmDialogProps): JSX.Element {
  return (
    <ConfirmDialog
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
      alertTitleText="This operation cannot be reverted."
      alertMessageText={
        <>
          To proceed, please write <code>{props.confirmText}</code>
        </>
      }
      confirmColor="error"
      {...props}
    />
  );
}

export const DeleteConfirmDialog = React.memo(DeleteConfirmDialogComponent);
