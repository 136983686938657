import React, { useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { GanttTask } from '../../entities/Task';
import { useGanttTasks } from '../../hooks/useGanttTasks';
import { useOverviewQueryParams } from './overviewQueryParams';

const OverviewGanttTasksContext = React.createContext({
  filteredGanttTasks: [] as GanttTask[],
  ganttTasks: [] as GanttTask[],
  error: undefined as FetchBaseQueryError | SerializedError | undefined,
  isLoading: false as boolean,
});

export function OverviewGanttTasksProvider() {
  const { from, to } = useOverviewQueryParams();
  const { ganttTasks, error, isLoading } = useGanttTasks({ username: null, from, to });
  const params = useParams<{
    processId?: string;
    scenarioName?: string;
    subprocessId: string;
    ownerUserName: string;
  }>();

  const filteredGanttTasks = ganttTasks.filter((gt) => {
    if (params.processId !== undefined && Number(params.processId) !== gt.task.process?.id) {
      return false;
    }
    if (params.scenarioName !== undefined && params.scenarioName !== gt.task.scenario?.name) {
      return false;
    }
    if (params.subprocessId !== undefined && Number(params.subprocessId) !== gt.task.subprocess?.id) {
      return false;
    }
    return true;
  });

  return (
    <OverviewGanttTasksContext.Provider value={{ filteredGanttTasks, ganttTasks, error, isLoading }}>
      <Outlet />
    </OverviewGanttTasksContext.Provider>
  );
}

export function useOverviewGanttTasks() {
  return useContext(OverviewGanttTasksContext);
}
