import { useNavigate, useMatch, Outlet } from 'react-router-dom';
import { GridButtonGroup, GridButton } from '../../components/GridButtonGroup';
import { OverviewHeader } from './layout/OverviewHeader';
import { OverviewLayout } from './layout/OverviewLayout';
import { useOverviewQueryParams } from './overviewQueryParams';

export function ProcessSubprocessesPage(props: { rootRoute: string }) {
  const navigate = useNavigate();
  const byUserTab = useMatch(`overview/${props.rootRoute}/by-user`) !== null;
  const { searchParams } = useOverviewQueryParams();

  return (
    <OverviewLayout>
      <OverviewHeader showTasksCountCheckbox={!byUserTab} showEmptyCardsCheckbox={!byUserTab}>
        <GridButtonGroup sx={{ width: 320 }} wrapAfter={2}>
          <GridButton
            key="subprocesses"
            onClick={() => navigate(`./subprocesses?${searchParams}`)}
            checked={!byUserTab}
          >
            {'Subprocesses'}
          </GridButton>
          <GridButton key="scenario" onClick={() => navigate(`./by-user?${searchParams}`)} checked={byUserTab}>
            {'By user'}
          </GridButton>
        </GridButtonGroup>
      </OverviewHeader>
      <Outlet />
    </OverviewLayout>
  );
}
