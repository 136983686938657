import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { Process } from '../entities/Process';
import { GanttTask } from '../entities/Task';
import { emptyFilterValueOption } from '../utils/datagrid';
import { useProcessList } from './useProcessList';

export function useProcessColDef(planId?: string): GridColDef {
  const { data: processList } = useProcessList(planId);

  const processMap = useMemo(() => {
    const processMap = new Map<Process['id'], Process>();
    if (processList) {
      for (let i = 0; i < processList.length; i++) {
        processMap.set(processList[i].id, processList[i]);
      }
    }
    return processMap;
  }, [processList]);

  return useMemo<GridColDef>(
    () => ({
      field: 'process',
      headerName: 'Process',
      type: 'singleSelect',
      valueOptions: processList
        ? [emptyFilterValueOption].concat(processList.map((process) => ({ label: process.name, value: process.id })))
        : [],
      valueFormatter: (value: Process['id']) => processMap.get(value)?.shortName,
      valueGetter: (value, row: GanttTask) => row.task.process?.id,
      renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, Process['id']>) =>
        value && processMap.get(value)?.shortName,
      width: 80,
    }),
    [processList, processMap],
  );
}
