import { memo } from 'react';
import { FieldError } from 'react-hook-form';
import { Day } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridButton, GridButtonGroup } from '../../../../../../components/GridButtonGroup';
import { Title } from '../Title';
// import { numberToBinaryString } from '../utils';

// // type WeekDaysRecord = Record<number, boolean>;

// export const weekDaysRecord = () => {
//   const m: WeekDaysRecord = {};
//   for (let i = 1; i <= 7; i++) {
//     m[i] = false;
//   }
//   return m;
// };

// export const printWeekDays = (weekDays?: WeekDaysRecord) => {
//   let weekDaysPrint = 0;
//   if (!weekDays) {
//     return '1111111';
//   }
//   for (let i = 1; i <= 7; i++) {
//     if (weekDays[i]) {
//       weekDaysPrint = weekDaysPrint + Math.pow(2, i - 1);
//     }
//   }
//   return numberToBinaryString(weekDaysPrint, 7);
// };

interface WeekDayDaysSelectorProps {
  value: number;
  onChange: (weekDay: number) => void;
  error: FieldError | undefined;
}

function WeekDayDaysSelectorComponent(props: WeekDayDaysSelectorProps) {
  const { value, onChange, error } = props;

  function setAllWeekdays(enabled: boolean) {
    onChange(enabled ? Math.pow(2, 7) - 1 : 0);
  }

  return (
    <Stack direction="column">
      <Title title="Weekdays:" onSelectNone={() => setAllWeekdays(false)} onSelectAll={() => setAllWeekdays(true)} />
      <GridButtonGroup sx={{ width: '100%', marginBottom: 1 }} wrapAfter={7}>
        {Array.from(new Array(7)).map((_, weekDay) => (
          <GridButton
            key={weekDay}
            onClick={() => onChange(value ^ Math.pow(2, weekDay))}
            checked={(value & Math.pow(2, weekDay)) !== 0}
          >
            {enGB.localize?.day(((weekDay + 1) % 7) as Day)?.substring(0, 3)}
          </GridButton>
        ))}
      </GridButtonGroup>
      {error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </Stack>
  );
}

export const WeekDayDaysSelector = memo(WeekDayDaysSelectorComponent);
