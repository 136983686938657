import { Area, AreaSchema } from '../entities/Area';
import { api } from './baseApi';

const url = '/areas';

const areaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readAreaList: builder.query<Area[], void>({
      // FIXME
      query: () => ({ url }),
      transformResponse: (data) => AreaSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadAreaListQuery } = areaApi;
