import { Process, ProcessForm, ProcessSchema } from '../entities/Process';
import { Scenario, ScenarioSchema } from '../entities/Scenario';
import { api, TAG_TYPES } from './baseApi';

const url = 'processes';

const processApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readProcessList: builder.query<Process[], string | void>({
      query: (planId) => ({
        url: `${url}`,
        params: planId ? { plan: planId } : undefined,
      }),
      transformResponse: (data) => ProcessSchema.array().parse(data),
      providesTags: (response) =>
        [{ type: TAG_TYPES.PROCESS, id: `LIST` }].concat(
          response?.map((process) => ({
            type: TAG_TYPES.PROCESS,
            id: `${process.id}`,
          })) ?? [],
        ),
    }),
    readProcessScenarioList: builder.query<Scenario[], number>({
      query: (processId) => ({ url: `${url}/${processId}/scenarios` }),
      transformResponse: (data) => ScenarioSchema.array().parse(data),
      providesTags: (response, error, processId) => [{ type: TAG_TYPES.SCENARIO, id: `LIST~${processId}` }],
    }),
    createProcess: builder.mutation<void, ProcessForm>({
      query: (process) => ({ url, method: 'POST', body: process }),
      invalidatesTags: () => [{ type: TAG_TYPES.PROCESS, id: 'LIST' }],
    }),
    updateProcess: builder.mutation<void, ProcessForm>({
      query: (process) => ({
        url: `${url}/${process.id}`,
        method: 'PUT',
        body: process,
      }),
      invalidatesTags: (response, error, process) => [
        { type: TAG_TYPES.PROCESS, id: `LIST` },
        { type: TAG_TYPES.PROCESS, id: `LIST~${process.id}` },
      ],
    }),
    removeProcess: builder.mutation<void, Process>({
      query: (process) => ({ url: `${url}/${process.id}`, method: 'DELETE' }),
      invalidatesTags: (response, error, process) => [
        { type: TAG_TYPES.PROCESS, id: 'LIST' },
        { type: TAG_TYPES.PROCESS, id: `LIST~${process.id}` },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useReadProcessListQuery, useCreateProcessMutation, useUpdateProcessMutation, useRemoveProcessMutation } =
  processApi;
