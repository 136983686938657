import React, { useCallback, useState } from 'react';
import { isSameDay } from 'date-fns';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { Task, TaskOverride } from '../../../entities/Task';
import { useTaskScheduling } from '../../../hooks/useTaskScheduling';
import { Preview } from './TaskEditDialog/ScheduleForm/Preview/Preview';

interface TaskRowDetailPanelProps {
  row: Task;
  onEditOverride: (day: Date, override: TaskOverride | undefined) => void;
  onDeleteOverride: (override: TaskOverride) => void;
}

function TaskRowDetailPanelComponent(props: TaskRowDetailPanelProps) {
  const { row, onEditOverride, onDeleteOverride } = props;
  const [previewYear, setPreviewYear] = useState<number>(new Date().getFullYear());

  const [clickContext, setClickContext] = useState<null | {
    anchorEl: HTMLElement;
    day: Date;
    override: TaskOverride | undefined;
  }>(null);

  const dayOverride = useCallback(
    (day: Date) => {
      return row.overrides?.find((override) => isSameDay(override.overrideDay, day));
    },
    [row.overrides],
  );

  const { dayHasTask } = useTaskScheduling(previewYear, previewYear);
  return (
    <Stack sx={{ py: 2, px: 1, maxWidth: 'calc(100vw - 56px)', boxSizing: 'border-box' }} direction="column">
      <Preview
        open={true}
        onToggle={() => {
          //
        }}
        year={previewYear}
        onYearChange={(year) => setPreviewYear(year)}
        isDayChecked={(date) => dayHasTask(row, date).hasTask}
        getDayColor={(day: Date) => {
          if (dayOverride(day)) {
            return 'warning';
          }
          return undefined;
        }}
        onDayClick={(event, day) => {
          setClickContext({
            anchorEl: event.currentTarget,
            day,
            override: dayOverride(day),
          });
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={clickContext?.anchorEl ?? null}
        open={Boolean(clickContext)}
        onClose={() => {
          setClickContext(null);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {clickContext?.override ? (
          [
            <MenuItem
              key="edit"
              onClick={() => {
                onEditOverride(clickContext.day, clickContext.override);
                setClickContext(null);
              }}
            >
              Edit exception
            </MenuItem>,
            <MenuItem
              key="delete"
              onClick={() => {
                onDeleteOverride(clickContext.override!);
                setClickContext(null);
              }}
            >
              Delete exception
            </MenuItem>,
          ]
        ) : clickContext ? (
          <MenuItem
            onClick={() => {
              onEditOverride(clickContext.day, undefined);
              setClickContext(null);
            }}
          >
            Add exception
          </MenuItem>
        ) : null}
      </Menu>
    </Stack>
  );
}

export const TaskRowDetailPanel = React.memo(TaskRowDetailPanelComponent);
