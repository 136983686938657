import React from 'react';
import { GridButton, GridButtonGroup } from '../../components/GridButtonGroup';

interface AssigneeVisibilitySelectorProps {
  owner: boolean;
  backup: boolean;
  reviewer: boolean;
  onAssegneeVisibilityChange: (params: { owner: boolean; backup: boolean; reviewer: boolean }) => void;
}

function AssigneeVisibilitySelectorComponent(props: AssigneeVisibilitySelectorProps) {
  const { owner, backup, reviewer, onAssegneeVisibilityChange } = props;

  return (
    <GridButtonGroup wrapAfter={3} sx={{ minWidth: 380 }}>
      <GridButton checked={owner} onClick={() => onAssegneeVisibilityChange({ owner: !owner, backup, reviewer })}>
        Owner
      </GridButton>
      <GridButton checked={backup} onClick={() => onAssegneeVisibilityChange({ owner, backup: !backup, reviewer })}>
        Backup
      </GridButton>
      <GridButton checked={reviewer} onClick={() => onAssegneeVisibilityChange({ owner, backup, reviewer: !reviewer })}>
        Reviewer
      </GridButton>
    </GridButtonGroup>
  );
}

export const AssigneeVisibilitySelector = React.memo(AssigneeVisibilitySelectorComponent);
