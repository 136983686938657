import React from 'react';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';

interface CardRadioGroupProps extends RadioGroupProps {}

function CardRadioGroupComponent(props: CardRadioGroupProps) {
  const { children, ...otherProps } = props;
  return (
    <RadioGroup {...otherProps}>
      <Stack direction="column" gap={1}>
        {children}
      </Stack>
    </RadioGroup>
  );
}

export const CardRadioGroup = React.memo(CardRadioGroupComponent);
