import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography, { TypographyProps } from '@mui/material/Typography';

function TitleComponent(
  props: TypographyProps & {
    title: string;
    onSelectAll?: () => void;
    onSelectNone?: () => void;
  },
) {
  return (
    <Typography
      component="div"
      fontWeight={500}
      gutterBottom={props.gutterBottom ?? true}
      display="flex"
      flexDirection="row"
      alignItems="baseline"
      gap={0.5}
      sx={{
        '.MuiButton-root': {
          paddingTop: '3px',
          paddingBottom: '1px',
          fontSize: '0.75em',
          lineHeight: '1.4',
        },
      }}
    >
      <Box sx={{ marginRight: 'auto' }}>{props.title}</Box>
      {props.onSelectNone ? (
        <Button variant="outlined" size="small" onClick={props.onSelectNone}>
          Select none
        </Button>
      ) : null}
      {props.onSelectAll ? (
        <Button variant="outlined" size="small" onClick={props.onSelectAll}>
          Select all
        </Button>
      ) : null}
    </Typography>
  );
}

export const Title = React.memo(TitleComponent);
