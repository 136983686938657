import * as React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ColorPicker } from '../../../components/ColorPicker';
import { Process } from '../../../entities/Process';
import { Scenario, ScenarioSchema } from '../../../entities/Scenario';
import { useCreateScenarioMutation, useUpdateScenarioMutation } from '../../../services/scenarioApi';

interface EditScenarioDialogProps {
  disabled: boolean;
  scenario?: Omit<Scenario, 'processId'>;
  processId: Process['id'];
  onClose: () => void;
}

function EditScenarioDialogComponent(props: EditScenarioDialogProps) {
  const { scenario, processId, onClose, disabled } = props;
  const [open, setOpen] = React.useState(false);
  const [createScenario, createScenarioStatus] = useCreateScenarioMutation();
  const [updateScenario, updateScenarioStatus] = useUpdateScenarioMutation();
  const theme = useTheme();

  const { control, reset, setValue, handleSubmit } = useForm<Pick<Scenario, 'name' | 'color'>>({
    defaultValues: { name: '', color: theme.palette.grey[200].substring(1) },
    resolver: zodResolver(ScenarioSchema.pick({ name: true, color: true })),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = (data: Pick<Scenario, 'name' | 'color'>) => {
    if (scenario) {
      updateScenario({ ...scenario, ...data, color: data.color.replace('#', ''), processId });
    } else {
      createScenario({ ...data, color: data.color.replace('#', ''), processId });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (scenario) {
      setOpen(true);
      setValue('name', scenario.name);
      setValue('color', `#${scenario.color}`);
    }
  }, [scenario, setValue]);

  const error = createScenarioStatus.error ?? updateScenarioStatus.error;

  return (
    <>
      <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} disabled={disabled}>
        {'Add Scenario'}
      </Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        TransitionProps={{
          onExited: () => {
            reset();
            createScenarioStatus.reset();
            updateScenarioStatus.reset();
            onClose();
          },
        }}
      >
        <form
          onSubmit={handleSubmit(handleCreate)}
          onReset={() => {
            reset();
            handleClose();
          }}
        >
          <DialogTitle>{`${scenario ? 'Edit' : 'Add'} Scenario`}</DialogTitle>
          <DialogContent>
            {error && <ErrorAlert error={error} sx={{ marginBottom: 2 }} />}
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField autoFocus margin="dense" id="name" label="Scenario name" type="text" fullWidth {...field} />
              )}
            />
            <Controller
              control={control}
              name="color"
              render={({ field }) => (
                <Stack direction="column" gap={1} marginTop={2}>
                  <ColorPicker value={field.value} onChange={field.onChange} valueLabel="Scenario color" />
                </Stack>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button type="reset">{'Cancel'}</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={createScenarioStatus.isLoading || updateScenarioStatus.isLoading}
            >
              {scenario ? 'Update' : 'Add'}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export const EditScenarioDialog = React.memo(EditScenarioDialogComponent);
