import { useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers-pro';
import { Layout } from '../../../components/Layout';
import { YearMonthlyView } from '../../../components/YearMonthlyView';
import { useTaskScheduling } from '../../../hooks/useTaskScheduling';
import { useReadWorkingDayListQuery, useUpdateWorkingDayMutation } from '../../../services/workingDayApi';
import { formatISODate } from '../../../utils/date';

const title = 'Manage Working Days';
const breadcrumbs = [{ title }];

const today = new Date();

export function WorkingDayList(): JSX.Element {
  const [year, setYear] = useState(today.getFullYear());
  const workingDay = useReadWorkingDayListQuery([year, year]);
  const [update, updateStatus] = useUpdateWorkingDayMutation();
  const { isWorkingDay } = useTaskScheduling(year, year);

  function CustomDayPicker(props: PickersDayProps<Date>) {
    // checking against `false` to avoid flashing while it's undefined
    return <PickersDay {...props} day={props.day} selected={isWorkingDay(props.day)} />;
  }

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      error={workingDay.error ?? updateStatus.error}
      maxWidth={false}
      sx={{ padding: 2, position: 'relative' }}
    >
      <LinearProgress
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          opacity: updateStatus.isLoading || workingDay.isFetching ? 1 : 0,
          transition: (theme) => theme.transitions.create('opacity', { duration: theme.transitions.duration.shorter }),
        }}
      />
      <Paper
        sx={{
          padding: 2,
          '.MuiCalendarPicker-root': {
            border: 1,
            borderColor: 'divider',
          },
          '.MuiPickersDay-root.Mui-selected': {
            color: 'primary.contrastText',
            bgcolor: 'primary.dark',

            '&:hover, &:focus': {
              bgcolor: 'primary.main',
            },
          },
          '.MuiPickersArrowSwitcher-root': {
            display: 'none',
          },
        }}
      >
        <YearMonthlyView
          year={year}
          onYearChange={setYear}
          renderMonth={({ month, index }) => (
            <Paper variant="outlined">
              <DateCalendar
                key={`${year}-${index}`}
                value={month.start}
                loading={workingDay.isLoading}
                views={['day']}
                disableHighlightToday
                reduceAnimations
                slots={{
                  day: CustomDayPicker,
                }}
                onChange={(value) => {
                  if (value && workingDay.data) {
                    const date = formatISODate(value);
                    update({ date, isWorkingDay: !isWorkingDay(value) });
                  }
                }}
                disabled={updateStatus.isLoading || workingDay.isFetching}
              />
            </Paper>
          )}
        />
      </Paper>
    </Layout>
  );
}
