import { useMemo } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ChevronLeftIcon, ChevronRightIcon } from './Icons';

const minYear = 2020;
const maxYear = new Date().getFullYear() + 4;

type Month = {
  start: Date;
  end: Date;
};

type RenderMonthProps = {
  month: Month;
  index: number;
  months: Month[];
};

type YearMonthlyViewProps = BoxProps & {
  year: number;
  onYearChange: (year: number) => void;
  renderMonth: (props: RenderMonthProps) => JSX.Element;
};

export function YearMonthlyView(props: YearMonthlyViewProps): JSX.Element {
  const { year, onYearChange, renderMonth, ...boxProps } = props;

  const months = useMemo<Month[]>(
    () =>
      [...new Array(12)].map((_, index) => ({
        start: startOfMonth(new Date(year, index, 1)),
        end: endOfMonth(new Date(year, index, 1)),
      })),
    [year],
  );

  return (
    <Box {...boxProps}>
      <Box sx={{ marginBottom: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IconButton disabled={year === minYear} onClick={() => onYearChange(year - 1)}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h4" component="h2" paddingX={1}>
          {year}
        </Typography>
        <IconButton disabled={year === maxYear} onClick={() => onYearChange(year + 1)}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'grid',
          justifyContent: 'center',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fit, 320px)',
        }}
      >
        {months.map((month, index, months) => renderMonth({ month, index, months }))}
      </Box>
    </Box>
  );
}
