import { useNavigate, useMatch, Outlet } from 'react-router-dom';
import { GridButtonGroup, GridButton } from '../../components/GridButtonGroup';
import { OverviewHeader } from './layout/OverviewHeader';
import { OverviewLayout } from './layout/OverviewLayout';
import { useOverviewQueryParams } from './overviewQueryParams';

export function SubprocessPage(props: { rootRoute: string }) {
  const navigate = useNavigate();
  const byUser = useMatch(`overview/${props.rootRoute}/by-user`) !== null;
  const { searchParams } = useOverviewQueryParams();

  return (
    <OverviewLayout>
      <OverviewHeader>
        <GridButtonGroup sx={{ width: 320 }} wrapAfter={2}>
          <GridButton key="tasks" onClick={() => navigate(`./tasks?${searchParams}`)} checked={!byUser}>
            {'Tasks'}
          </GridButton>
          <GridButton key="by-user" onClick={() => navigate(`./by-user?${searchParams}`)} checked={byUser}>
            {'By user'}
          </GridButton>
        </GridButtonGroup>
      </OverviewHeader>
      <Outlet />
    </OverviewLayout>
  );
}
