import React, { useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { GridButton, GridButtonGroup } from '../../../../../components/GridButtonGroup';
import { TaskSchedule } from '../../../../../entities/Task';
import { WeeklyTab } from './tabs/WeekDayTab';
import { WorkDayTab } from './tabs/WorkDayTab';
import { YearlyTab } from './tabs/YearlyTab';

enum RecurrenceMode {
  YEARLY = 0,
  WORK_DAY = 1,
  WEELKY = 2,
}

interface TaskScheduleProps {
  initialValues: DefaultValues<TaskSchedule> | null;
  onSubmit: (recurrence: TaskSchedule) => void;
  onClose: () => void;
  dialogActions: React.ReactNode;
}

export function ScheduleFormComponent(props: TaskScheduleProps) {
  const [mode, setMode] = useState(
    props.initialValues
      ? props.initialValues.day && props.initialValues.day > 0
        ? 0
        : props.initialValues.workDay && props.initialValues.workDay !== 0
          ? 1
          : 2
      : 0,
  );
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleSubmit = (schedule: Partial<TaskSchedule>) => {
    props.onSubmit({
      day: 0,
      month: 0,
      occurrence: 0,
      shift: 0,
      weekDay: 0,
      workDay: 0,
      ...schedule,
    });
  };

  return (
    <>
      <DialogContent sx={{ paddingBottom: 1, flex: 0 }}>
        <Typography fontWeight={500} gutterBottom>
          {'Schedule mode'}
        </Typography>
        <GridButtonGroup sx={{ width: '100%' }} wrapAfter={3}>
          <GridButton onClick={() => setMode(RecurrenceMode.YEARLY)} checked={mode === RecurrenceMode.YEARLY}>
            {'Yearly schedule'}
          </GridButton>
          <GridButton onClick={() => setMode(RecurrenceMode.WORK_DAY)} checked={mode === RecurrenceMode.WORK_DAY}>
            {'Workday schedule'}
          </GridButton>
          <GridButton onClick={() => setMode(RecurrenceMode.WEELKY)} checked={mode === RecurrenceMode.WEELKY}>
            {'Weekday schedule'}
          </GridButton>
        </GridButtonGroup>
      </DialogContent>
      {/* <Tabs
        value={mode}
        onChange={(_, value) => {
          setMode(value);
        }}
        variant="fullWidth"
        sx={{
          marginBottom: '-2px',
          paddingX: '2px',
        }}
      >
        <Tab label="Yearly schedule" />
        <Tab label="Workday schedule" />
        <Tab label="Weekday schedule" />
      </Tabs> */}
      {mode === RecurrenceMode.YEARLY ? (
        <YearlyTab
          {...props}
          onSubmit={handleSubmit}
          previewOpen={previewOpen}
          onPreviewOpenChange={setPreviewOpen}
          dialogActions={props.dialogActions}
        />
      ) : null}
      {mode === RecurrenceMode.WORK_DAY ? (
        <WorkDayTab
          {...props}
          onSubmit={handleSubmit}
          previewOpen={previewOpen}
          onPreviewOpenChange={setPreviewOpen}
          dialogActions={props.dialogActions}
        />
      ) : null}
      {mode === RecurrenceMode.WEELKY ? (
        <WeeklyTab
          {...props}
          onSubmit={handleSubmit}
          previewOpen={previewOpen}
          onPreviewOpenChange={setPreviewOpen}
          dialogActions={props.dialogActions}
        />
      ) : null}
    </>
  );
}

// export const TaskRecurrence = React.memo(TaskRecurrenceComponent);
