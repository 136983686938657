import React from 'react';
import { ErrorAlert, ErrorAlertProps } from '@top-solution/microtecnica-mui';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

interface OverviewInnerLayoutProps {
  inProgress?: boolean;
  error?: ErrorAlertProps['error'];
  children?: React.ReactNode | React.ReactNode[];
}

function OverviewInnerLayoutComponent(props: OverviewInnerLayoutProps) {
  const { children, inProgress, error } = props;
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      width="100%"
      height="calc(100vh - 142px)"
      gap={2}
    >
      {error ? <ErrorAlert error={error} /> : null}
      {inProgress ? (
        <Stack direction="row" justifyContent="center" alignItems="center" flex={1}>
          <CircularProgress />
        </Stack>
      ) : (
        children
      )}
    </Stack>
  );
}

export const OverviewInnerLayout = React.memo(OverviewInnerLayoutComponent);
