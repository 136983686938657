import { useMemo } from 'react';
import { Process } from '../entities/Process';
import { useReadProcessListQuery } from '../services/processApi';

type UseProcessList = ReturnType<typeof useReadProcessListQuery> & {
  map?: Map<Process['id'], Process>;
  data?: Process[];
};

export function useProcessList(planId?: string): UseProcessList {
  const processList = useReadProcessListQuery(planId);

  const map = useMemo(
    () => processList.data?.reduce((map, process) => map.set(process.id, process), new Map<Process['id'], Process>()),
    [processList.data],
  );

  return { ...processList, map };
}
