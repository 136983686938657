import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

export type WorkingDaysMap = Record<number, Record<number, Set<number>>>;
export type DaysInMonthMap = Record<number, Record<number, number>>;
export type IsWorkingDay = (date: Date) => boolean | undefined;

const WorkingDaysContext = React.createContext({
  workingDays: {} as WorkingDaysMap,
  daysInMonth: {} as DaysInMonthMap,
  updateWorkingDays: (() => {
    // noop
  }) as (workingDays: WorkingDay[], yearFrom: number, yearTo: number) => void,
  isWorkingDay: (() => {
    return undefined;
  }) as IsWorkingDay,
});

import { getDaysInMonth } from 'date-fns';
import { WorkingDay } from '../entities/WorkingDay';
import { useReadWorkingDayListQuery } from '../services/workingDayApi';

interface useWorkingDaysProviderProps {
  children: React.ReactNode;
}

/**
 * This contexts works a big cache for the working days and days-in-month count,
 * which turned out to be pretty expensive to compute in hooks (and consequently
 * on each page change).
 * The performance tab of the dev tools says this improves the related pages
 * rendering by roughly a 10x factor.
 */
function WorkingDaysProviderComponent(props: useWorkingDaysProviderProps) {
  const { children } = props;

  const [workingDays, setWorkingDays] = useState<WorkingDaysMap>({});
  const [daysInMonth, setDaysInMonth] = useState<DaysInMonthMap>({});

  const updateWorkingDays = useCallback((workingDaysQueryResults: WorkingDay[], yearFrom: number, yearTo: number) => {
    setWorkingDays((oldWorkinDays) => {
      const newWorkingDays: WorkingDaysMap = {};

      for (const [key] of Object.entries(oldWorkinDays)) {
        const year = Number(key);
        if (year < yearFrom || year > yearTo) {
          newWorkingDays[year] = oldWorkinDays[year];
        }
      }

      for (let i = 0; i < workingDaysQueryResults.length; i++) {
        if (workingDaysQueryResults[i].isWorkingDay) {
          const date = workingDaysQueryResults[i].date;
          const year = Number(date.substring(0, 4));
          const month = Number(date.substring(5, 7)) - 1;
          const day = Number(date.substring(8, 10));

          if (!newWorkingDays[year]) {
            newWorkingDays[year] = {};
          }

          if (!newWorkingDays[year][month]) {
            newWorkingDays[year][month] = new Set<number>();
          }

          newWorkingDays[year][month].add(day);
        }
      }

      return newWorkingDays;
    });

    setDaysInMonth((oldDaysInMonth) => {
      const newDaysInMonth = { ...oldDaysInMonth };
      for (let i = yearFrom; i <= yearTo; i++) {
        if (!newDaysInMonth[i]) {
          newDaysInMonth[i] = {};

          for (let m = 0; m < 12; m++) {
            newDaysInMonth[i][m] = getDaysInMonth(new Date(i, m, 1));
          }
        }
      }
      return newDaysInMonth;
    });
  }, []);

  const isWorkingDay: IsWorkingDay = useCallback(
    (date: Date) => {
      return workingDays[date.getFullYear()]?.[date.getMonth()]?.has(date.getDate());
    },
    [workingDays],
  );

  return (
    <WorkingDaysContext.Provider value={{ workingDays, updateWorkingDays, isWorkingDay, daysInMonth }}>
      {children}
    </WorkingDaysContext.Provider>
  );
}

export const WorkingDaysProvider = React.memo(WorkingDaysProviderComponent);

export function useWorkingDays([from, to]: [number, number]) {
  const { workingDays, updateWorkingDays, isWorkingDay, daysInMonth } = useContext(WorkingDaysContext);
  const workingDaysListApi = useReadWorkingDayListQuery([from, to]);

  useEffect(() => {
    if (workingDaysListApi.data && workingDaysListApi.originalArgs) {
      updateWorkingDays(
        workingDaysListApi.data,
        workingDaysListApi.originalArgs[0],
        workingDaysListApi.originalArgs[1],
      );
    }
  }, [
    updateWorkingDays,
    workingDaysListApi.data,
    workingDaysListApi.originalArgs,
    workingDaysListApi.fulfilledTimeStamp,
  ]);

  return useMemo(() => {
    const ret = {
      workingDays,
      isWorkingDay,
      daysInMonth,
    };

    if (localStorage.getItem('enableDebugLogging') === 'true') {
      // eslint-disable-next-line no-console
      console.debug('useWorkingDays', ret);
    }

    return ret;
  }, [daysInMonth, isWorkingDay, workingDays]);
}
