import React from 'react';
import { Scenario } from '../../../entities/Scenario';
import { GanttTask } from '../../../entities/Task';
import { useGanttTasksStats } from '../../../hooks/useGanttTasksStats';
import { useScenarioNames } from '../../../hooks/useScenarioNames';
import { TaskStatsCard, TaskStatsCardProps } from '../components/TasksStatsCard';
import { CardsGrid } from '../layout/CardsGrid';
import { OverviewInnerLayout } from '../layout/OverviewInnerLayout';
import { useOverviewGanttTasks } from '../OverviewGanttTasks';
import { useOverviewQueryParams } from '../overviewQueryParams';

interface ScenarioTaskStatsCardProps extends Omit<TaskStatsCardProps, 'title' | 'stats'> {
  scenarioName: Scenario['name'];
  ganttTasks: GanttTask[];
}

function ScenarioTaskStatsCard(props: ScenarioTaskStatsCardProps) {
  const { scenarioName, ganttTasks, ...taskStatsCardProps } = props;
  const { showEmptyCards, excludeFutureTasks } = useOverviewQueryParams();

  const ganttTasksStats = useGanttTasksStats(
    ganttTasks,
    {
      scenarioName: scenarioName,
    },
    { excludeFutureTasks, ignoreOtherScenario: true },
  );

  if (!showEmptyCards) {
    if (ganttTasksStats.finance.total === 0 && ganttTasksStats.supplier.total === 0) {
      return null;
    }
  }

  return <TaskStatsCard title={scenarioName} stats={ganttTasksStats} {...taskStatsCardProps} />;
}

function ScenariosGridComponent() {
  const { searchParams } = useOverviewQueryParams();

  const { scenarioNames } = useScenarioNames();

  const { ganttTasks, error: ganttTasksError, isLoading: ganttTasksIsLoading } = useOverviewGanttTasks();

  return (
    <OverviewInnerLayout error={ganttTasksError}>
      <CardsGrid>
        {(ganttTasksIsLoading ? [] : scenarioNames).map((scenarioName) => (
          <ScenarioTaskStatsCard
            key={scenarioName}
            scenarioName={scenarioName}
            ganttTasks={ganttTasks}
            detailPath={`${encodeURIComponent(scenarioName)}/processes?${searchParams}`}
            taskDetailsPath={`${encodeURIComponent(scenarioName)}/tasks`}
          />
        ))}
      </CardsGrid>
    </OverviewInnerLayout>
  );
}

export const ScenariosGrid = React.memo(ScenariosGridComponent);
