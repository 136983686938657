import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface PageTitleProps {
  title: string | React.ReactNode | undefined;
  subtitle?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const PAGE_TITLE_OFFSET_HEIGHT = 202;

function PageTitleComponent(props: PageTitleProps) {
  const { title, subtitle, children } = props;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      marginBottom={1.5}
      marginTop={0.5}
      width={'100%'}
      flexWrap="wrap"
      minHeight={40}
      gap={4}
    >
      {title ? (
        <Typography variant="h5" component="h1" sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {title}
          {subtitle ? (
            <Typography component="span" fontSize="0.6em" gutterBottom sx={{ opacity: '50%', marginLeft: '1ch' }}>
              {subtitle}
            </Typography>
          ) : null}
        </Typography>
      ) : (
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      )}
      {children}
    </Stack>
  );
}

export const PageTitle = React.memo(PageTitleComponent);
