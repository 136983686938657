import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isBefore, sub } from 'date-fns';
import { GanttTask, TaskStatus } from '../entities/Task';
import { useGanttTasks } from '../hooks/useGanttTasks';
import { useCreateNotificationsMutation } from '../services/notificationApi';

function MailSenderComponent() {
  const [params] = useState({ from: sub(new Date(), { months: 2 }), to: new Date() });
  const { ganttTasks, fulfilledTimeStamp: ganttTasksFulfilledTimeStamp } = useGanttTasks(params);
  const interval = useRef<number | undefined>(undefined);
  const lateTasksRef = useRef<GanttTask[] | undefined>(undefined);

  const [createNotifications] = useCreateNotificationsMutation();

  const lateTasks = useMemo(() => {
    if (!ganttTasksFulfilledTimeStamp) {
      return undefined;
    }

    const notificationsTimestamp = ganttTasksFulfilledTimeStamp;
    return ganttTasks.filter(
      (gt) =>
        (gt.status === TaskStatus.Open || gt.status === TaskStatus.Overdue) &&
        isBefore(new Date(gt.date), new Date(notificationsTimestamp)),
    );
  }, [ganttTasks, ganttTasksFulfilledTimeStamp]);

  useEffect(() => {
    lateTasksRef.current = lateTasks;
  }, [lateTasks]);

  useEffect(() => {
    async function sendNotifications() {
      // eslint-disable-next-line no-console
      console.log('✉️ Sending notifications');
      try {
        await createNotifications({ tasks: lateTasksRef.current ?? [] }).unwrap();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('✉️ Error sending notifications');
      }
    }

    if (ganttTasksFulfilledTimeStamp) {
      if (interval.current === undefined) {
        interval.current = setInterval(() => sendNotifications(), 10 * 60 * 1000);
        sendNotifications();
      }

      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = undefined;
        }
      };
    }
  }, [createNotifications, ganttTasksFulfilledTimeStamp]);

  return <div />;
}

const MemoizedMailSenderComponent = React.memo(MailSenderComponent);

function MountAfterStartup() {
  const [startupLock, setStartupLock] = useState(true);

  useEffect(() => {
    const t = setTimeout(() => {
      setStartupLock(false);
    }, 30000 * 1000);

    return () => {
      clearTimeout(t);
    };
  }, []);

  if (startupLock) {
    return null;
  }
  return <MemoizedMailSenderComponent />;
}

export const MailSender = React.memo(MountAfterStartup);
