import { useEffect, useMemo } from 'react';
import { Controller, UseFormWatch, UseFormSetValue, Control } from 'react-hook-form';
import { IntegerTextField } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AreaAutocomplete } from '../../../../../components/Form/AreaAutocomplete';
import { CategoryAutocomplete } from '../../../../../components/Form/CategoryAutocomplete';
import { DepartmentAutocomplete } from '../../../../../components/Form/DepartmentAutocomplete';
import { ProcessAutocomplete } from '../../../../../components/Form/ProcessAutocomplete';
import { ScenarioAutocomplete } from '../../../../../components/Form/ScenarioAutocomplete';
import { SubprocessAutocomplete } from '../../../../../components/Form/SubprocessAutocomplete';
import { UserAutocomplete } from '../../../../../components/Form/UserAutocomplete';
import { GridButton, GridButtonGroup } from '../../../../../components/GridButtonGroup';
import { EditingTask, TaskConfigurationForm } from '../../../../../entities/Task';
import { UserRoleName } from '../../../../../entities/User';

type TaskConfigurationFormContentProps = {
  task?: EditingTask;
  //
  watch: UseFormWatch<TaskConfigurationForm>;
  setValue: UseFormSetValue<TaskConfigurationForm>;
  control: Control<TaskConfigurationForm>;
};

export function TaskConfigurationFormContent(props: TaskConfigurationFormContentProps): JSX.Element {
  const { task, setValue, watch, control } = props;
  const { isAdmin, hasRole, username } = useAuth();

  const process = watch('process');
  const ownerUsername = watch('ownerUsername');
  const reviewerUsername = watch('reviewerUsername');

  const ownerChanged = useMemo(() => {
    return task?.owner?.username === username && ownerUsername !== username;
  }, [ownerUsername, task?.owner?.username, username]);
  const reviewerChanged = useMemo(() => {
    return task?.reviewer?.username === username && reviewerUsername !== username;
  }, [reviewerUsername, task?.reviewer?.username, username]);

  useEffect(() => {
    if (!isAdmin) {
      if (!ownerChanged && !reviewerChanged) {
        setValue('confirmUserChange', true);
      } else {
        setValue('confirmUserChange', false as unknown as true);
      }
    }
  }, [isAdmin, ownerChanged, reviewerChanged, setValue]);

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={1} paddingTop={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <TextField
                label="Description"
                error={Boolean(error)}
                helperText={error?.message || ' '}
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="process"
            render={({ field, fieldState: { error } }) => (
              <ProcessAutocomplete
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
                {...field}
                disabled={false}
                processManager={hasRole(UserRoleName.MANAGER) && username ? username : undefined}
                onChange={(_, newProcess) => {
                  if (!newProcess || (process && process.id !== newProcess.id)) {
                    setValue('scenario', null as unknown as TaskConfigurationForm['scenario']);
                    setValue('category', null as unknown as TaskConfigurationForm['category']);
                  }
                  field.onChange(newProcess);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="subprocess"
            render={({ field, fieldState: { error } }) => (
              <SubprocessAutocomplete
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                fullWidth
                {...field}
                onChange={(_, subprocess) => field.onChange(subprocess)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="area"
            render={({ field, fieldState: { error } }) => (
              <AreaAutocomplete
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                fullWidth
                {...field}
                onChange={(_, area) => field.onChange(area)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="department"
            render={({ field, fieldState: { error } }) => (
              <DepartmentAutocomplete
                error={Boolean(error)}
                helperText={error?.message ?? ' '}
                fullWidth
                {...field}
                onChange={(_, department) => field.onChange(department)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="scenario"
            render={({ field, fieldState: { error } }) => (
              <ScenarioAutocomplete
                error={Boolean(error)}
                helperText={error?.message}
                process={process}
                disabled={!process}
                sx={{ minWidth: '20ch' }}
                fullWidth
                {...field}
                onChange={(_, scenario) => field.onChange(scenario)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="category"
            render={({ field, fieldState: { error } }) => (
              <CategoryAutocomplete
                error={Boolean(error)}
                helperText={error?.message}
                process={process}
                disabled={!process}
                sx={{ minWidth: '20ch' }}
                fullWidth
                {...field}
                onChange={(_, category) => field.onChange(category)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="documentUrl"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                label="Document link"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={500} gutterBottom marginTop={1}>
            {'Task type'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="isDeliverable"
            render={({ field }) => (
              <GridButtonGroup sx={{ width: '100%' }} wrapAfter={2}>
                <GridButton onClick={() => field.onChange(false)} checked={field.value === false}>
                  {'Normal'}
                </GridButton>
                <GridButton onClick={() => field.onChange(true)} checked={field.value === true}>
                  {'Deliverable'}
                </GridButton>
              </GridButtonGroup>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={500} gutterBottom marginTop={2}>
            {'Assignees'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="ownerUsername"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Owner"
                fullWidth
                error={Boolean(error)}
                onChange={(_, value) => onChange(value as string)}
                helperText={error?.message || ' '}
                disabled={!isAdmin}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="backupUsername"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Backup"
                fullWidth
                error={Boolean(error)}
                onChange={(_, value) => onChange(value as string)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Controller
            control={control}
            name="reviewerUsername"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <UserAutocomplete
                label="Reviewer"
                fullWidth
                error={Boolean(error)}
                onChange={(_, value) => onChange(value as string)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {!isAdmin && (ownerChanged || reviewerChanged) ? (
            <Controller
              control={control}
              name="confirmUserChange"
              render={({ field: { value, ...field }, fieldState: { error } }) => (
                <Alert
                  severity="warning"
                  sx={{
                    alignItems: 'center',
                    marginTop: 1,
                    border: error ? '1px solid' : 'none',
                    borderColor: 'error.main',
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox {...field} checked={value} size="small" />}
                    label={
                      <span>
                        {`I understand that changing the ${
                          ownerChanged && reviewerChanged ? 'assignees' : ownerChanged ? 'Owner' : 'Reviewer'
                        } may result in loss of access to the current Task.`}
                      </span>
                    }
                    sx={{ marginLeft: 0.5, '.MuiTypography-root': { marginLeft: 1 } }}
                  />
                </Alert>
              )}
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={500} gutterBottom marginTop={1}>
            {'Journal'}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Controller
            control={control}
            name="entryNumber"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <IntegerTextField
                label="Journal entry"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
                value={field.value || null}
                onChange={(_, value) => field.onChange(value ?? undefined)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Controller
            control={control}
            name="entryType"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                label="Entry type"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Controller
            control={control}
            name="reversal"
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                label="Reversal"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ' '}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
