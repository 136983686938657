import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Breadcrumb, DeleteConfirmDialog, ErrorAlert } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DefinitionList } from '../../../components/DefinitionList';
import { UserAutocomplete } from '../../../components/Form/UserAutocomplete';
import { DeleteIcon, EditIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { LinkButton } from '../../../components/LinkButton';
import { PageTitle } from '../../../components/PageTitle';
import { Category } from '../../../entities/Category';
import { Process, ProcessForm, ProcessFormSchema } from '../../../entities/Process';
import { Scenario } from '../../../entities/Scenario';
import { UserRoleName } from '../../../entities/User';
import { useDeleteCategoryMutation } from '../../../services/categoryApi';
import {
  useCreateProcessMutation,
  useReadProcessListQuery,
  useRemoveProcessMutation,
  useUpdateProcessMutation,
} from '../../../services/processApi';
import { useDeleteScenarioMutation } from '../../../services/scenarioApi';
import { printUserName } from '../../../utils/users';
import { EditCategoryDialog } from './EditCategoryDialog';
import { EditScenarioDialog } from './EditScenarioDialog';

const emptyProcess: Process = {
  id: -1,
  name: '',
  shortName: '',
  managerList: [],
  categoryList: [],
  scenarioList: [],
};

const processesPath = '/admin/processes';

export function ProcessEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isAdmin, username } = useAuth();

  const processList = useReadProcessListQuery();
  const [remove, removeStatus] = useRemoveProcessMutation();
  const process = useMemo(
    () => (id === 'new' ? emptyProcess : (processList.data ?? []).find((process) => process.id === Number(id))),
    [id, processList.data],
  );

  const form = useForm<ProcessForm>({
    defaultValues: {},
    resolver: zodResolver(ProcessFormSchema),
  });
  const { handleSubmit, control } = form;

  const pageTitle = id === 'new' ? 'Create new process' : `Manage process "${process?.name}"`;
  const breadcrumbs: Breadcrumb[] = [{ title: 'Manage processes', url: processesPath }, { title: process?.name }];

  const [editingProcessData, setEditingProcessData] = useState(id === 'new');
  const [categoryToEdit, setCategoryToEdit] = useState<Omit<Category, 'processId'>>();
  const [scenarioToEdit, setScenarioToEdit] = useState<Omit<Scenario, 'processId'>>();

  const [create, createStatus] = useCreateProcessMutation();
  const [update, updateStatus] = useUpdateProcessMutation();

  const [deleteScenario, deleteScenarioStatus] = useDeleteScenarioMutation();
  const [deleteCategory, deleteCategoryStatus] = useDeleteCategoryMutation();

  const [deletingProcess, setDeletingProcess] = useState<boolean>(false);
  const [scenarioToDelete, setScenarioToDelete] = useState<Scenario | undefined>();
  const [categoryToDelete, setCategoryToDelete] = useState<Category | undefined>();
  const [deletingCategory, setDeletingCategory] = useState<boolean>(false);
  const [deletingScenario, setDeletingScenario] = useState<boolean>(false);

  // const isLoading = createStatus.isLoading ?? updateStatus.isLoading;
  const error = createStatus.error ?? updateStatus.error ?? deleteScenarioStatus.error ?? deleteCategoryStatus.error;

  const userCanEditProcess = isAdmin || (username && process?.managerList.find((m) => m.username === username));

  const onSubmit = async (data: ProcessForm) => {
    if (id === 'new') {
      await create(data).unwrap();
      navigate(`/admin/processes/`);
    } else if (!isNaN(Number(id))) {
      await update({ ...data, id: Number(id) }).unwrap();
      setEditingProcessData(false);
    }
  };

  useEffect(() => {
    if (process) {
      form.setValue('name', process.name);
      form.setValue('shortName', process.shortName);
      form.setValue(
        'managerList',
        process.managerList.map((manager) => manager.username),
      );
    }
  }, [process, form]);

  async function handleDeleteConfirm() {
    if (process) {
      await remove(process).unwrap();
      navigate(processesPath);
    }
  }

  async function handleDeleteScenarioConfirm() {
    if (scenarioToDelete && process) {
      await deleteScenario({ scenarioId: scenarioToDelete.id, processId: process.id }).unwrap();
    }
    setDeletingScenario(false);
  }

  async function handleDeleteCategoryConfirm() {
    if (categoryToDelete && process) {
      await deleteCategory({ categoryId: categoryToDelete.id, processId: process.id }).unwrap();
    }
    setDeletingCategory(false);
  }

  return (
    <Layout
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      maxWidth={false}
      sx={{ '.MuiDataGrid-cell .MuiChip-root': { marginRight: 0.125 } }}
    >
      <PageTitle title={pageTitle}>
        <Stack direction="row" alignItems="center" gap={2}>
          {id === 'new' || !process ? null : (
            <LinkButton to={`/overview/processes/${process.id}/subprocesses`} sx={{ margin: 0 }}>
              {'Overview'}
            </LinkButton>
          )}
        </Stack>
      </PageTitle>
      {error && <ErrorAlert error={error} sx={{ marginBottom: 2 }} />}
      <FormProvider {...form}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'minmax(400px, 1fr) minmax(200px, 450px) minmax(200px, 450px)',
            gap: 2,
          }}
        >
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height={32}
              gap={1}
              marginBottom={1}
            >
              <Typography variant="body1" fontWeight="bold" gutterBottom sx={{ flex: 1 }}>
                {'Process info'}
              </Typography>
              {id === 'new' || !userCanEditProcess ? null : (
                <Button variant="text" color="error" size="small" onClick={() => setDeletingProcess(true)}>
                  {'Delete process'}
                </Button>
              )}
              {userCanEditProcess && !editingProcessData ? (
                <Button variant="outlined" color="primary" size="small" onClick={() => setEditingProcessData(true)}>
                  {'Edit info'}
                </Button>
              ) : null}
            </Stack>
            <Card variant="outlined" sx={{ padding: 2 }}>
              {editingProcessData ? (
                <Grid container spacing={2} paddingTop={1}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          label="Name"
                          error={Boolean(error)}
                          helperText={error?.message || ' '}
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="shortName"
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          label="Short name"
                          error={Boolean(error)}
                          helperText={error?.message || ' '}
                          fullWidth
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="managerList"
                      render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
                        <UserAutocomplete
                          label="Managers"
                          filterByRoles={[UserRoleName.MANAGER, UserRoleName.ADMIN]}
                          multiple
                          fullWidth
                          error={Boolean(error)}
                          helperText={error?.message || ' '}
                          {...field}
                          value={value}
                          onChange={(e, value) => onChange(value ?? [])}
                        />
                      )}
                    />
                  </Grid>
                  {editingProcessData ? (
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          color="inherit"
                          onClick={() => {
                            if (id === 'new') {
                              navigate(processesPath);
                            } else {
                              setEditingProcessData(false);
                            }
                          }}
                        >
                          {'Cancel'}
                        </Button>
                        <LoadingButton type="submit" color="primary" variant="contained">
                          {id === 'new' ? 'Create' : 'Save'}
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                <DefinitionList sx={{ gridTemplateColumns: '100px 1fr' }}>
                  <Typography component="dt">{'Name'}</Typography>
                  <Typography component="dd">{process?.name ?? ''}</Typography>
                  <Typography component="dt">{'Short name'}</Typography>
                  <Typography component="dd">{process?.shortName ?? ''}</Typography>
                  <Typography component="dt">{'Managers'}</Typography>
                  <Typography component="dd">
                    {process?.managerList.map((manager) => printUserName(manager)).join(', ') ?? ''}
                  </Typography>
                </DefinitionList>
              )}
            </Card>
          </Box>
          {process && id !== 'new' ? (
            <>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" height={32} marginBottom={1}>
                  <Typography variant="body1" fontWeight="bold" gutterBottom>
                    {'Scenarios'}
                  </Typography>
                  {userCanEditProcess ? (
                    <EditScenarioDialog
                      scenario={scenarioToEdit}
                      onClose={() => setScenarioToEdit(undefined)}
                      processId={Number(id)}
                      disabled={editingProcessData}
                    />
                  ) : null}
                </Stack>
                <Paper variant="outlined">
                  <List dense>
                    {process.scenarioList.map((scenario) => (
                      <ListItem
                        key={scenario.id}
                        secondaryAction={
                          userCanEditProcess ? (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => setScenarioToEdit(scenario)}
                                disabled={editingProcessData}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setScenarioToDelete(scenario);
                                  setDeletingScenario(true);
                                }}
                                disabled={editingProcessData}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : null
                        }
                      >
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <Box
                            sx={{
                              width: 16,
                              height: 16,
                              borderRadius: '50%',
                              backgroundColor: `#${scenario.color}`,
                              content: '""',
                              border: '1px solid',
                              borderColor: (theme) => alpha(theme.palette.grey[500], 0.4),
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={scenario.name} />
                      </ListItem>
                    ))}
                    {process.scenarioList.length === 0 ? (
                      <ListItem>
                        <ListItemText
                          primary={'No scenario associated yet'}
                          sx={{ '.MuiTypography-root': { typography: 'caption' } }}
                        />
                      </ListItem>
                    ) : null}
                  </List>
                </Paper>
                {/* <Controller
                control={control}
                name="shortName"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Short name"
                    error={Boolean(error)}
                    helperText={error?.message || ' '}
                    fullWidth
                    {...field}
                  />
                )}
              /> */}
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" height={32} marginBottom={1}>
                  <Typography variant="body1" fontWeight="bold" gutterBottom>
                    {'Categories'}
                  </Typography>
                  {userCanEditProcess ? (
                    <EditCategoryDialog
                      category={categoryToEdit}
                      onClose={() => setCategoryToEdit(undefined)}
                      disabled={editingProcessData}
                      processId={Number(id)}
                    />
                  ) : null}
                </Stack>
                <Paper variant="outlined">
                  <List dense>
                    {process?.categoryList.map((category) => (
                      <ListItem
                        key={category.id}
                        secondaryAction={
                          userCanEditProcess ? (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => setCategoryToEdit(category)}
                                disabled={editingProcessData}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setCategoryToDelete(category);
                                  setDeletingCategory(true);
                                }}
                                disabled={editingProcessData}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : null
                        }
                      >
                        <ListItemText primary={category.name} />
                      </ListItem>
                    ))}
                    {process.categoryList.length === 0 ? (
                      <ListItem>
                        <ListItemText
                          primary={'No category associated yet'}
                          sx={{ '.MuiTypography-root': { typography: 'caption' } }}
                        />
                      </ListItem>
                    ) : null}
                  </List>
                </Paper>
              </Grid>
            </>
          ) : null}
        </Box>
      </FormProvider>
      {process && (
        <DeleteConfirmDialog
          title={`Are you sure to delete process ${process.shortName}?`}
          open={deletingProcess}
          onConfirm={handleDeleteConfirm}
          onClose={() => {
            setDeletingProcess(false);
          }}
          error={removeStatus.error}
          inProgress={removeStatus.isLoading}
        />
      )}
      {scenarioToDelete && (
        <DeleteConfirmDialog
          title={`Are you sure to delete the Scenario ${scenarioToDelete.name}?`}
          open={deletingScenario}
          onConfirm={handleDeleteScenarioConfirm}
          onClose={() => {
            setDeletingScenario(false);
          }}
          error={deleteScenarioStatus.error}
          inProgress={deleteScenarioStatus.isLoading}
        />
      )}
      {categoryToDelete && (
        <DeleteConfirmDialog
          title={`Are you sure to delete the Process ${categoryToDelete.name}?`}
          open={deletingCategory}
          onConfirm={handleDeleteCategoryConfirm}
          onClose={() => {
            setDeletingCategory(false);
          }}
          error={deleteCategoryStatus.error}
          inProgress={deleteCategoryStatus.isLoading}
        />
      )}
    </Layout>
  );
}
