import { z } from 'zod';
import { CategorySchema } from './Category';
import { ScenarioSchema } from './Scenario';
import { UserSchema } from './User';

export const ProcessSchema = z.object({
  id: z.number(),
  name: z.string(),
  shortName: z.string(),
  scenarioList: ScenarioSchema.array(),
  categoryList: CategorySchema.array(),
  managerList: UserSchema.array(),
});

export type Process = z.infer<typeof ProcessSchema>;

export const ScenarioProcessSchema = ProcessSchema.omit({ scenarioList: true, categoryList: true, managerList: true });
export type ScenarioProcess = z.infer<typeof ScenarioProcessSchema>;

export const ProcessFormSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(3),
  shortName: z.string().min(2).max(5),
  managerList: UserSchema.shape.username.array().min(1),
});

export type ProcessForm = z.infer<typeof ProcessFormSchema>;

const ProcessTaskSchema = z.object({
  id: z.number(),
  area: z.string().min(1),
  backup: z.string(),
  processID: z.number(),
  department: z.string().optional(),
  description: z.string().min(1),
  category: z.string().optional(),
  categoryId: z.number().optional(),
  dueMonth: z.number().optional(),
  dueDaysFromMonthEnd: z.number().min(-25).max(25),
  dueHour: z.number().optional(),
  dueHourMachine: z.number().optional(),
  duration: z.number(),
  durationMachine: z.number().optional(),
  entryNumber: z.number().optional(),
  entryType: z.string().optional(),
  frequency: z.string().min(1),
  owner: z.string().min(1),
  reversal: z.string().nullable(),
  reviewer: z.string().min(1),
  scenario: z.string(),
  subprocess: z.string().optional(),
  path: z.string().url().array().optional(),
  schedule: z.object({
    month: z.number(),
    day: z.number(),
    shift: z.number(),
    workDay: z.number(),
    occurrence: z.number(),
    weekDay: z.number(),
  }),
});

const ProcessTaskFormSchema = ProcessTaskSchema.omit({
  category: true,
})
  .extend({
    id: z.number(),
    categoryId: z.number(),
    dueMonth: z.number().nullable(),
    owner: UserSchema,
    backup: UserSchema.nullable(),
    reviewer: UserSchema,
    entryNumber: z.number().nullable(),
    dueHour: z.number().nonnegative().nullable(),
    dueHourMachine: z.number().nonnegative().nullable(),
    duration: z.number().nonnegative().nullable(),
    durationMachine: z.number().nonnegative().nullable(),
    isMachineTask: z.boolean(),
    subprocess: z.string().nullable(),
    department: z.string().nullable(),
    path: z.string().url().array().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.dueHour && !data.dueHourMachine) {
      ctx.addIssue({
        path: ['dueHour'],
        code: z.ZodIssueCode.custom,
        message: `Due Hour or Due Machine must be set`,
      });
      ctx.addIssue({
        path: ['dueHourMachine'],
        code: z.ZodIssueCode.custom,
        message: `Due Hour or Due Machine must be set`,
      });
    }
    if (data.isMachineTask && data.dueHourMachine === null) {
      ctx.addIssue({
        path: ['dueHourMachine'],
        code: z.ZodIssueCode.custom,
        message: `Required`,
      });
    }
    if (data.entryNumber && !data.entryType) {
      ctx.addIssue({
        path: ['entryType'],
        code: z.ZodIssueCode.custom,
        message: `Required if Journal Entry is set`,
      });
    }
  });

export type ProcessTaskForm = z.infer<typeof ProcessTaskFormSchema>;
