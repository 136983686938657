import { Route, Routes } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { UserRoleName } from '../../entities/User';
import { NotFoundPage } from '../Error/NotFoundPage';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';
import { ProcessList } from './Process/ProcessList';
import { TurnbackList } from './Turnback/TurnbackList';
import { UserList } from './User/UserList';
import { WorkingDayList } from './WorkingDay/WorkingDayList';

const AdminAuthGuard = ({ children }: { children: React.ReactNode }) => (
  <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role === UserRoleName.ADMIN}>
    {children}
  </AuthGuard>
);

export function AdminRouter(): JSX.Element {
  return (
    <AuthGuard
      unauthorizedFallback={<UnauthorizedPage />}
      authorizeRole={(role) => role === UserRoleName.ADMIN || role === UserRoleName.MANAGER}
    >
      <Routes>
        <Route
          path="users"
          element={
            <AdminAuthGuard>
              <UserList />
            </AdminAuthGuard>
          }
        />
        <Route
          path="working-days"
          element={
            <AdminAuthGuard>
              <WorkingDayList />
            </AdminAuthGuard>
          }
        />
        <Route path="processes/*" element={<ProcessList />} />
        <Route path="turnbacks/*" element={<TurnbackList />} />
        <Route
          path="*"
          element={
            <AdminAuthGuard>
              <NotFoundPage />
            </AdminAuthGuard>
          }
        />
      </Routes>
    </AuthGuard>
  );
}
