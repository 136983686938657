import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { ExportIcon, ImportIcon } from '../../../../../components/Icons';
import { TaskSchedule } from '../../../../../entities/Task';
import { numberToBinaryString } from './utils';

interface DebugTableProps {
  schedule: TaskSchedule;
  onImport: (schedule: TaskSchedule) => void;
}

function DebugTableComponent(props: DebugTableProps) {
  const {
    schedule: { month, workDay, weekDay, occurrence, day, shift },
    onImport,
  } = props;

  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [importText, setImportText] = useState('');

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small" sx={{ pre: { margin: 0 } }}>
        <TableHead>
          <TableRow>
            <TableCell>MONTH</TableCell>
            <TableCell>DAY</TableCell>
            <TableCell>OCCURRENCE</TableCell>
            <TableCell>WEEKDAY</TableCell>
            <TableCell>WORKDAY</TableCell>
            <TableCell>SHIFT</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              '.MuiTableCell-root': {
                borderBottom: 0,
              },
            }}
          >
            <TableCell>
              <pre>{numberToBinaryString(month, 12)}</pre>
            </TableCell>
            <TableCell>
              <pre>{day ?? 0}</pre>
            </TableCell>
            <TableCell>
              <pre>{numberToBinaryString(occurrence, 16)}</pre>
            </TableCell>
            <TableCell>
              <pre>{numberToBinaryString(weekDay, 7)}</pre>
            </TableCell>
            <TableCell>
              <pre>{workDay ?? 0}</pre>
            </TableCell>
            <TableCell>
              <pre>{shift ?? 0}</pre>
            </TableCell>
            <TableCell>
              <IconButton
                onClick={() => {
                  copy([month, day, occurrence, weekDay, workDay, shift].join(' '));
                }}
              >
                <ExportIcon />
              </IconButton>
              <IconButton onClick={() => setImportDialogOpen(true)}>
                <ImportIcon />
              </IconButton>
              <Dialog open={importDialogOpen} onClose={() => setImportDialogOpen(false)}>
                <DialogTitle>Import schedule</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    label="Import schedule"
                    fullWidth
                    variant="standard"
                    value={importText}
                    onChange={(e) => setImportText(e.target.value)}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setImportDialogOpen(false)}>Cancel</Button>
                  <Button
                    onClick={() => {
                      setImportDialogOpen(false);
                      const [month, day, occurrence, weekDay, workDay, shift] = importText.split(' ');

                      onImport({
                        month: Number(month),
                        day: Number(day),
                        shift: Number(shift),
                        workDay: Number(workDay),
                        weekDay: Number(weekDay),
                        occurrence: Number(occurrence),
                      });

                      setImportText('');
                    }}
                  >
                    Import
                  </Button>
                </DialogActions>
              </Dialog>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const DebugTable = React.memo(DebugTableComponent);
