import { useCallback } from 'react';
import { add, isAfter, isBefore, isFuture } from 'date-fns';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import {
  FromTo,
  PeopleLoadRow,
  PeopleLoadMetric,
  PeopleLoadTimeSample,
  UserPeriodMetrics,
} from '../../entities/PeopleLoad';
import { formatDate } from '../../utils/date';
import { PersonLoadCell } from './PersonLoadCell';
import { StatChip } from './StatChip';

export type FromToGridColDef = GridColDef<PeopleLoadRow, UserPeriodMetrics, number> & FromTo;

export function isFromToGridColDef(v: GridValidRowModel): v is FromToGridColDef {
  if ('from' in v && v.from instanceof Date) {
    if ('to' in v && v.to instanceof Date) {
      return true;
    }
  }
  return false;
}

export function usePeopleLoadColDef(): (
  date: Date,
  metric: PeopleLoadMetric,
  timeSample: PeopleLoadTimeSample,
) => FromToGridColDef {
  return useCallback((date: Date, metric: PeopleLoadMetric, timeSample: PeopleLoadTimeSample) => {
    let headerName = '';
    let toDate = new Date();
    let minWidth = 0;

    switch (timeSample) {
      case PeopleLoadTimeSample.Daily: {
        toDate = add(date, { days: 1 });
        headerName = formatDate(date, 'EEE dd/MM');
        minWidth = 100;
        break;
      }
      case PeopleLoadTimeSample.Weekly: {
        toDate = add(date, { days: 6 });
        headerName = `${formatDate(date, 'dd/MM')} — ${formatDate(toDate, 'dd/MM')}`;
        minWidth = 120;
        break;
      }
      case PeopleLoadTimeSample.Monthly: {
        toDate = add(date, { months: 1, days: -1 });
        headerName = `${formatDate(date, 'MMM')}`;
        minWidth = 80;
        break;
      }
    }

    const colDef: FromToGridColDef = {
      field: `${date.toISOString()}`,
      headerName,
      from: date,
      to: toDate,
      renderHeader: () => {
        return (
          <Stack direction="row" alignItems="center" gap={'1ch'} sx={{ fontWeight: 500 }}>
            {isAfter(new Date(), date) && isBefore(new Date(), toDate) ? (
              <Box
                sx={{
                  height: '0.8em',
                  width: '0.8em',
                  borderRadius: '50%',
                  bgcolor: 'primary.light',
                }}
              />
            ) : null}
            {headerName}
          </Stack>
        );
      },
      minWidth,
      flex: 1,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      valueGetter: (value, row: PeopleLoadRow) => row.periods[date.toISOString()],
      renderCell: ({ row, field, value }) => {
        if (row.id === 'average') {
          const metrics = row.periods[field].metrics;
          if (metrics) {
            const metricValue = metric === PeopleLoadMetric.Load ? metrics.load : metrics.onTime;
            return <StatChip metricValue={metricValue} disabled={metricValue === 0} clickable={false} />;
          }
        }
        if (value) {
          return <PersonLoadCell user={row.user} userPeriodMetrics={value} metric={metric} timeSample={timeSample} />;
        }
      },
      headerClassName: timeSample !== PeopleLoadTimeSample.Daily ? 'ClickableHeader' : undefined,
      cellClassName: metric === PeopleLoadMetric.OnTime && isFuture(date) ? 'DisabledCell' : undefined,
    };
    return colDef;
  }, []);
}
