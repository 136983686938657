import { z } from 'zod';

const PlannerDatagridConfigSchema = z
  .object({
    density: z.string(),
    columnVisibilityModel: z.record(z.boolean()),
  })
  .partial();

export const PlannerConfigSchema = z
  .object({
    pages: z
      .object({
        overview: z
          .object({
            params: z
              .object({
                from: z.string(),
                to: z.string(),
                showTasksCount: z.boolean(),
                showEmptyCards: z.boolean(),
                excludeFutureTasks: z.boolean(),
              })
              .partial(),
            datagrid: PlannerDatagridConfigSchema,
          })
          .partial(),
        gantt: z
          .object({
            params: z
              .object({
                from: z.string(),
                to: z.string(),
                owner: z.boolean(),
                backup: z.boolean(),
                reviewer: z.boolean(),
                zoomLevel: z.number(),
              })
              .partial(),
            datagrid: PlannerDatagridConfigSchema,
          })
          .partial(),
        peopleLoad: z
          .object({
            params: z
              .object({
                timeSample: z.string(),
              })
              .partial(),
          })
          .partial(),
        processes: z
          .object({
            datagrid: PlannerDatagridConfigSchema,
          })
          .partial(),
        tasks: z
          .object({
            datagrid: PlannerDatagridConfigSchema,
          })
          .partial(),
        turnbacks: z
          .object({
            datagrid: PlannerDatagridConfigSchema,
          })
          .partial(),
        users: z
          .object({
            datagrid: PlannerDatagridConfigSchema,
          })
          .partial(),
      })
      .partial(),
  })
  .partial();

export type PlannerDatagridConfig = z.infer<typeof PlannerDatagridConfigSchema>;
export type PlannerConfig = z.infer<typeof PlannerConfigSchema>;
