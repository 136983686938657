import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { TaskStatusChip } from '../components/TaskStatus';
import { GanttTask, TaskStatus, taskStatusLabels } from '../entities/Task';

export function useTaskStatusColDef(): GridColDef<GanttTask, TaskStatus, string> {
  return useMemo<GridColDef<GanttTask, TaskStatus, string>>(
    () => ({
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      width: 100,
      valueOptions: [
        { label: taskStatusLabels[TaskStatus.Open], value: TaskStatus.Open },
        { label: taskStatusLabels[TaskStatus.Late], value: TaskStatus.Late },
        { label: taskStatusLabels[TaskStatus.OnTime], value: TaskStatus.OnTime },
        { label: taskStatusLabels[TaskStatus.NotApplicable], value: TaskStatus.NotApplicable },
        { label: taskStatusLabels[TaskStatus.Overdue], value: TaskStatus.Overdue },
      ],
      valueFormatter: (value) => taskStatusLabels[value],
      renderCell: ({ value }) => (value ? <TaskStatusChip value={value} /> : null),
    }),
    [],
  );
}
