import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Area } from '../../entities/Area';
import { useReadAreaListQuery } from '../../services/areaApi';

type AreaAutocompleteProps = Omit<AutocompleteProps<Area, false, false, false>, 'options' | 'renderInput'> &
  Pick<TextFieldProps, 'error' | 'helperText' | 'label'>;

function AreaAutocompleteComponent(props: AreaAutocompleteProps, ref: React.Ref<HTMLDivElement>) {
  const { disabled, error, helperText, label, ...autocompleteProps } = props;
  const areaList = useReadAreaListQuery();

  const apiError = areaList.error as ServerError | undefined;

  const options = useMemo(() => {
    return [...(areaList.data ?? [])].sort((a, b) =>
      a.description === 'OTHER' ? 1 : b.description === 'OTHER' ? -1 : a.description.localeCompare(b.description),
    );
  }, [areaList.data]);

  return (
    <Autocomplete
      disabled={disabled || areaList?.data?.length === 0 || areaList.isError || areaList.isFetching}
      {...autocompleteProps}
      options={options}
      getOptionLabel={(option) => option.description}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error || Boolean(apiError)}
          label={label ?? ' Area'}
          helperText={helperText || apiError?.data?.message || apiError?.message}
          ref={ref}
        />
      )}
      renderOption={(props, option) => (
        <Typography {...props}>
          {option.slug}
          <Typography sx={{ opacity: 0.7, fontSize: '0.8em', marginLeft: 1 }}>{option.description}</Typography>
        </Typography>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

export const AreaAutocomplete = forwardRef(AreaAutocompleteComponent);
