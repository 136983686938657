import { memo } from 'react';
import { FieldError } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridButton, GridButtonGroup } from '../../../../../../components/GridButtonGroup';
import { Title } from '../Title';

interface ShiftSelectorProps {
  value: number | null;
  onChange: (value: number) => void;
  error: FieldError | undefined;
}

function ShiftSelectorComponent(props: ShiftSelectorProps) {
  const { value, onChange, error } = props;

  return (
    <Stack direction="column">
      <Title title={'In case of non-working days:'}>{/* {'In caso di giorno non lavorativo:'} */}</Title>
      <GridButtonGroup aria-label="outlined button group" sx={{ width: '100%' }} wrapAfter={3}>
        <GridButton key="-1" onClick={() => onChange(-1)} checked={value === -1}>
          {/* {'Usa giorno lavorativo precedente'} */}
          {'Use the previous working day'}
        </GridButton>
        <GridButton key="0" onClick={() => onChange(0)} checked={value === 0}>
          {/* {'Ignora'} */}
          {'Do nothing'}
        </GridButton>
        <GridButton key="1" onClick={() => onChange(1)} checked={value === 1}>
          {/* {'Usa giorno lavorativo successivo'} */}
          {'Use the next working day'}
        </GridButton>
      </GridButtonGroup>
      {error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </Stack>
  );
}

export const ShiftSelector = memo(ShiftSelectorComponent);
