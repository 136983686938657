import { useMemo, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { ServerErrorBox } from '@top-solution/microtecnica-mui';
import { ApiError } from '@top-solution/microtecnica-utils';
import Box from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';

import { FullPageProgress } from '../Progress';
import { Breadcrumb, Header } from './Header';

export interface AppLayoutProps extends ContainerProps {
  children?: ReactNode | ReactNode[];
  title?: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: ReactNode;
  headerFilters?: ReactNode;
}

const APP_NAME = 'Planner';

export default function Layout(props: AppLayoutProps): JSX.Element {
  const {
    children,
    title,
    breadcrumbs,
    inProgress,
    error,
    progressIndicator,
    headerFilters,
    sx,
    maxWidth,
    ...containerProps
  } = props;

  const pageTitle = useMemo(() => {
    let pageTitle;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs[breadcrumbs.length - 1].title;
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header breadcrumbs={breadcrumbs}>{headerFilters}</Header>
      <Toolbar />
      <Box
        sx={{
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Container
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            paddingTop: 1.25,
            paddingBottom: 0,
            paddingX: 1.5,
            ...sx,
          }}
          maxWidth={error ? 'sm' : maxWidth}
          disableGutters
          {...containerProps}
        >
          {inProgress ? progressIndicator || <FullPageProgress /> : error ? <ServerErrorBox error={error} /> : children}
        </Container>
      </Box>
    </>
  );
}
