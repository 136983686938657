import React, { useState } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { ScheduleTabProps, WeekDayScheduleForm, WeekDayScheduleFormSchema } from '../../../../../../entities/Task';
import { useTaskScheduling } from '../../../../../../hooks/useTaskScheduling';
import { DebugTable } from '../DebugTable';
import { Preview } from '../Preview/Preview';
import { ScheduleActionButtons } from '../ScheduleActionButtons';
import { MonthsSelector } from '../selectors/MonthsSelector';
import { DayOccurrencesSelector } from '../selectors/OccurrenceSelector';
import { WeekDayDaysSelector } from '../selectors/WeekDaysSelector';

const defaultValues: DefaultValues<WeekDayScheduleForm> = {
  month: 0,
  weekDay: 0,
  occurrence: 0,
};

function WeekDayTabComponent(props: ScheduleTabProps) {
  const [previewYear, setPreviewYear] = useState(new Date().getFullYear());
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  const { dayHasTask } = useTaskScheduling(previewYear, previewYear);

  const { control, handleSubmit, watch, setValue, reset } = useForm<WeekDayScheduleForm>({
    defaultValues: props.initialValues ?? defaultValues,
    resolver: zodResolver(WeekDayScheduleFormSchema),
  });

  const month = watch('month');
  const weekDay = watch('weekDay');
  const occurence = watch('occurrence');

  return (
    <Stack
      component="form"
      direction="column"
      onSubmit={handleSubmit(props.onSubmit)}
      onReset={() => reset()}
      sx={{ flex: 1 }}
    >
      {' '}
      <DialogContent sx={{ paddingTop: 0 }}>
        <Stack direction="column" gap={2} paddingTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="month"
                render={({ field, fieldState: { error } }) => <MonthsSelector {...field} error={error} />}
              />
            </Grid>
            <Grid item xs={12} xl={7}>
              <Controller
                control={control}
                name="occurrence"
                render={({ field, fieldState: { error } }) => <DayOccurrencesSelector {...field} error={error} />}
              />
            </Grid>
            <Grid item xs={12} xl={5}>
              <Controller
                control={control}
                name="weekDay"
                render={({ field, fieldState: { error } }) => <WeekDayDaysSelector {...field} error={error} />}
              />
            </Grid>
            <Grid item xs={12}>
              <Preview
                open={props.previewOpen}
                onToggle={props.onPreviewOpenChange}
                year={previewYear}
                onYearChange={(year) => setPreviewYear(year)}
                isDayChecked={(date) =>
                  dayHasTask(
                    {
                      schedule: {
                        day: 0,
                        month,
                        occurrence: occurence,
                        shift: 0,
                        weekDay,
                        workDay: 0,
                      },
                      overrides: [],
                    },
                    date,
                  ).hasTask
                }
              />
            </Grid>
          </Grid>
          {showDebugInfo ? (
            <Grid item xs={12}>
              <DebugTable
                schedule={{
                  month,
                  day: 0,
                  shift: 0,
                  workDay: 0,
                  occurrence: occurence,
                  weekDay,
                }}
                onImport={(taskRecurrence) => {
                  if (taskRecurrence.month) {
                    setValue('month', taskRecurrence.month);
                  }
                  if (taskRecurrence.weekDay) {
                    setValue('weekDay', taskRecurrence.weekDay);
                  }
                  if (taskRecurrence.occurrence) {
                    setValue('occurrence', taskRecurrence.occurrence);
                  }
                }}
              />
            </Grid>
          ) : null}
        </Stack>
      </DialogContent>
      <ScheduleActionButtons
        onClose={props.onClose}
        onToggleDebugInfo={() => setShowDebugInfo((s) => !s)}
        dialogActions={props.dialogActions}
      />
    </Stack>
  );
}

export const WeeklyTab = React.memo(WeekDayTabComponent);
