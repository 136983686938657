import { useMemo, useState } from 'react';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert, ErrorDetails, UserAutocomplete } from '@top-solution/microtecnica-mui';
import { UserSchema, useReadAppUserRoleListQuery, useUpdateAppUserMutation } from '@top-solution/microtecnica-utils';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { UserRoleChip } from '../../../components/UserRoleChip';
import { User, UserRoleName } from '../../../entities/User';
import { createEmptyTimetable } from '../../../entities/UserTimetable';
import { useReadUserListQuery, useUpdateUserTimetableMutation } from '../../../services/userApi';

const appId = `${import.meta.env.VITE_APP_ID}`;

type UserEditAdminFormProps = {
  initialValues: DefaultValues<User>;
  role: UserRoleName;
  onClose: () => void;
};

export function UserEditAdminForm(props: UserEditAdminFormProps): JSX.Element {
  const { initialValues, onClose, role } = props;
  const userList = useReadUserListQuery();
  const { control, handleSubmit, watch } = useForm<Pick<User, 'username'>>({
    defaultValues: { ...initialValues },
    resolver: zodResolver(UserSchema.pick({ username: true })),
  });
  const [appRoleError, setAppRoleError] = useState<string | undefined>();

  const { data: roles } = useReadAppUserRoleListQuery(appId);
  const [updateAppUser, updateAppUserStatus] = useUpdateAppUserMutation();
  const [updateTimetable, updateTimetableStatus] = useUpdateUserTimetableMutation();

  const onSubmit = async (data: Pick<User, 'username'>) => {
    setAppRoleError(undefined);
    if (data.username) {
      const appRole = roles?.find((r) => r.name === role);
      if (appRole) {
        await updateAppUser({
          user: {
            username: data.username,
            roles: [appRole],
          },
          appId: appId,
        }).unwrap();
        await updateTimetable({ timetable: createEmptyTimetable(), username: data.username }).unwrap();
      } else {
        setAppRoleError(`Can't find the role with name "${role}" on Common Data.`);
      }
    }

    onClose();
  };

  const username = watch('username');

  const userAlreadyPresent = useMemo(
    () => Boolean(userList.data?.find((u) => u.username === username)),
    [userList.data, username],
  );

  const isLoading = updateAppUserStatus.isLoading ?? updateTimetableStatus.isLoading;
  const error = updateAppUserStatus.error ?? updateTimetableStatus.error;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        {appRoleError ? <ErrorDetails content={appRoleError} title={''}></ErrorDetails> : null}
        <Grid container columnSpacing={2} paddingTop={1}>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: 2 }}>
              Role: <UserRoleChip user={{ role }} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="username"
              render={({ field, fieldState: { error } }) => (
                <UserAutocomplete
                  label="User name"
                  error={Boolean(error) || userAlreadyPresent}
                  helperText={error?.message || (userAlreadyPresent ? 'This user is already present' : ' ')}
                  fullWidth
                  {...field}
                  onChange={(_, user) => field.onChange(user)}
                />
              )}
            />
          </Grid>
        </Grid>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => onClose()} sx={{ marginRight: 'auto' }}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={userAlreadyPresent}
          loading={isLoading}
        >
          {initialValues.username ? 'Edit' : 'Add'}
        </LoadingButton>
      </DialogActions>
    </form>
  );
}
