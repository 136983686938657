import { memo, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  ChartData,
  LineController,
  BarController,
  TooltipItem,
} from 'chart.js';
import { endOfYear, formatDate, startOfYear } from 'date-fns';
import { deepmerge } from 'deepmerge-ts';
import Box from '@mui/material/Box';
import { grey, indigo, purple } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import { ChevronLeftIcon, ChevronRightIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { LinkButton } from '../../components/LinkButton';
import { PageTitle } from '../../components/PageTitle';
import { GanttTask } from '../../entities/Task';
import { UserRoleName } from '../../entities/User';
import { useGanttTasks } from '../../hooks/useGanttTasks';
import { formatFromDate, formatToDate } from '../../utils/date';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';
import { usePerformanceTasksStats } from './usePerformanceTasksStats';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
);

const ChartSkeleton = memo(function ChartSkeleton() {
  return (
    <Box sx={{ width: '100%', aspectRatio: '2' }}>
      <Skeleton sx={{ width: '100%', height: '100%', transform: 'unset' }}></Skeleton>
    </Box>
  );
});

const options = {
  plugins: {
    title: {
      display: true,
    },
    legend: {
      position: 'bottom' as const,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
};

/**
 * Calculates statistics and renders graphs only when tasks are fully loaded
 */
function GanttTasksWrapper(props: { fromDate: Date; toDate: Date }): JSX.Element {
  const { ganttTasks, fulfilledTimeStamp } = useGanttTasks({
    from: props.fromDate,
    to: props.toDate,
  });

  if (!fulfilledTimeStamp) {
    return (
      <>
        <ChartSkeleton />
        <ChartSkeleton />
      </>
    );
  }

  return <PerformanceCharts fromDate={props.fromDate} toDate={props.toDate} ganttTasks={ganttTasks} />;
}

function PerformanceCharts(props: { fromDate: Date; toDate: Date; ganttTasks: GanttTask[] }): JSX.Element {
  const { tasksStatsByMonth } = usePerformanceTasksStats(props.fromDate, props.toDate, props.ganttTasks);

  const monthsLabels = useMemo(() => {
    const monthsLabels: string[] = [];
    for (let i = 0; i < 12; i++) {
      const d = new Date(1970, i, 15);
      if (monthsLabels) {
        monthsLabels.push(formatDate(d, 'LLLL'));
      }
    }
    return monthsLabels;
  }, []);

  const [onTimeByMonth, escapeByMonth] = useMemo(() => {
    const datasets = [
      {
        label: 'Average',
        backgroundColor: alpha(indigo[500], 0.8),
        borderColor: alpha(indigo[500], 1),
        type: 'line' as 'bar',
      },
      {
        label: 'Finance',
        backgroundColor: alpha(purple[400], 0.6),
        borderColor: alpha(purple[400], 0.8),
        borderWidth: 2,
      },
      {
        label: 'Supplier',
        backgroundColor: alpha(grey[500], 0.6),
        borderColor: alpha(grey[500], 0.8),
        borderWidth: 2,
      },
    ];

    const onTimeByMonth: ChartData<'bar'> = {
      labels: monthsLabels,
      datasets: datasets.map((dataset) => ({
        ...dataset,
        data: [],
      })),
    };

    for (let i = 0; i < 12; i++) {
      const monthStats = tasksStatsByMonth[i];
      const finance = Math.round((monthStats.finance.onTime / monthStats.finance.total) * 1000) / 10;
      const supplier = Math.round((monthStats.supplier.onTime / monthStats.supplier.total) * 1000) / 10;
      onTimeByMonth.datasets[0].data.push((finance + supplier) / 2);
      onTimeByMonth.datasets[1].data.push(finance);
      onTimeByMonth.datasets[2].data.push(supplier);
    }

    const escapeByMonth: ChartData<'bar'> = {
      labels: monthsLabels,
      datasets: datasets.map((dataset) => ({
        ...dataset,
        data: [],
      })),
    };
    for (let i = 0; i < 12; i++) {
      const monthStats = tasksStatsByMonth[i];
      escapeByMonth.datasets[0].data.push((monthStats.finance.escapeCount + monthStats.supplier.escapeCount) / 2);
      escapeByMonth.datasets[1].data.push(monthStats.finance.escapeCount);
      escapeByMonth.datasets[2].data.push(monthStats.supplier.escapeCount);
    }

    return [onTimeByMonth, escapeByMonth];
  }, [monthsLabels, tasksStatsByMonth]);

  const onTimeOptions = useMemo(
    () =>
      deepmerge(options, {
        plugins: {
          title: {
            text: `On Time percentage by month`,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<'bar'>) => {
                return `${tooltipItem.raw}%`;
              },
            },
          },
        },
        scales: {
          y: {
            min: 0,
            max: 100,
          },
        },
      }),
    [],
  );

  const escapeOptions = useMemo(
    () =>
      deepmerge(options, {
        plugins: {
          title: {
            text: `Escape count by month`,
          },
        },
      }),
    [],
  );

  return (
    <>
      <Bar key="onTime" options={onTimeOptions} data={onTimeByMonth} />
      <Bar key="escape" options={escapeOptions} data={escapeByMonth} />
    </>
  );
}
export function PerformancePage(): JSX.Element {
  const [year, setYear] = useState(new Date().getFullYear());
  const fromDate = useMemo(() => startOfYear(new Date(year, 2, 14)), [year]);
  const toDate = useMemo(() => endOfYear(new Date(year, 2, 14)), [year]);

  const title = `Performance — Year`;
  const breadcrumbs = [{ title: `${title} ${year}` }];

  return (
    <AuthGuard
      unauthorizedFallback={<UnauthorizedPage />}
      authorizeRole={(role) => role === UserRoleName.ADMIN}
      authorizeUsername={(username) => username === 'monitor-fmrp'}
    >
      <Layout title={title} breadcrumbs={breadcrumbs} maxWidth={'md'} disableGutters sx={{ paddingBottom: 1 }}>
        <PageTitle
          title={
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {title}
                <IconButton onClick={() => setYear(year - 1)}>{<ChevronLeftIcon />}</IconButton>
                {year}
                <IconButton onClick={() => setYear(year + 1)} disabled={year >= new Date().getFullYear()}>
                  {<ChevronRightIcon />}
                </IconButton>
              </Stack>
            </>
          }
        >
          <Stack direction="row" justifyContent="center" gap={1}></Stack>
          <LinkButton to={`/overview?from=${formatFromDate(fromDate)}&to=${formatToDate(toDate)}`}>
            {'Overview'}
          </LinkButton>
        </PageTitle>
        <Stack direction="column" gap={2}>
          <GanttTasksWrapper fromDate={fromDate} toDate={toDate} />
        </Stack>
      </Layout>
    </AuthGuard>
  );
}
