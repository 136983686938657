import React, { useId, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDay, isFuture, sub } from 'date-fns';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { LinkButton } from '../../components/LinkButton';
import { PopoverTable } from '../../components/PopoverTable';
import { TaskStatusChip } from '../../components/TaskStatus';
import { UserTimetableTable } from '../../components/UserTimetableTable';
import { PeopleLoadMetric, PeopleLoadTimeSample, UserPeriodMetrics } from '../../entities/PeopleLoad';
import { TaskStatus } from '../../entities/Task';
import { User } from '../../entities/User';
import { formatDate, formatDuration, formatFromDate, formatToDate } from '../../utils/date';
import { printUserName } from '../../utils/users';
import { StatChip } from './StatChip';

const taskStatusPriority = [TaskStatus.OnTime, TaskStatus.Late, TaskStatus.Overdue, TaskStatus.Open];

function PersonLoadCellComponent(props: {
  userPeriodMetrics: UserPeriodMetrics;
  metric: PeopleLoadMetric;
  timeSample: PeopleLoadTimeSample;
  user: User;
}) {
  const { userPeriodMetrics, timeSample, metric, user } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showContent, setShowContent] = useState(false);

  const from = userPeriodMetrics.period.from;
  const to = userPeriodMetrics.period.to;
  const load = userPeriodMetrics.metrics.load;
  const onTime = userPeriodMetrics.metrics.onTime;
  const id = useId();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowContent(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const sortedTasks = useMemo(() => {
    const sortedTasks = [
      ...(userPeriodMetrics?.tasks ?? []).filter((t) => {
        if (t.status === TaskStatus.NotApplicable) {
          return false;
        }
        if (metric === PeopleLoadMetric.OnTime) {
          if (t.status === TaskStatus.Overdue || t.status === TaskStatus.Open) {
            return false;
          }
        }
        return true;
      }),
    ];
    sortedTasks.sort((a, b) => {
      if (a.status === b.status) {
        return (b.task.duration ?? -1) - (a.task.duration ?? -1);
        // return a.task.description.localeCompare(b.task.description);
      }

      return taskStatusPriority.indexOf(a.status) - taskStatusPriority.indexOf(b.status);
    });
    return sortedTasks;
  }, [userPeriodMetrics?.tasks, metric]);

  if (metric === PeopleLoadMetric.Load) {
    if (load === undefined) {
      return '—';
    }
  } else if (metric === PeopleLoadMetric.OnTime) {
    if (!userPeriodMetrics || isFuture(userPeriodMetrics.period.from)) {
      return null;
    }
    if (typeof onTime === 'undefined') {
      return '—';
    }
  }

  return (
    <Stack direction="row" justifyContent="flex-end" sx={{ marginX: -0.5, width: '100%' }}>
      <StatChip
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        metricValue={metric === PeopleLoadMetric.Load ? load : onTime}
        disabled={userPeriodMetrics.tasks.length === 0}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        TransitionProps={{
          onExited: () => setShowContent(false),
        }}
        slotProps={{
          paper: {
            elevation: 24,
          },
        }}
      >
        {showContent && (
          <Stack direction="column" sx={{ padding: 1, maxWidth: '50vw', overflow: 'auto', gap: 2, width: 640 }}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">{printUserName(user)}</Typography>
                <Typography variant="h6" fontFamily="monospace">
                  <StatChip metricValue={metric === PeopleLoadMetric.Load ? load : onTime} />
                </Typography>
              </Stack>
              <Typography fontWeight="bold" color="text.secondary">
                {formatDate(from, 'EEEE dd/MM/yyyy')}
                {timeSample === PeopleLoadTimeSample.Daily
                  ? null
                  : ` — ${formatDate(sub(to, { hours: 1 }), 'EEEE dd/MM/yyyy')}`}
              </Typography>
            </Box>
            {metric === PeopleLoadMetric.Load ? (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontWeight={500}>{`Total working time ${
                    timeSample === PeopleLoadTimeSample.Monthly
                      ? '(month)'
                      : timeSample === PeopleLoadTimeSample.Weekly
                        ? '(week)'
                        : ''
                  }`}</Typography>
                  <Typography fontWeight={500}>
                    {formatDuration(userPeriodMetrics.metrics.userWorkingSeconds)}
                  </Typography>
                </Stack>
                <Box>
                  <Typography fontWeight={500} gutterBottom>
                    {'Completed assigned tasks'}
                  </Typography>
                  <PopoverTable>
                    <TableRow sx={{ '& td, & th': { fontWeight: 500 } }}>
                      <TableCell component="th" scope="row" className="task">
                        {'Task'}
                      </TableCell>
                      <TableCell align="right" className="duration">
                        {'Scheduled'}
                      </TableCell>
                      <TableCell align="right" className="duration">
                        {'Actual'}
                      </TableCell>
                      <TableCell align="right" className="badge"></TableCell>
                    </TableRow>
                    {sortedTasks.map((row) => {
                      const actual = userPeriodMetrics.actualTasksDuration[row.task.id];
                      const scheduled = userPeriodMetrics.scheduledTasksDuration[row.task.id];
                      return (
                        <TableRow key={row.task.id} className="tasks" title={row.task.description}>
                          <TableCell component="th" scope="row" className="task">
                            {row.task.description}
                          </TableCell>
                          <TableCell align="right" className="duration">
                            {formatDuration(scheduled)}
                          </TableCell>
                          <TableCell
                            align="right"
                            className="duration"
                            sx={{ fontWeight: actual - scheduled <= 60 ? undefined : 500 }}
                          >
                            {formatDuration(actual)}
                          </TableCell>
                          <TableCell align="right" className="badge">
                            <TaskStatusChip badge value={row.status} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow sx={{ '& td, & th': { fontWeight: 500 } }}>
                      <TableCell component="th" scope="row" className="task">
                        {'Total tasks duration'}
                      </TableCell>
                      <TableCell align="right" className="duration">
                        {formatDuration(userPeriodMetrics.metrics.tasksScheduledDuration)}
                      </TableCell>
                      <TableCell align="right" className="duration">
                        {formatDuration(userPeriodMetrics.metrics.tasksActualDuration)}
                      </TableCell>
                      <TableCell align="right" className="badge"></TableCell>
                    </TableRow>
                  </PopoverTable>
                </Box>
                <Box>
                  <Typography fontWeight={500}>{'User timetable'}</Typography>
                  <UserTimetableTable
                    timetable={userPeriodMetrics.userTimetable}
                    showOnlyDay={timeSample === PeopleLoadTimeSample.Daily ? getDay(from) : undefined}
                  />
                </Box>
              </>
            ) : (
              <Box>
                <Typography fontWeight={500} gutterBottom>
                  {'Completed assigned tasks'}
                </Typography>
                {sortedTasks.length ? (
                  <PopoverTable>
                    {sortedTasks.map((row) => (
                      <TableRow key={row.task.id}>
                        <TableCell component="th" scope="row" title={row.task.description} className="task">
                          {row.task.description}
                        </TableCell>
                        <TableCell align="center" className="status">
                          <TaskStatusChip value={row.status} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </PopoverTable>
                ) : (
                  <Typography variant="body2" color="text.secondary" fontStyle="italic">
                    {'No completed task'}
                  </Typography>
                )}
              </Box>
            )}
            <Stack direction="row" justifyContent="flex-end">
              <LinkButton
                component={Link}
                to={`/gantt?username=${user.username}&from=${formatFromDate(from)}&to=${formatToDate(
                  to,
                )}&backup=0&reviewer=0&owner=1`}
                target="_blank"
                sx={{ margin: 0 }}
              >
                {'Tasks Gantt'}
              </LinkButton>
            </Stack>
          </Stack>
        )}
      </Popover>
    </Stack>
  );
}

export const PersonLoadCell = React.memo(PersonLoadCellComponent);
