import React from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { GridButton, GridButtonGroup } from '../../components/GridButtonGroup';
import { OverviewHeader } from './layout/OverviewHeader';
import { OverviewLayout } from './layout/OverviewLayout';
import { useOverviewQueryParams } from './overviewQueryParams';

function OverviewPageComponent() {
  const navigate = useNavigate();

  const { searchParams } = useOverviewQueryParams();

  const scenariosTab = useMatch('overview/scenarios') !== null;

  return (
    <OverviewLayout>
      <OverviewHeader showTasksCountCheckbox showEmptyCardsCheckbox>
        <GridButtonGroup sx={{ width: 320 }} wrapAfter={2}>
          <GridButton key="processes" onClick={() => navigate(`./processes?${searchParams}`)} checked={!scenariosTab}>
            {'Processes'}
          </GridButton>
          <GridButton key="scenario" onClick={() => navigate(`./scenarios?${searchParams}`)} checked={scenariosTab}>
            {'Scenarios'}
          </GridButton>
        </GridButtonGroup>
      </OverviewHeader>
      <Outlet />
    </OverviewLayout>
  );
}

export const OverviewPage = React.memo(OverviewPageComponent);
