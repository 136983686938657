import { Escape, EscapeSchema } from '../entities/Escape';
import { TAG_TYPES, api } from './baseApi';

const url = 'escape-types';

const escapeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readEscapeList: builder.query<Escape[], void>({
      query: () => ({ url }),
      transformResponse: (data) => EscapeSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.ESCAPE, id: `LIST` }],
    }),
    createEscape: builder.mutation<void, Omit<Escape, 'id'>>({
      query: (body) => ({ url: `${url}/`, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.ESCAPE, id: 'LIST' }],
    }),
    updateEscape: builder.mutation<void, Escape>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.ESCAPE, id: 'LIST' }],
    }),
    deleteEscape: builder.mutation<void, { escapeId: Escape['id'] }>({
      query: ({ escapeId }) => ({ url: `${url}/${escapeId}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.ESCAPE, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useReadEscapeListQuery, useCreateEscapeMutation, useUpdateEscapeMutation, useDeleteEscapeMutation } =
  escapeApi;
