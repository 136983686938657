import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { EditingTask, TaskConfigurationForm, TaskOverride, TimeScheduleForm } from '../../../../entities/Task';
import { TimeSchedulingStepStep } from '../TaskEditDialog/TimeSchedulingStep';
import { ScheduleFormComponent } from './ScheduleForm/ScheduleForm';
import { TaskConfigurationStep } from './TaskConfigurationStep';

type TaskOverrideDialogProps = Omit<DialogProps, 'onClose'> & {
  task: EditingTask;
  onClose: (value?: TaskConfigurationForm) => void;
  editMode?: boolean;
  day: Date;
  override: TaskOverride | undefined;
};

export function TaskOverrideDialog(props: TaskOverrideDialogProps): JSX.Element {
  const { onClose, task, day, override, ...dialogProps } = props;

  const [dateSchedule, setDateSchedule] = useState<{ day: Date } | null>({ day });
  const [timeSchedule, setTimeSchedule] = useState<TimeScheduleForm | null>(
    task
      ? ({
          dueHour: override?.dueHour ?? task?.dueHour,
          duration: override?.duration ?? task?.duration,
          dueHourMachine: override?.dueHourMachine ?? task?.dueHourMachine,
          durationMachine: override?.durationMachine ?? task?.durationMachine,
        } as TimeScheduleForm)
      : null,
  );

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const title = task?.id ? `Edit task` : 'Add Task';

  const closeButton = (
    <Button color="inherit" onClick={() => onClose()}>
      Cancel
    </Button>
  );

  return (
    <Dialog maxWidth="xl" fullWidth {...dialogProps} sx={{ minHeight: 786 }}>
      <DialogTitle sx={{ paddingBottom: 1 }}>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 1, flex: '0 0 auto', minHeight: 24 }}>
        {task && (
          <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 0, paddingBottom: 2 }}>
            {task.description}
          </Typography>
        )}
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{'Date'}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{'Time scheduling'}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{'Task configuration'}</StepLabel>
          </Step>
        </Stepper>
      </DialogContent>
      <Stack direction="column" justifyContent="space-between" flex={1} sx={{ paddingX: 1 }}>
        {activeStep === 0 ? (
          <ScheduleFormComponent
            onSubmit={(data) => {
              setDateSchedule(data);
              handleNext();
            }}
            onClose={onClose}
            dialogActions={closeButton}
            task={task}
            day={day}
            override={override}
          />
        ) : activeStep === 1 ? (
          <>
            {dateSchedule ? (
              <TimeSchedulingStepStep
                open={false}
                task={task}
                onBack={handleBack}
                timeSchedule={timeSchedule ?? undefined}
                onClose={(timeSchedule) => {
                  setTimeSchedule(timeSchedule);
                  handleNext();
                }}
                dialogActions={closeButton}
              />
            ) : null}
          </>
        ) : (
          <>
            {dateSchedule && timeSchedule ? (
              <TaskConfigurationStep
                open={false}
                day={day}
                task={task}
                onBack={handleBack}
                onClose={onClose}
                dialogActions={closeButton}
                timeSchedule={timeSchedule}
                overrideDay={dateSchedule.day}
                override={override}
              />
            ) : null}
          </>
        )}
      </Stack>
    </Dialog>
  );
}
