import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderProps, UserMenu, Logo, MenuItem as MTMuiMenuItem } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button, { buttonClasses } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useSectionList } from '../../hooks/useSectionList';
import { darkTheme } from '../../theme';
import { ChevronRightIcon, MenuIcon } from '../Icons';

export type Breadcrumb = {
  title: React.ReactNode;
  url?: string;
  renderComponent?: () => JSX.Element;
};

type CustomHeaderProps = Omit<HeaderProps, 'breadcrumbs'> & {
  breadcrumbs?: Breadcrumb[];
};

export function Header(props: CustomHeaderProps): JSX.Element {
  const { username } = useAuth();
  const { breadcrumbs, children } = props;
  const sections = useSectionList();
  const navigate = useNavigate();

  const [breadcrumbsMenuAnchorEl, setBreadcrumbsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const menuItems = useMemo<MTMuiMenuItem[]>(
    () =>
      [...sections.userSectionList, ...sections.adminSectionList].map(({ title, icon, url }) => ({
        label: title,
        icon: icon as JSX.Element,
        onClick: (closeMenu) => {
          navigate(url);
          closeMenu();
        },
      })),
    [sections, navigate],
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar
        sx={{
          '.MuiButton-root': {
            '&, &.Mui-disabled': {
              color: 'inherit',
            },
          },
          '.breadcrumb-component': {
            display: 'flex',
            alignItems: 'center',
            minWidth: '100px',
            flex: '0 1 auto',
          },
        }}
      >
        <Toolbar sx={{ paddingX: { xs: 0.5, xl: 1 } }}>
          <Button
            component={Link}
            to={'/'}
            size="large"
            sx={{ flex: '0 0 auto', '.app-title': { marginLeft: { xs: 1.5, xl: 2 } } }}
          >
            <Box component={Logo} sx={{ height: { xs: 24, xl: 36 } }} />
            {<span className="app-title">{'Planner'}</span>}
          </Button>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, minWidth: 0 }}>
            {breadcrumbs?.map(({ title, url, renderComponent }, index) => (
              <div key={url || index} className="breadcrumb-component">
                <ChevronRightIcon style={{ color: 'inherit' }} />
                {renderComponent ? (
                  renderComponent()
                ) : (
                  <Button component={Link} to={url ?? ''} disabled={!url} size="large" sx={{ lineHeight: '20px' }}>
                    <Box
                      component="span"
                      sx={{ flex: '0 1 auto', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    >
                      {title}
                    </Box>
                  </Button>
                )}
              </div>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {(breadcrumbs ?? []).length > 0 ? (
              <>
                <IconButton
                  id="basic-button"
                  aria-controls={breadcrumbsMenuAnchorEl ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={breadcrumbsMenuAnchorEl ? 'true' : undefined}
                  onClick={(e) => setBreadcrumbsMenuAnchorEl(e.currentTarget)}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={breadcrumbsMenuAnchorEl}
                  open={Boolean(breadcrumbsMenuAnchorEl)}
                  onClose={() => setBreadcrumbsMenuAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="stretch"
                    gap={0.125}
                    sx={{
                      width: 320,
                      [`& .${buttonClasses.root}`]: {
                        color: 'inherit',
                        borderRadius: 0,
                      },
                      '& .breadcrumb-component > *': {
                        width: '100%',
                      },
                    }}
                  >
                    {breadcrumbs?.map(({ title, url, renderComponent }, index) => (
                      <div key={url || index} className="breadcrumb-component">
                        {renderComponent ? (
                          renderComponent()
                        ) : (
                          <Button
                            component={Link}
                            to={url ?? ''}
                            disabled={!url}
                            size="large"
                            sx={{ lineHeight: '20px' }}
                          >
                            {title}
                          </Button>
                        )}
                      </div>
                    ))}
                  </Stack>
                </Menu>
              </>
            ) : null}
          </Box>
          <Box sx={{ flex: '1 1 auto' }} />
          <Box className="Header-children_wrapper">
            <ThemeProvider theme={darkTheme}>
              <Box
                sx={{
                  marginX: 1,
                  display: 'flex',
                  '.MuiFormControl-root, .MuiSkeleton-root': {
                    marginRight: 0.5,
                  },
                  '.MuiSkeleton-root': {
                    borderRadius: 1,
                    height: 40,
                  },
                }}
              >
                {children}
              </Box>
            </ThemeProvider>
          </Box>
          {username && <UserMenu menuItems={menuItems} />}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
