import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import { Layout } from '../../../components/Layout';
import { TaskListGrid } from './TaskListGrid';

export function ManageTasksPage(): JSX.Element {
  const { isAdmin } = useAuth();
  const title = isAdmin ? 'Manage Tasks' : 'My Tasks';
  const breadcrumbs = [{ title }];

  return (
    <AuthGuard>
      <Layout title={title} breadcrumbs={breadcrumbs} maxWidth={false}>
        <TaskListGrid />
      </Layout>
    </AuthGuard>
  );
}
