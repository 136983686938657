import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

interface CalendarDayProps {
  checked: boolean;
  children: React.ReactNode;
  title?: string;
  opacity?: number;
  onClick?: ButtonProps['onClick'];
  className?: string;
  color?: ButtonProps['color'];
  disabled?: boolean;
}
function UnstyledCalendarDayButton(props: CalendarDayProps) {
  return <Button {...props} variant="contained" disabled={!props.checked || props.disabled} />;
}

function UnstyledCalendarDay(props: CalendarDayProps) {
  return <div className={props.className}>{props.children}</div>;
}

export const StyledCalendarDayButton = styled(UnstyledCalendarDayButton)(({ theme, ...props }) => ({
  opacity: props.opacity,
  borderRadius: '50%',
  minWidth: '1.6em',
  width: '1.6em',
  height: '1.6em',
  fontSize: 'clamp(0.63rem, calc(0.54rem + 0.42vw), 0.88rem)',
  display: 'inline',
  textAlign: 'center',
  padding: '2px',
  backgroundColor: props.checked ? undefined : 'transparent',
  lineHeight: 1.3,
  '&, &.Mui-disabled': {
    color: props.checked ? theme.palette.getContrastText(theme.palette.primary.main) : theme.palette.text.secondary,
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
  '&, &:hover': {
    boxShadow: 'none',
  },
}));

export const StyledCalendarDay = styled(UnstyledCalendarDay)(({ theme, ...props }) => ({
  opacity: props.opacity,
  borderRadius: '50%',
  minWidth: '1.6em',
  width: '1.6em',
  height: '1.6em',
  fontSize: 'clamp(0.63rem, calc(0.54rem + 0.42vw), 0.88rem)',
  display: 'inline',
  textAlign: 'center',
  padding: '2px',
  backgroundColor: props.checked ? undefined : 'transparent',
  lineHeight: 1.3,
  '&, &.Mui-disabled': {
    color: props.checked ? theme.palette.getContrastText(theme.palette.primary.main) : theme.palette.text.secondary,
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
  '&, &:hover': {
    boxShadow: 'none',
  },
}));

export function CalendarDay(props: CalendarDayProps) {
  if (props.checked) {
    return <StyledCalendarDayButton {...props} />;
  } else {
    return <StyledCalendarDay {...props} />;
  }
}
