import { memo } from 'react';
import { FieldError } from 'react-hook-form';
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridButton, GridButtonGroup } from '../../../../../../components/GridButtonGroup';
import { Title } from '../Title';

// type DayOccurrenceRecord = Record<number, boolean>;

const occurrenceKeys = [1, 2, 4, 8, Math.pow(2, 14), Math.pow(2, 15)];

export const occurrenceNames: Record<string, string> = {
  [`${Math.pow(2, 0)}`]: 'First',
  [`${Math.pow(2, 1)}`]: 'Second',
  [`${Math.pow(2, 2)}`]: 'Third',
  [`${Math.pow(2, 3)}`]: 'Fourth',
  [`${Math.pow(2, 14)}`]: 'Second-last',
  [`${Math.pow(2, 15)}`]: 'Last',
};

export const occurrenceShortNames: Record<string, string> = {
  [`${Math.pow(2, 0)}`]: '1st',
  [`${Math.pow(2, 1)}`]: '2nd',
  [`${Math.pow(2, 2)}`]: '3rd',
  [`${Math.pow(2, 3)}`]: '4th',
  [`${Math.pow(2, 14)}`]: '- 2nd',
  [`${Math.pow(2, 15)}`]: '- 1st',
};

interface DayOccurrenceSelectorProps {
  value: number;
  onChange: (dayOccurrences: number) => void;
  error: FieldError | undefined;
}

function DayOccurrencesSelectorComponent(props: DayOccurrenceSelectorProps) {
  const { value, onChange, error } = props;

  function setAllDayOccurrences(enabled: boolean) {
    onChange(enabled ? Math.pow(2, 7) - 1 : 0);
  }

  return (
    <Stack direction="column">
      <Title
        title="Week:"
        onSelectNone={() => setAllDayOccurrences(false)}
        onSelectAll={() => setAllDayOccurrences(true)}
      />
      <GridButtonGroup sx={{ width: '100%', marginBottom: 1 }} wrapAfter={6}>
        {occurrenceKeys.map((dayOccurrence) => (
          <GridButton
            key={dayOccurrence}
            onClick={() => onChange(value ^ dayOccurrence)}
            checked={(value & dayOccurrence) !== 0}
          >
            {occurrenceNames[`${dayOccurrence}`] ?? ''}
          </GridButton>
        ))}
      </GridButtonGroup>
      {error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </Stack>
  );
}

export const DayOccurrencesSelector = memo(DayOccurrencesSelectorComponent);
