import React, { useMemo } from 'react';
import { Month, add, eachWeekOfInterval, getDaysInMonth, isPast } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { capitalize } from '@mui/material/utils';
import { useTaskScheduling } from '../../../../../../hooks/useTaskScheduling';
import { CalendarDay } from './CalendarDay';
import { DayNamesRow } from './DayNamesRow';
import { PreviewProps } from './PreviewProps';

interface PreviewMonthProps extends PreviewProps {
  month: number;
}

function PreviewMonthComponent(props: PreviewMonthProps) {
  const { month, year, isDayChecked, onDayClick } = props;
  const { isWorkingDay } = useTaskScheduling(year, year);

  const weeks = useMemo(
    () =>
      eachWeekOfInterval(
        {
          start: new Date(year, month, 1),
          end: add(new Date(year, month, getDaysInMonth(new Date(year, month, 1))), { days: 7 }),
        },
        {
          weekStartsOn: 1,
        },
      ).slice(0, 6),
    [month, year],
  );

  return (
    <Stack direction="column" gap={0.25}>
      <Typography color="primary" textAlign="center">
        {capitalize(enGB.localize?.month(month as Month) ?? '')}
      </Typography>
      <DayNamesRow />
      {weeks.map((week) => {
        return (
          <Stack direction="row" justifyContent="center" gap={0.75} key={week.valueOf()}>
            {Array.from(new Array(7)).map((_, i) => {
              const day = add(week, { days: i });
              const inMonth = day.getMonth() === month;
              const checked = inMonth && isDayChecked(day);
              return (
                <CalendarDay
                  key={i}
                  title={day.toDateString()}
                  opacity={!inMonth ? 0.3 : isWorkingDay(day) ? 1 : 0.55}
                  checked={checked}
                  onClick={onDayClick ? (event) => onDayClick(event, day) : undefined}
                  color={checked && props.getDayColor ? props.getDayColor(day) : undefined}
                  disabled={isPast(day)}
                >
                  {day.getDate()}
                </CalendarDay>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
}

export const PreviewMonth = React.memo(PreviewMonthComponent);
