import React from 'react';
import { RgbColorPicker } from 'react-colorful';
import { colord } from 'colord';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';

interface ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  valueLabel?: string;
}

function ColorPickerComponent(props: ColorPickerProps) {
  const { value, onChange, valueLabel } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = debounce(onChange as any, 200);

  return (
    <Box
      sx={{
        '& .react-colorful': {
          width: '100%',
        },
      }}
    >
      <Typography gutterBottom>
        <b>{valueLabel ?? 'Selected color'}: </b>
        <Box
          component="span"
          sx={{
            width: '1em',
            display: 'inline-block',
            height: '1em',
            bgcolor: value,
            borderRadius: '50%',
            marginX: 1,
            position: 'relative',
            top: '.125rem',
            border: '1px solid',
            borderColor: 'divider',
          }}
        />
        <Typography component="span" fontFamily="monospace">
          {value}
        </Typography>
      </Typography>
      <RgbColorPicker
        color={colord(value).toRgb()}
        onChange={(color) => {
          handleChange(colord(color).toHex());
        }}
      />
    </Box>
  );
}

export const ColorPicker = React.memo(ColorPickerComponent);
