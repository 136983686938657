import { useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { DataGridWrapper, ErrorAlert } from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import {
  add,
  differenceInDays,
  differenceInWeeks,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { GridButton, GridButtonGroup } from '../../components/GridButtonGroup';
import { ChevronLeftIcon, ChevronRightIcon } from '../../components/Icons';
import { Layout } from '../../components/Layout';
import { LinkButton } from '../../components/LinkButton';
import { PageTitle } from '../../components/PageTitle';
import { StyledDataGrid } from '../../components/StyledDataGrid';
import {
  PeopleLoadMetric,
  PeopleLoadMetricNames,
  PeopleLoadTimeSample,
  PeopleLoadTimeSampleNames,
} from '../../entities/PeopleLoad';
import { UserRoleName } from '../../entities/User';
import { UseGanttTasksParams, useGanttTasks } from '../../hooks/useGanttTasks';
import { usePlannerConfig } from '../../hooks/usePlannerConfig';
import { stripedGetRowClassName } from '../../utils/datagrid';
import {
  dateRangePickerShortcutsItems,
  formatFromDate,
  formatISODate,
  formatToDate,
  parseISODate,
  weekStartsOn,
} from '../../utils/date';
import { UnauthorizedPage } from '../Error/UnauthorizedPage';
import { isFromToGridColDef } from './usePeopleLoadColDef';
import { usePeopleLoadTable } from './usePeopleLoadTable';

const PAGE_TITLE = 'People Load';
const METRIC_PARAM = 'metric';
const FROM_PARAM = 'from';
const TO_PARAM = 'to';
const TIME_SAMPLE_PARAM = 'time-sample';
const breadcrumbs = [{ title: PAGE_TITLE }];

const gridInitialState: GridInitialStatePremium = {
  sorting: {
    sortModel: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
  },
};

interface PeopleLoadComponentProps {
  timeSample: PeopleLoadTimeSample;
  metric: PeopleLoadMetric;
}

function PeopleLoadComponent(props: PeopleLoadComponentProps): JSX.Element {
  const { timeSample, metric } = props;
  const [qs, setQs] = useSearchParams();
  const from = qs.get(FROM_PARAM);
  const to = qs.get(TO_PARAM);
  const { patchConfig } = usePlannerConfig();

  const fromDate = useMemo(() => startOfDay(parseISODate(from) || new Date()), [from]);
  const toDate = useMemo(() => startOfDay(parseISODate(to) || new Date()), [to]);

  const useGanttTasksParams: UseGanttTasksParams = useMemo(
    () => ({
      from: fromDate,
      to: toDate,
      skip: false,
      disableMonthFiltering: true,
    }),
    [fromDate, toDate],
  );

  const ganttTasks = useGanttTasks(useGanttTasksParams);

  const { columns, rows, averageRow, error } = usePeopleLoadTable(timeSample, metric, fromDate, toDate);

  return (
    <AuthGuard
      unauthorizedFallback={<UnauthorizedPage />}
      authorizeRole={(role) => role === UserRoleName.ADMIN}
      authorizeUsername={(username) => username === 'monitor-fmrp'}
    >
      <Layout
        title={PAGE_TITLE}
        breadcrumbs={breadcrumbs}
        maxWidth={false}
        disableGutters
        headerFilters={
          <DateRangePicker
            value={[fromDate, sub(toDate, { days: 1 })]}
            disableAutoMonthSwitching
            onAccept={([fromDate, toDate]) => {
              if (fromDate && toDate) {
                setQs((qs) => {
                  let roundedFrom = fromDate;
                  let roundedTo = toDate;

                  /**
                   * Round dates (to enforce the selection of, i.e., at least a month
                   * in the monthly view)
                   */
                  if (timeSample === PeopleLoadTimeSample.Weekly) {
                    roundedFrom = startOfWeek(fromDate, weekStartsOn);
                    roundedTo = endOfWeek(toDate, weekStartsOn);
                  } else if (timeSample === PeopleLoadTimeSample.Monthly) {
                    roundedFrom = startOfMonth(fromDate);
                    roundedTo = endOfMonth(toDate);
                  }

                  qs.set(FROM_PARAM, formatFromDate(roundedFrom));
                  qs.set(TO_PARAM, formatFromDate(add(roundedTo, { days: 1 })));
                  return qs;
                });
              }
            }}
            slots={{
              fieldSeparator: () => null,
            }}
            slotProps={{
              textField: {
                variant: 'filled',
                size: 'small',
                sx: { width: '13ch' },
              },
              shortcuts: {
                items: dateRangePickerShortcutsItems,
              },
            }}
          />
        }
        sx={{ paddingBottom: 1 }}
      >
        <PageTitle title={PAGE_TITLE}>
          <Stack direction="row" justifyContent="center" gap={1}></Stack>
          <LinkButton to={`/overview?from=${formatFromDate(fromDate)}&to=${formatToDate(toDate)}`}>
            {'Overview'}
          </LinkButton>
        </PageTitle>
        {error ? <ErrorAlert error={error} sx={{ marginBottom: 1 }} /> : null}
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap" marginBottom={2} rowGap={1}>
          <GridButtonGroup wrapAfter={2} sx={{ minWidth: 380 }}>
            <GridButton
              checked={metric === PeopleLoadMetric.Load}
              onClick={() => {
                setQs((qs) => {
                  qs.set(METRIC_PARAM, PeopleLoadMetric.Load);
                  return qs;
                });
              }}
            >
              {PeopleLoadMetricNames[PeopleLoadMetric.Load]}
            </GridButton>
            <GridButton
              checked={metric === PeopleLoadMetric.OnTime}
              onClick={() => {
                setQs((qs) => {
                  qs.set(METRIC_PARAM, PeopleLoadMetric.OnTime);
                  return qs;
                });
              }}
            >
              {PeopleLoadMetricNames[PeopleLoadMetric.OnTime]}
            </GridButton>
          </GridButtonGroup>
          <GridButtonGroup wrapAfter={3} sx={{ minWidth: 380 }}>
            <GridButton
              checked={timeSample === PeopleLoadTimeSample.Daily}
              onClick={() => {
                setQs((qs) => {
                  qs.set(TIME_SAMPLE_PARAM, PeopleLoadTimeSample.Daily);
                  patchConfig({ pages: { peopleLoad: { params: { timeSample: PeopleLoadTimeSample.Daily } } } });

                  const sampleDifferenceInDays = differenceInDays(toDate, fromDate);

                  let from = fromDate;

                  if (sampleDifferenceInDays > 31) {
                    from = sub(toDate, { months: 1 });
                  }

                  qs.set('from', formatFromDate(from));
                  qs.set('to', formatToDate(toDate));
                  return qs;
                });
              }}
            >
              {PeopleLoadTimeSampleNames[PeopleLoadTimeSample.Daily]}
            </GridButton>
            <GridButton
              checked={timeSample === PeopleLoadTimeSample.Weekly}
              onClick={() => {
                setQs((qs) => {
                  qs.set(TIME_SAMPLE_PARAM, PeopleLoadTimeSample.Weekly);
                  patchConfig({ pages: { peopleLoad: { params: { timeSample: PeopleLoadTimeSample.Weekly } } } });

                  const sampleDifferenceInWeeks = differenceInWeeks(toDate, fromDate);

                  let from = fromDate;
                  const to = toDate;

                  if (sampleDifferenceInWeeks < 6) {
                    from = sub(toDate, { weeks: 5 });
                  } else if (sampleDifferenceInWeeks > 12) {
                    from = sub(toDate, { weeks: 11 });
                  }

                  qs.set('from', formatFromDate(startOfWeek(from, weekStartsOn)));
                  qs.set('to', formatToDate(add(endOfWeek(sub(to, { days: 1 }), weekStartsOn), { days: 1 })));
                  return qs;
                });
              }}
            >
              {PeopleLoadTimeSampleNames[PeopleLoadTimeSample.Weekly]}
            </GridButton>
            <GridButton
              checked={timeSample === PeopleLoadTimeSample.Monthly}
              onClick={() => {
                setQs((qs) => {
                  qs.set(TIME_SAMPLE_PARAM, PeopleLoadTimeSample.Monthly);
                  patchConfig({ pages: { peopleLoad: { params: { timeSample: PeopleLoadTimeSample.Monthly } } } });

                  let from = fromDate;
                  const to = toDate;

                  from = sub(toDate, { months: 12 });

                  qs.set('from', formatFromDate(startOfMonth(from)));
                  qs.set('to', formatToDate(add(endOfMonth(sub(to, { days: 1 })), { days: 1 })));
                  return qs;
                });
              }}
            >
              {PeopleLoadTimeSampleNames[PeopleLoadTimeSample.Monthly]}
            </GridButton>
          </GridButtonGroup>
          <Stack direction="row" gap={1}>
            <Button
              startIcon={<ChevronLeftIcon />}
              variant="outlined"
              onClick={() => {
                setQs((qs) => {
                  qs.set(
                    FROM_PARAM,
                    formatFromDate(
                      sub(fromDate, timeSample === PeopleLoadTimeSample.Monthly ? { months: 1 } : { weeks: 1 }),
                    ),
                  );
                  qs.set(
                    TO_PARAM,
                    formatToDate(
                      sub(toDate, timeSample === PeopleLoadTimeSample.Monthly ? { months: 1 } : { weeks: 1 }),
                    ),
                  );
                  return qs;
                });
              }}
            >
              {`Previous ${timeSample === PeopleLoadTimeSample.Monthly ? 'month' : 'week'}`}
            </Button>
            <Button
              endIcon={<ChevronRightIcon />}
              variant="outlined"
              onClick={() => {
                setQs((qs) => {
                  qs.set(
                    FROM_PARAM,
                    formatFromDate(
                      add(fromDate, timeSample === PeopleLoadTimeSample.Monthly ? { months: 1 } : { weeks: 1 }),
                    ),
                  );
                  qs.set(
                    TO_PARAM,
                    formatToDate(
                      add(toDate, timeSample === PeopleLoadTimeSample.Monthly ? { months: 1 } : { weeks: 1 }),
                    ),
                  );
                  return qs;
                });
              }}
            >
              {`Next ${timeSample === PeopleLoadTimeSample.Monthly ? 'month' : 'week'}`}
            </Button>
          </Stack>
        </Stack>
        <DataGridWrapper>
          <StyledDataGrid
            rows={rows}
            columns={columns}
            loading={ganttTasks.isFetching}
            density="compact"
            hideFooter
            disableColumnMenu
            disableColumnReorder
            initialState={gridInitialState}
            pinnedColumns={{
              left: ['name'],
            }}
            getRowClassName={stripedGetRowClassName}
            pinnedRows={{ bottom: [averageRow] }}
            onColumnHeaderClick={(params) => {
              const colDef = params.colDef;
              if (isFromToGridColDef(colDef)) {
                if (timeSample === PeopleLoadTimeSample.Weekly) {
                  setQs((qs) => {
                    qs.set(TIME_SAMPLE_PARAM, PeopleLoadTimeSample.Daily);
                    qs.set(FROM_PARAM, formatFromDate(colDef.from));
                    qs.set(TO_PARAM, formatToDate(colDef.to));
                    return qs;
                  });
                } else if (timeSample === PeopleLoadTimeSample.Monthly) {
                  setQs((qs) => {
                    qs.set(TIME_SAMPLE_PARAM, PeopleLoadTimeSample.Weekly);
                    qs.set(FROM_PARAM, formatFromDate(startOfWeek(colDef.from, weekStartsOn)));
                    qs.set(TO_PARAM, formatToDate(add(endOfWeek(colDef.to, weekStartsOn), { days: 1 })));
                    return qs;
                  });
                }
              }
            }}
            // columnGroupingModel={columnGroupingModel}
            // experimentalFeatures={{ columnGrouping: true }}
            sx={{
              '.ClickableHeader': {
                cursor: 'pointer',
                '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05) },
              },
              '.DisabledCell': {
                backgroundColor: (theme) => theme.palette.grey[100],
              },
              '.MuiDataGrid-pinnedColumns--left': {
                height: '100% !important',
              },
            }}
          />
        </DataGridWrapper>
      </Layout>
    </AuthGuard>
  );
}

/**
 * This wrapper redirects to a route with a [from, to] range that is coherent with the
 * time sample, so <PeopleLoadPage /> doesn't need to handle it. This prevents the unnecessary
 * triggering of useGanttTasks.
 */
export function PeopleLoadPage() {
  const [qs] = useSearchParams();
  const { config } = usePlannerConfig();
  const from = qs.get(FROM_PARAM);

  const metric = useMemo(() => (qs.get(METRIC_PARAM) ?? PeopleLoadMetric.Load) as PeopleLoadMetric, [qs]);
  const timeSample = useMemo(
    () =>
      (qs.get(TIME_SAMPLE_PARAM) ??
        config?.pages?.peopleLoad?.params?.timeSample ??
        PeopleLoadTimeSample.Daily) as PeopleLoadTimeSample,
    [config?.pages?.peopleLoad?.params?.timeSample, qs],
  );

  if (!from || !metric || !timeSample) {
    if (!from) {
      const today = startOfDay(new Date());
      const from = startOfWeek(sub(today, { weeks: 2 }), weekStartsOn);
      qs.set(FROM_PARAM, formatISODate(from));
      qs.set(TO_PARAM, formatISODate(endOfWeek(today, weekStartsOn)));
    }
    if (!metric) {
      qs.set(METRIC_PARAM, metric ?? PeopleLoadMetric.Load);
    }
    if (!timeSample) {
      qs.set(TIME_SAMPLE_PARAM, metric ?? PeopleLoadTimeSample.Daily);
    }
    return (
      <Layout title={PAGE_TITLE} maxWidth={false} disableGutters>
        <Navigate to={`.?${qs}`} replace={true} />
      </Layout>
    );
  }

  return <PeopleLoadComponent metric={metric} timeSample={timeSample} />;
}
