import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { z } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { UserRoleChip } from '../../../components/UserRoleChip';
import { User } from '../../../entities/User';
import { DayTimetableSchema, Timetable, createEmptyTimetable } from '../../../entities/UserTimetable';
import { useUpdateUserTimetableMutation } from '../../../services/userApi';
import { WorkingHoursEditor } from './WorkingHoursInput';

type UserTimetableDialogProps = Omit<DialogProps, 'onClose'> & {
  user: User;
  onClose: () => void;
  editMode?: true;
};

export function UserTimetableDialog(props: UserTimetableDialogProps): JSX.Element {
  const { user, onClose, ...dialogProps } = props;

  const { control, handleSubmit, watch, setValue } = useForm<{ timetable: Timetable }>({
    defaultValues: {
      timetable: createEmptyTimetable(),
    },
    resolver: zodResolver(z.object({ timetable: z.array(DayTimetableSchema) })),
  });

  const [updateTimetable, updateTimetableStatus] = useUpdateUserTimetableMutation();

  const onSubmit = async (data: { timetable: Timetable }) => {
    await updateTimetable({ ...data, username: props.user.username }).unwrap();

    onClose();
  };

  useEffect(() => {
    if (user.timetable) {
      setValue('timetable', user.timetable);
    }
  }, [setValue, user.timetable]);

  return (
    <Dialog maxWidth="md" fullWidth {...dialogProps}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{'Edit user timetable'}</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center" gap={2} marginBottom={2}>
            {props.user.firstName || props.user.lastName ? (
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography variant="body1" fontWeight="bold">
                  {props.user.firstName}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {props.user.lastName}
                </Typography>
              </Stack>
            ) : null}
            <Typography variant="body1" fontFamily="monospace">
              {props.user.username}
            </Typography>
            <Box sx={{ marginLeft: 'auto' }}>
              <UserRoleChip user={props.user} />
            </Box>
          </Stack>
          <WorkingHoursEditor control={control} watch={watch} setValue={setValue} />
          {updateTimetableStatus.error && <ErrorAlert error={updateTimetableStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => onClose()} sx={{ marginRight: 'auto' }}>
            Cancel
          </Button>
          <LoadingButton type="submit" color="primary" variant="contained" loading={updateTimetableStatus.isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
