import { Process, ScenarioProcess, ScenarioProcessSchema } from '../entities/Process';
import { Scenario, ScenarioSchema } from '../entities/Scenario';
import { api, TAG_TYPES } from './baseApi';

const url = 'scenarios';

const scenarioApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readScenarioList: builder.query<Array<Scenario & { process: ScenarioProcess }>, void>({
      query: () => ({ url: url }),
      transformResponse: (data) => ScenarioSchema.extend({ process: ScenarioProcessSchema }).array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.SCENARIO, id: 'LIST' }],
    }),
    createScenario: builder.mutation<void, Omit<Scenario, 'id'> & { processId: Process['id'] }>({
      query: (body) => ({ url: `${url}/`, method: 'POST', body }),
      invalidatesTags: () => [
        { type: TAG_TYPES.SCENARIO, id: 'LIST' },
        { type: TAG_TYPES.PROCESS, id: 'LIST' },
      ],
    }),
    updateScenario: builder.mutation<void, Scenario & { processId: Process['id'] }>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'PUT', body }),
      invalidatesTags: () => [
        { type: TAG_TYPES.SCENARIO, id: 'LIST' },
        { type: TAG_TYPES.PROCESS, id: 'LIST' },
      ],
    }),
    deleteScenario: builder.mutation<void, { scenarioId: Scenario['id']; processId: Process['id'] }>({
      query: ({ scenarioId }) => ({ url: `${url}/${scenarioId}`, method: 'DELETE' }),
      invalidatesTags: () => [
        { type: TAG_TYPES.SCENARIO, id: 'LIST' },
        { type: TAG_TYPES.PROCESS, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useReadScenarioListQuery,
  useCreateScenarioMutation,
  useUpdateScenarioMutation,
  useDeleteScenarioMutation,
} = scenarioApi;
