import * as React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Turnback, TurnbackSchema } from '../../../entities/Turnback';
import { useCreateTurnbackMutation, useUpdateTurnbackMutation } from '../../../services/turnbackApi';

interface EditTurnbackDialogProps {
  turnback?: Turnback;
  open: boolean;
  onClose: () => void;
}

function EditTurnbackDialogComponent(props: EditTurnbackDialogProps) {
  const { turnback, open, onClose } = props;
  const [createTurnback, createTurnbackStatus] = useCreateTurnbackMutation();
  const [updateTurnback, updateTurnbackStatus] = useUpdateTurnbackMutation();

  const { control, reset, setValue, handleSubmit, formState } = useForm<Pick<Turnback, 'name'>>({
    defaultValues: { name: '' },
    resolver: zodResolver(TurnbackSchema.pick({ name: true })),
  });

  const handleCreate = (data: Pick<Turnback, 'name'>) => {
    if (turnback) {
      updateTurnback({ ...turnback, ...data });
    } else {
      createTurnback({ ...data });
    }
    onClose();
  };

  useEffect(() => {
    if (open && turnback && !formState.isDirty) {
      setValue('name', turnback.name);
    }
  }, [formState.isDirty, open, setValue, turnback]);

  const error = createTurnbackStatus.error ?? updateTurnbackStatus.error;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      TransitionProps={{
        onExited: () => {
          reset();
          createTurnbackStatus.reset();
          updateTurnbackStatus.reset();
          onClose();
        },
      }}
    >
      <form onSubmit={handleSubmit(handleCreate)} onReset={onClose}>
        <DialogTitle>{`${turnback ? 'Edit' : 'Add'} Turnback`}</DialogTitle>
        <DialogContent>
          {error && <ErrorAlert error={error} sx={{ marginBottom: 2 }} />}
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField autoFocus margin="dense" id="name" label="Turnback name" type="text" fullWidth {...field} />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button type="reset">{'Cancel'}</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={createTurnbackStatus.isLoading || updateTurnbackStatus.isLoading}
          >
            {turnback ? 'Update' : 'Add'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const EditTurnbackDialog = React.memo(EditTurnbackDialogComponent);
