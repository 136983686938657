import { Turnback, TurnbackSchema } from '../entities/Turnback';
import { TAG_TYPES, api } from './baseApi';

const url = 'turnbacks';

const turnbackApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readTurnbackList: builder.query<Turnback[], void>({
      query: () => ({ url }),
      transformResponse: (data) => TurnbackSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.TURNBACK, id: `LIST` }],
    }),
    createTurnback: builder.mutation<void, Omit<Turnback, 'id'>>({
      query: (body) => ({ url: `${url}/`, method: 'POST', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.TURNBACK, id: 'LIST' }],
    }),
    updateTurnback: builder.mutation<void, Turnback>({
      query: (body) => ({ url: `${url}/${body.id}`, method: 'PUT', body }),
      invalidatesTags: () => [{ type: TAG_TYPES.TURNBACK, id: 'LIST' }],
    }),
    deleteTurnback: builder.mutation<void, { turnbackId: Turnback['id'] }>({
      query: ({ turnbackId }) => ({ url: `${url}/${turnbackId}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.TURNBACK, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadTurnbackListQuery,
  useCreateTurnbackMutation,
  useUpdateTurnbackMutation,
  useDeleteTurnbackMutation,
} = turnbackApi;
