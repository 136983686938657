import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Process } from '../../entities/Process';
import { Scenario } from '../../entities/Scenario';
import { useReadScenarioListQuery } from '../../services/scenarioApi';
import { ScenarioLabel } from '../ScenarioLabel';

type ScenarioAutocompleteProps = Omit<
  AutocompleteProps<Omit<Scenario, 'processId'>, false, false, false>,
  'options' | 'renderInput'
> &
  Pick<TextFieldProps, 'error' | 'helperText' | 'label'> & { process?: Process };

function ScenarioAutocompleteComponent(props: ScenarioAutocompleteProps, ref: React.Ref<HTMLDivElement>) {
  const { disabled, error, helperText, label, process, ...autocompleteProps } = props;
  const scenarioList = useReadScenarioListQuery();

  const apiError = scenarioList.error as ServerError | undefined;

  const options = useMemo(() => {
    return [...(scenarioList.data ?? [])]
      .filter((scenario) => scenario.process.id === process?.id)
      .sort((a, b) => (a.name === 'OTHER' ? 1 : b.name === 'OTHER' ? -1 : a.name.localeCompare(b.name)));
  }, [process?.id, scenarioList.data]);

  return (
    <Autocomplete
      disabled={disabled || scenarioList?.data?.length === 0 || scenarioList.isError || scenarioList.isFetching}
      {...autocompleteProps}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error || Boolean(apiError)}
          label={label ?? 'Scenario'}
          helperText={helperText || apiError?.data?.message || apiError?.message}
          disabled={!process || params.disabled}
          ref={ref}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <ScenarioLabel scenario={option} />
        </Box>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

export const ScenarioAutocomplete = forwardRef(ScenarioAutocompleteComponent);
