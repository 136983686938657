import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { User, GuestUserFormSchema, GuestUserForm, UserRoleName } from '../../../entities/User';
import { createEmptyTimetable } from '../../../entities/UserTimetable';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserTimetableMutation,
} from '../../../services/userApi';

type UserEditGuestDialogProps = {
  initialValues?: User;
  onClose: () => void;
};

export function UserEditGuestDialog(props: UserEditGuestDialogProps): JSX.Element {
  const { initialValues, onClose } = props;
  const { control, handleSubmit } = useForm<GuestUserForm>({
    defaultValues: { ...initialValues },
    resolver: zodResolver(GuestUserFormSchema),
  });

  const [create, createStatus] = useCreateUserMutation();
  const [update, updateStatus] = useUpdateUserMutation();
  const [updateTimetable, updateTimetableStatus] = useUpdateUserTimetableMutation();

  const isLoading = createStatus.isLoading || updateStatus.isLoading || updateTimetableStatus.isLoading;
  const error = createStatus.error ?? updateStatus.error ?? updateTimetableStatus.error;

  const onSubmit = async (data: GuestUserForm) => {
    if (props.initialValues) {
      await update({
        ...props.initialValues,
        ...data,
      }).unwrap();
    } else {
      const response = await create({
        ...data,
        role: UserRoleName.GUEST,
      }).unwrap();
      await updateTimetable({ timetable: createEmptyTimetable(), username: response.username }).unwrap();
    }

    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Grid container columnSpacing={2} paddingTop={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Email"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  sx={{ flex: 2 }}
                  {...field}
                  value={field.value ?? ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Last name"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  {...field}
                  value={field.value ?? ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="First name"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  {...field}
                  value={field.value ?? ''}
                />
              )}
            />
          </Grid>
        </Grid>
        {error && <ErrorAlert error={error} />}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => onClose()} sx={{ marginRight: 'auto' }}>
          Cancel
        </Button>
        <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
          {initialValues ? 'Edit' : 'Add'}
        </LoadingButton>
      </DialogActions>
    </form>
  );
}
