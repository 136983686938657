import { useMemo } from 'react';
import { FilterOperator, PagedRequestParams } from '@top-solution/microtecnica-utils';
import { differenceInDays, add, getDay, isSameDay, startOfDay, isPast, isAfter } from 'date-fns';
import { CompletedTask, GanttTask, Task, TaskStatus } from '../entities/Task';
import { User } from '../entities/User';
import { useReadTaskListQuery, useReadCompletedTaskListQuery, ReadTaskListParams } from '../services/taskApi';
import { useReadUserListQuery } from '../services/userApi';
import { useReadWorkingDayListQuery } from '../services/workingDayApi';
import { useTaskScheduling } from './useTaskScheduling';

const defaultPolling = 10 * 60 * 1000;

export type UseGanttTasksParams = {
  from: Date | null;
  to: Date | null;
  /**
   * Filter tasks by task owner. All filters are in OR.
   */
  owner?: User['username'] | null;
  /**
   * Filter tasks by task backup. All filters are in OR.
   */
  backup?: User['username'] | null;
  /**
   * Filter tasks by task reviewer. All filters are in OR.
   */
  reviewer?: User['username'] | null;
  /**
   * Filter tasks by username (owner, backup, or reviewer). All filters are in OR.
   */
  username?: User['username'] | null;
  /**
   * Skips all data fetching.
   */
  skip?: boolean;
};

type GanttTaskNotScheduled = GanttTask & {
  reason: string;
  day: string;
};

export function useGanttTasks(params: UseGanttTasksParams) {
  const users = useReadUserListQuery();

  const startOfDayFrom = useMemo(() => (params.from ? startOfDay(params.from) : null), [params.from]);
  const startOfDayTo = useMemo(() => (params.to ? startOfDay(params.to) : null), [params.to]);

  const workingDaysListApi = useReadWorkingDayListQuery([
    (params.from ?? new Date()).getFullYear(),
    params.to?.getFullYear() ?? 2030 + 1,
  ]);

  const user = useMemo(
    () => users.data?.find((u) => u.username === (params.username ?? params.backup ?? params.owner ?? params.reviewer)),
    [params.backup, params.owner, params.reviewer, params.username, users.data],
  );
  const { dayHasTask, isWorkingDay } = useTaskScheduling(
    (params.from ?? new Date()).getFullYear(),
    params.to?.getFullYear() ?? 2030 + 1,
  );

  const readTaskListQueryParams = useMemo(() => {
    const filters: PagedRequestParams['filters'] = [];

    if (startOfDayTo) {
      filters.push({
        field: 'createdDate',
        value: add(startOfDayTo, { days: 1 }).toISOString(),
        operator: FilterOperator.lessThan,
      });
      // Removed because: missing data on queries ending in a different year from the start year
      // if (startOfDayFrom) {
      //   const minMonth = Math.min(startOfDayFrom.getMonth(), startOfDayTo.getMonth());
      //   const maxMonth = Math.min(startOfDayFrom.getMonth(), startOfDayTo.getMonth());
      //   filters.push({
      //     field: 'month',
      //     value: `${Math.pow(2, Math.max(minMonth - 1, 0))}`,
      //     operator: FilterOperator.greaterOrEqualThan,
      //   });
      //   filters.push({
      //     field: 'month',
      //     value: `${Math.pow(2, Math.min(maxMonth + 1, 12))}`,
      //     operator: FilterOperator.lessOrEqualThan,
      //   });
      // }
    }

    const readTaskListQuery: ReadTaskListParams = {
      filters,
      offset: 0,
      limit: 0,
      // hasUpdatedTask: true,
      excludeDeleted: startOfDayFrom ? add(startOfDayFrom, { months: -1 }).toISOString() : undefined,
    };

    return readTaskListQuery;
  }, [startOfDayFrom, startOfDayTo]);

  const readTaskListQuery = useReadTaskListQuery(readTaskListQueryParams, {
    skip: !params.from || !params.to || params.skip,
    pollingInterval: defaultPolling,
  });

  const readCompletedTaskListQueryParams = useMemo(() => {
    return {
      filters: params.username
        ? [
            {
              field: 'ownerUsername',
              value: params.username,
              operator: FilterOperator.equals,
            },
          ]
        : [],
      offset: 0,
      limit: 0,
    };
  }, [params.username]);

  const readCompletedTaskListQuery = useReadCompletedTaskListQuery(readCompletedTaskListQueryParams, {
    pollingInterval: defaultPolling,
  });

  const filteredTaskList = useMemo(() => {
    if (!readTaskListQuery.data) {
      return [];
    }

    if (!params.owner && !params.backup && !params.reviewer) {
      return readTaskListQuery.data;
    }

    let filteredTaskList = readTaskListQuery.data;

    filteredTaskList = filteredTaskList.filter((task) => {
      if (params.owner && task.owner?.username === params.owner) {
        return true;
      }
      if (params.backup && task.backup?.username === params.backup) {
        return true;
      }
      if (params.reviewer && task.reviewer?.username === params.reviewer) {
        return true;
      }
      return false;
    });

    return filteredTaskList;
  }, [params.backup, params.owner, params.reviewer, readTaskListQuery.data]);

  const completedTasksByTaskId = useMemo(() => {
    const completedTasksByTaskId: Record<Task['id'], CompletedTask[]> = {};
    for (const c of readCompletedTaskListQuery.data ?? []) {
      if (!completedTasksByTaskId[c.taskId]) {
        completedTasksByTaskId[c.taskId] = [];
      }

      completedTasksByTaskId[c.taskId].push(c);
    }

    return completedTasksByTaskId;
  }, [readCompletedTaskListQuery?.data]);

  const ganttTaskParams = useMemo(
    () => ({
      from: startOfDayFrom,
      to: startOfDayTo,
      filteredTaskList: filteredTaskList,
      completedTasksByTaskId: completedTasksByTaskId,
    }),
    [completedTasksByTaskId, filteredTaskList, startOfDayFrom, startOfDayTo],
  );

  const skip =
    readCompletedTaskListQuery.fulfilledTimeStamp === undefined ||
    readTaskListQuery.fulfilledTimeStamp === undefined ||
    users.fulfilledTimeStamp === undefined ||
    workingDaysListApi.fulfilledTimeStamp === undefined;

  const [ganttTasks, notScheduled]: [GanttTask[], GanttTaskNotScheduled[]] = useMemo(() => {
    // Do not calculate gantt tasks if related services have not finished loading
    if (skip) {
      return [[], []];
    }

    // eslint-disable-next-line no-console
    console.log('useGanttTasks: calculating tasks...');

    const gts: GanttTask[] = [];
    const notScheduled: GanttTaskNotScheduled[] = [];

    const isDebugLoggingEnabled = localStorage.getItem('enableDebugLogging');

    if (!ganttTaskParams.from || !ganttTaskParams.to || ganttTaskParams.filteredTaskList.length === 0) {
      return [gts, notScheduled];
    }

    const fromToDifference = differenceInDays(ganttTaskParams.to, ganttTaskParams.from) + 1;

    for (let i = 0; i < fromToDifference; i++) {
      const day = add(ganttTaskParams.from, { days: i });
      const dayGanttTasks: GanttTask[] = [];

      const dayTimetable = user?.timetable?.find((t) => {
        return t.day === getDay(day);
      });

      for (const task of ganttTaskParams.filteredTaskList) {
        const { hasTask, reason, overrideTask } = dayHasTask(task, day);

        // Exclude the task if it is not scheduled on the sample date
        if (!hasTask) {
          if (isDebugLoggingEnabled) {
            notScheduled.push({
              task,
              completedTask: undefined,
              date: day.toISOString(),
              status: TaskStatus.NotApplicable,
              reason: `dayHasTask returned false: ${reason}`,
              day: day.toISOString(),
            });
          }

          continue;
        }

        // The task scheduled date + scheduled time
        const dueDateTime = add(day, { seconds: task.dueHour ? task.dueHour : 0 });

        // Exclude the task if it was created after the sample date
        if (isAfter(new Date(task.createdDate), dueDateTime)) {
          if (isDebugLoggingEnabled) {
            notScheduled.push({
              task,
              completedTask: undefined,
              date: day.toISOString(),
              status: TaskStatus.NotApplicable,
              reason: `[${day.toISOString()}] the task was created after this date (created on ${task.createdDate})`,
              day: day.toISOString(),
            });
          }

          continue;
        }

        // Exclude the task if it was deleted before the sample date
        if (task.deletedDate && isAfter(dueDateTime, new Date(task.deletedDate))) {
          if (isDebugLoggingEnabled) {
            notScheduled.push({
              task,
              completedTask: undefined,
              date: day.toISOString(),
              status: TaskStatus.NotApplicable,
              reason: `[${day.toISOString()}] the task was deleted before this date (deleted on ${task.deletedDate})`,
              day: day.toISOString(),
            });
          }

          continue;
        }

        // Find a completed task for the sample date
        let completedTask;

        // If there is an override task, use the completed task for the override for the current day
        // Using this, the task for any specific day will be considered completed regardless
        // of the presence of an override
        const idForCompletedTask = overrideTask ? overrideTask.id : task.id;

        const completedTasks = ganttTaskParams.completedTasksByTaskId[idForCompletedTask] ?? [];
        for (const c of completedTasks) {
          if (!c.scheduledDate) {
            continue;
          }
          if (!isSameDay(new Date(c.scheduledDate), day)) {
            continue;
          }
          completedTask = c;
        }

        // Assign a status on the GanttTask
        let taskStatus = TaskStatus.Open;
        if (completedTask) {
          taskStatus = completedTask.status ?? TaskStatus.Open;
        } else if (isPast(dueDateTime)) {
          taskStatus = TaskStatus.Overdue;
        }

        // Finally assemble the GanttTask
        const gt: GanttTask = {
          task: {
            ...task,
            ...overrideTask,
          },
          completedTask,
          date: dueDateTime.toISOString(),
          status: taskStatus,
          overrideTask,
        };

        gts.push(gt);
        dayGanttTasks.push(gt);
      }

      if (dayGanttTasks.length > 0) {
        if (dayTimetable && dayTimetable.startAfternoon > 0 && dayTimetable.endMorning > 0) {
          if (isWorkingDay(day)) {
            gts.push({
              date: day.toISOString(),
              task: {
                id: -1,
                description: 'Lunch break',
                dueHour: dayTimetable.startAfternoon,
                duration: dayTimetable.startAfternoon - dayTimetable.endMorning,
                createdDate: new Date(0).toISOString(),
              },
              lunchBreak: true,
              status: TaskStatus.Open,
            });
          }
        }
      }
    }

    return [gts, notScheduled];
  }, [
    skip,
    ganttTaskParams.from,
    ganttTaskParams.to,
    ganttTaskParams.filteredTaskList,
    ganttTaskParams.completedTasksByTaskId,
    user?.timetable,
    dayHasTask,
    isWorkingDay,
  ]);

  return useMemo(() => {
    const ret = {
      ganttTasks,
      notScheduled,
      error: readTaskListQuery.error ?? readCompletedTaskListQuery.error,
      isFetching: readTaskListQuery.isFetching || readCompletedTaskListQuery.isFetching,
      isLoading: readTaskListQuery.isLoading || readCompletedTaskListQuery.isLoading,
      fulfilledTimeStamp:
        readTaskListQuery.fulfilledTimeStamp && readCompletedTaskListQuery.fulfilledTimeStamp
          ? Math.max(readTaskListQuery.fulfilledTimeStamp, readCompletedTaskListQuery.fulfilledTimeStamp)
          : undefined,
      user,
      refetch: () => {
        readTaskListQuery.refetch();
        readCompletedTaskListQuery.refetch();
      },
    };

    if (localStorage.getItem('enableDebugLogging') === 'true') {
      // eslint-disable-next-line no-console
      console.debug('useGanttTasks', {
        ...ret,
        tasks: {
          scheduled: readTaskListQuery.data,
          notScheduled: notScheduled,
          completed: readCompletedTaskListQuery.data,
        },
      });
    }

    return ret;
  }, [ganttTasks, notScheduled, readTaskListQuery, readCompletedTaskListQuery, user]);
}
