import { ForwardedRef, forwardRef } from 'react';
import { createMdiIcon } from '@top-solution/microtecnica-mui';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import {
  mdiAccountGroup,
  mdiAccountHardHat,
  mdiAlphaDBoxOutline,
  mdiAlphaEBoxOutline,
  mdiAlphaUBoxOutline,
  mdiArrowRightThin,
  mdiArrowUDownLeft,
  mdiBugOutline,
  mdiCalendar,
  mdiCalendarToday,
  mdiChartAreaspline,
  mdiChartGantt,
  mdiCheck,
  mdiCheckCircleOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiExport,
  mdiFileDocumentArrowRightOutline,
  mdiImport,
  mdiInformationOutline,
  mdiListStatus,
  mdiMagnify,
  mdiMagnifyMinus,
  mdiMagnifyPlus,
  mdiMenu,
  mdiMenuDown,
  mdiNoteMultiple,
  mdiOpenInNew,
  mdiPencil,
  mdiPlus,
  mdiRefresh,
  mdiTimetable,
  mdiTrashCan,
  mdiViewDashboardVariant,
} from '@mdi/js';

import AccountPercentage from './account-percentage.svg?react';

const createCustomSvgIcon = (
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >,
) =>
  forwardRef(function CustomSvgIcon(props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>): JSX.Element {
    return <SvgIcon component={icon} inheritViewBox {...props} ref={ref} />;
  });

export const AccountGroupIcon = createMdiIcon(mdiAccountGroup);
export const AccountHardHatIcon = createMdiIcon(mdiAccountHardHat);
export const AlphaDBoxOutlineIcon = createMdiIcon(mdiAlphaDBoxOutline);
export const AlphaEBoxOutlineIcon = createMdiIcon(mdiAlphaEBoxOutline);
export const AlphaUBoxOutlineIcon = createMdiIcon(mdiAlphaUBoxOutline);
export const ArrowRightThinIcon = createMdiIcon(mdiArrowRightThin);
export const ArrowUDownLeftIcon = createMdiIcon(mdiArrowUDownLeft);
export const BugOutlineIcon = createMdiIcon(mdiBugOutline);
export const CalendarIcon = createMdiIcon(mdiCalendar);
export const CalendarTodayIcon = createMdiIcon(mdiCalendarToday);
export const ChartAreasplineIcon = createMdiIcon(mdiChartAreaspline);
export const ChartGanttIcon = createMdiIcon(mdiChartGantt);
export const CheckCircleOutlineIcon = createMdiIcon(mdiCheckCircleOutline);
export const CheckIcon = createMdiIcon(mdiCheck);
export const ChevronDownIcon = createMdiIcon(mdiChevronDown);
export const ChevronLeftIcon = createMdiIcon(mdiChevronLeft);
export const ChevronRightIcon = createMdiIcon(mdiChevronRight);
export const CloseIcon = createMdiIcon(mdiClose);
export const DeleteIcon = createMdiIcon(mdiTrashCan);
export const DuplicateIcon = createMdiIcon(mdiNoteMultiple);
export const EditIcon = createMdiIcon(mdiPencil);
export const ExportIcon = createMdiIcon(mdiExport);
export const FileDocumentArrowRightOutlineIcon = createMdiIcon(mdiFileDocumentArrowRightOutline);
export const ImportIcon = createMdiIcon(mdiImport);
export const InformationOutlineIcon = createMdiIcon(mdiInformationOutline);
export const ListStatusIcon = createMdiIcon(mdiListStatus);
export const MagnifyIcon = createMdiIcon(mdiMagnify);
export const MagnifyMinusIcon = createMdiIcon(mdiMagnifyMinus);
export const MagnifyPlusIcon = createMdiIcon(mdiMagnifyPlus);
export const OpenInNewIcon = createMdiIcon(mdiOpenInNew);
export const MenuDownIcon = createMdiIcon(mdiMenuDown);
export const MenuIcon = createMdiIcon(mdiMenu);
export const PlusIcon = createMdiIcon(mdiPlus);
export const RefreshIcon = createMdiIcon(mdiRefresh);
export const TimetableIcon = createMdiIcon(mdiTimetable);
export const ViewDashboardVariantIcon = createMdiIcon(mdiViewDashboardVariant);

export const AccountPercentageIcon = createCustomSvgIcon(AccountPercentage);
