import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Process } from '../../../entities/Process';
import { GanttTask } from '../../../entities/Task';
import { useGanttTasksStats } from '../../../hooks/useGanttTasksStats';
import { useReadProcessListQuery } from '../../../services/processApi';
import { TaskStatsCard, TaskStatsCardProps } from '../components/TasksStatsCard';
import { CardsGrid } from '../layout/CardsGrid';
import { OverviewInnerLayout } from '../layout/OverviewInnerLayout';
import { useOverviewGanttTasks } from '../OverviewGanttTasks';
import { useOverviewQueryParams } from '../overviewQueryParams';

interface ProcessTaskStatsCardProps extends Omit<TaskStatsCardProps, 'title' | 'stats'> {
  process: Process;
  ganttTasks: GanttTask[];
}

function ProcessTaskStatsCard(props: ProcessTaskStatsCardProps) {
  const { process, ganttTasks, ...taskStatsCardProps } = props;
  const params = useParams<{ scenarioName?: string }>();
  const { showEmptyCards, excludeFutureTasks } = useOverviewQueryParams();

  const ganttTasksStats = useGanttTasksStats(
    ganttTasks,
    {
      processId: process.id,
      scenarioName: params.scenarioName,
    },
    { excludeFutureTasks, ignoreOtherScenario: true },
  );

  if (!showEmptyCards) {
    if (ganttTasksStats.finance.total === 0 && ganttTasksStats.supplier.total === 0) {
      return null;
    }
  }
  return <TaskStatsCard title={process.name} stats={ganttTasksStats} {...taskStatsCardProps} />;
}

function ProcessesGridComponent() {
  const { searchParams } = useOverviewQueryParams();

  const processList = useReadProcessListQuery(undefined, { pollingInterval: 10000 });

  const sortedProcessList = useMemo(() => {
    return [...(processList.data ?? [])].sort((a, b) => {
      if (a.name === 'OTHER') {
        return 1;
      }
      if (b.name === 'OTHER') {
        return -1;
      }
      return a.name.localeCompare(b.name);
    });
  }, [processList]);

  const { filteredGanttTasks, error: ganttTasksError, isLoading: ganttTasksIsLoading } = useOverviewGanttTasks();

  return (
    <OverviewInnerLayout
      error={ganttTasksError ?? processList.error}
      inProgress={processList.isLoading || ganttTasksIsLoading}
    >
      <CardsGrid>
        {(ganttTasksIsLoading ? [] : sortedProcessList).map((process) => (
          <ProcessTaskStatsCard
            key={process.id}
            process={process}
            ganttTasks={filteredGanttTasks}
            detailPath={`${process.id}/subprocesses?${searchParams}`}
            taskDetailsPath={`${process.id}/tasks`}
          />
        ))}
      </CardsGrid>
    </OverviewInnerLayout>
  );
}

export const ProcessesGrid = React.memo(ProcessesGridComponent);
