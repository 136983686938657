import { z } from 'zod';
import { formatHour } from '../utils/date';

export const DEFAULT_START_MORNING_HOUR = 8; // 8:00
export const DEFAULT_END_AFTERNOON_HOUR = 17; // 17:00
const DEFAULT_START_AFTERNOON_HOUR = 14.5; // 14:30
const DEFAULT_END_MORNING_HOUR = 13.75; // 13:45

export const DayTimetableSchema = z
  .object({
    day: z.number(),
    startMorning: z.number(),
    endMorning: z.number(),
    startAfternoon: z.number(),
    endAfternoon: z.number(),
  })
  .superRefine((data, ctx) => {
    if (data.startMorning && !data.endMorning) {
      ctx.addIssue({
        path: ['endMorning'],
        code: z.ZodIssueCode.custom,
        message: 'Required',
      });
    } else if (data.startMorning && data.endMorning && data.endMorning <= data.startMorning) {
      ctx.addIssue({
        path: ['endMorning'],
        code: z.ZodIssueCode.custom,
        message: `Must be after ${formatHour(data.startMorning)}`,
      });
    }
    if (data.startAfternoon && !data.endAfternoon) {
      ctx.addIssue({
        path: ['endAfternoon'],
        code: z.ZodIssueCode.invalid_type,
        expected: z.ZodParsedType.number,
        received: z.ZodParsedType.undefined,
      });
    } else if (data.startAfternoon && data.endMorning && data.startAfternoon <= data.endMorning) {
      ctx.addIssue({
        path: ['startAfternoon'],
        code: z.ZodIssueCode.custom,
        message: `Must be after ${formatHour(data.endMorning)}`,
      });
    } else if (data.startAfternoon && data.endAfternoon && data.endAfternoon <= data.startAfternoon) {
      ctx.addIssue({
        path: ['endAfternoon'],
        code: z.ZodIssueCode.custom,
        message: `Must be after ${formatHour(data.startAfternoon)}`,
      });
    }
  });

export type DayTimetable = z.infer<typeof DayTimetableSchema>;
export type Timetable = DayTimetable[];

const weekdayTimetable: Omit<DayTimetable, 'day'> = {
  startMorning: DEFAULT_START_MORNING_HOUR * 60 * 60,
  endMorning: DEFAULT_END_MORNING_HOUR * 60 * 60,
  startAfternoon: DEFAULT_START_AFTERNOON_HOUR * 60 * 60,
  endAfternoon: DEFAULT_END_AFTERNOON_HOUR * 60 * 60,
};

const holidayTimetable = {
  startMorning: 0,
  endMorning: 0,
  startAfternoon: 0,
  endAfternoon: 0,
};

export const createEmptyTimetable = (): Timetable => {
  return [
    { day: 0, ...holidayTimetable },
    { day: 1, ...weekdayTimetable },
    { day: 2, ...weekdayTimetable },
    { day: 3, ...weekdayTimetable },
    { day: 4, ...weekdayTimetable },
    { day: 5, ...weekdayTimetable },
    { day: 6, ...holidayTimetable },
  ];
};
