import { memo } from 'react';
import { FieldError } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Title } from '../Title';

export const days = Array.from(new Array(31)).map((_, i) => i + 1);

interface DaysSelectorProps {
  value: number | null;
  onChange: (day: number) => void;
  error: FieldError | undefined;
}

function DaysSelectorComponent(props: DaysSelectorProps) {
  const { value, onChange, error } = props;

  return (
    <Stack direction="column">
      <Title gutterBottom={false} title={'Day:'}></Title>
      <Stack direction="row" alignItems="center" gap={1} flex="0 0 auto">
        <Typography>{'On day '}</Typography>
        <Autocomplete
          disablePortal
          options={days}
          value={value ?? 0}
          onChange={(e, value) => onChange(value)}
          renderInput={(params) => (
            <TextField {...params} placeholder="#" size="small" sx={{ input: { textAlign: 'right' } }} />
          )}
          getOptionLabel={(day) => (day === 0 ? '' : day.toString())}
          disableClearable
          sx={{
            width: '10ch',
            '.MuiAutocomplete-listbox': {
              maxHeight: 200,
            },
          }}
        />
        {/* <Typography component="span">{' day of month'}</Typography> */}
      </Stack>
      {error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </Stack>
  );
}

export const DaysSelector = memo(DaysSelectorComponent);
