import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { grey, blue, purple, green } from '@mui/material/colors';
import { UserRoleName } from '../entities/User';

interface UserRoleChipProps extends ChipProps {
  user: { role: UserRoleName };
}

function UserRoleChipComponent(props: UserRoleChipProps) {
  const { user, ...chipProps } = props;

  return (
    <Chip
      label={user.role}
      color="primary"
      {...chipProps}
      sx={{
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: '0.75em',
        lineHeight: '1.1em',
        color: user.role === UserRoleName.GUEST ? '#000' : undefined,
        backgroundColor: (theme) => {
          let backgroundColor = '';
          switch (user.role) {
            case UserRoleName.ADMIN:
              backgroundColor = purple[theme.palette.mode === 'dark' ? 400 : 600];
              break;
            case UserRoleName.MANAGER:
              backgroundColor = blue[theme.palette.mode === 'dark' ? 400 : 600];
              break;
            case UserRoleName.USER:
              backgroundColor = green[theme.palette.mode === 'dark' ? 400 : 600];
              break;
            case UserRoleName.GUEST:
              backgroundColor = grey[theme.palette.mode === 'dark' ? 800 : 200];
              break;
            case UserRoleName.DELETED:
              backgroundColor = grey[theme.palette.mode === 'dark' ? 800 : 200];
              break;
            default:
              break;
          }
          return backgroundColor;
        },
        ...chipProps.sx,
      }}
    />
  );
}

export const UserRoleChip = React.memo(UserRoleChipComponent);
