import { useCallback, useMemo } from 'react';
import { DeepPartial } from 'react-hook-form';
import { useReadAppConfigQuery, useUpdateAppConfigMutation } from '@top-solution/microtecnica-utils';
import { deepmerge } from 'deepmerge-ts';
import { GridColumnVisibilityModel, GridDensity } from '@mui/x-data-grid-premium';
import { PlannerConfig, PlannerConfigSchema, PlannerDatagridConfig } from '../entities/Config';

export function usePlannerConfig() {
  const readConfigQuery = useReadAppConfigQuery();
  const [updateAppConfig] = useUpdateAppConfigMutation();

  const config: PlannerConfig | undefined = useMemo(() => {
    if (!readConfigQuery.data) {
      return undefined;
    }

    const parsed = PlannerConfigSchema.safeParse(readConfigQuery.data);

    if (parsed.error) {
      // eslint-disable-next-line no-console
      console.warn('Config reset, ', readConfigQuery.data, parsed.error);
      updateAppConfig({});
      return {};
    }

    return parsed.data;
  }, [readConfigQuery.data, updateAppConfig]);

  const patchConfig = useCallback(
    (newConfig: DeepPartial<PlannerConfig>) => {
      if (!config) {
        return;
      }
      const mergedConfig = deepmerge(config, newConfig);
      updateAppConfig(mergedConfig as unknown as Record<string, unknown>);
    },
    [config, updateAppConfig],
  );

  return useMemo(
    () => ({
      isConfigLoaded: readConfigQuery.fulfilledTimeStamp !== undefined,
      config,
      patchConfig,
      datagridProps: (configUpdater: (datagridConfig: PlannerDatagridConfig) => PlannerConfig) => ({
        onDensityChange: (density: GridDensity) => patchConfig(configUpdater({ density })),
        onColumnVisibilityModelChange: (columnVisibilityModel: GridColumnVisibilityModel) =>
          patchConfig(configUpdater({ columnVisibilityModel })),
      }),
      datagridInitialState: (configGetter: (plannerConfig: PlannerConfig) => PlannerDatagridConfig | undefined) => {
        const datagridConfig = configGetter(config ?? {});
        return {
          density: (datagridConfig?.density as GridDensity) ?? undefined,
          columns: {
            columnVisibilityModel: datagridConfig?.columnVisibilityModel as GridColumnVisibilityModel,
          },
        };
      },
    }),
    [config, patchConfig, readConfigQuery.fulfilledTimeStamp],
  );
}
