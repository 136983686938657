import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useReadEscapeListQuery } from '../../services/escapeApi';

type EscapeSelectProps = Omit<TextFieldProps, 'select'> & {
  disableClearable?: boolean;
};

function EscapeSelectComponent(props: EscapeSelectProps, ref: React.Ref<unknown>) {
  const { value, disabled, error, helperText, label, disableClearable, ...textFieldProps } = props;
  const escapeList = useReadEscapeListQuery();

  const apiError = escapeList.error as ServerError | undefined;

  const list = useMemo(() => {
    const list: JSX.Element[] = [];
    if (!disableClearable || !escapeList.data?.length) {
      list.push(
        <MenuItem value="" key="EMPTY">
          &nbsp;
        </MenuItem>,
      );
    }
    if (escapeList.data?.length) {
      return list.concat(
        escapeList.data.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        )),
      );
    }
    return list;
  }, [escapeList.data, disableClearable]);

  return (
    <TextField
      select
      SelectProps={{ ref }}
      value={value && escapeList.data?.length ? value : ''}
      disabled={disabled || escapeList?.data?.length === 0 || escapeList.isError || escapeList.isFetching}
      error={error || Boolean(apiError)}
      label={label ?? 'Escape'}
      helperText={helperText || apiError?.data?.message || apiError?.message}
      {...textFieldProps}
    >
      {list}
    </TextField>
  );
}

export const EscapeSelect = forwardRef(EscapeSelectComponent);
