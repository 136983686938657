import { z } from 'zod';

export const EscapeSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export type Escape = z.infer<typeof EscapeSchema>;

export enum EscapeTypes {
  'Other' = 1,
  'Significant' = 2,
  'NA' = 3,
}
