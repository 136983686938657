import { Day } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import Stack from '@mui/material/Stack';
import { CalendarDay } from './CalendarDay';

const dayNames = Array.from(new Array(7)).map((_, i) =>
  enGB.localize
    ?.day(((i + 1) % 7) as Day)
    .substring(0, 1)
    .toUpperCase(),
);

export function DayNamesRow() {
  return (
    <Stack direction="row" justifyContent="center" gap={0.75} key="days">
      {Array.from(new Array(7)).map((_, i) => (
        <CalendarDay key={i} checked={false} opacity={0.8}>
          {dayNames[i]}
        </CalendarDay>
      ))}
    </Stack>
  );
}
