import React from 'react';
import Card, { CardProps } from '@mui/material/Card';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useRadioGroup } from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface RadioCardProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  cardProps?: CardProps;
  title: string;
  children?: CardProps['children'];
}

function RadioCardComponent(props: RadioCardProps) {
  const { cardProps, title, children, ...formControlLabelProps } = props;
  const radioGroup = useRadioGroup();

  const checked = radioGroup?.value === props.value;

  return (
    <Card
      {...cardProps}
      variant="outlined"
      sx={{
        padding: 1,
        borderColor: checked ? 'primary.main' : undefined,
        '&:hover': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
        },
        transition: (theme) => theme.transitions.create(['background-color']),
      }}
    >
      <FormControlLabel
        {...formControlLabelProps}
        control={<Radio disabled={props.disabled} />}
        label={
          <Stack direction="row" alignItems="flex-start" gap={1}>
            <Stack direction="column" flex={1}>
              <Typography typography="body1" fontWeight={500} lineHeight="42px">
                {title}
              </Typography>
              {children}
            </Stack>
          </Stack>
        }
        sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
      />
    </Card>
  );
}

export const RadioCard = React.memo(RadioCardComponent);
