import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Button, { ButtonProps } from '@mui/material/Button';
import { ArrowRightThinIcon } from './Icons';

function LinkButtonComponent(props: ButtonProps & LinkProps) {
  return (
    <Button
      variant="outlined"
      color="secondary"
      component={Link}
      endIcon={<ArrowRightThinIcon sx={{ fontSize: 16 }} />}
      size="small"
      {...props}
      sx={{ marginLeft: 1, minWidth: 'unset', borderRadius: '15px', paddingX: 2, ...props.sx }}
    >
      {props.children}
    </Button>
  );
}

export const LinkButton = React.memo(LinkButtonComponent);
