import {
  format,
  formatISO,
  parseISO,
  isValid,
  intervalToDuration,
  previousMonday,
  sub,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import { GridColDef } from '@mui/x-data-grid-premium';
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';

export function formatDate(date: Date, fmrt: string): string {
  return format(date, fmrt, { locale: enGB });
}

export function formatHour(secondsFromMidnight: number): string {
  const { hours, minutes } = intervalToDuration({ start: 0, end: Math.round(secondsFromMidnight) * 1000 });
  return `${String(hours ?? 0).padStart(2, '0')}:${String(minutes ?? 0).padStart(2, '0')}`;
}

export function formatDuration(seconds: number): string {
  const h = String(Math.floor(seconds / 60 / 60)).padStart(2, '0');
  const s = String(Math.floor((seconds / 60) % 60)).padStart(2, '0');
  return `${h}h ${s}m`;
}

export function formatDateHour(date: Date): string {
  return format(date, 'HH:mm');
}

export function formatISODate(date: Date): string {
  return formatISO(date, { representation: 'date' });
}

export function parseISODate(value: unknown): Date | null {
  if (typeof value === 'string') {
    const date = parseISO(value);
    if (isValid(date)) {
      return date;
    }
  }
  return null;
}

export function formatFromDate(date: Date | null): string {
  return formatISODate(date ?? previousMonday(new Date()));
}

export function formatToDate(date: Date | null): string {
  return formatISODate(date ?? new Date());
}

export const dateColumn: Partial<GridColDef> = {
  type: 'date',
  align: 'right',
  valueFormatter: (value: Date) => (value && value.valueOf() !== 0 ? formatDate(value, 'P') : '—'),
};

export const hourColumn: Partial<GridColDef> = {
  type: 'number',
  filterable: false,
  valueFormatter: (value: number) => (value ? formatHour(value) : '—'),
};

export const durationColumn: Partial<GridColDef> = {
  type: 'number',
  filterable: false,
  valueFormatter: (value: number) => (value ? formatHour(value) : '—'),
};

export const dateRangePickerShortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
  {
    label: 'Yesterday',
    getValue: () => {
      const yesterday = sub(new Date(), { days: 1 });
      return [yesterday, yesterday];
    },
  },
  {
    label: 'Today',
    getValue: () => {
      const today = new Date();
      return [today, today];
    },
  },
  {
    label: 'Tomorrow',
    getValue: () => {
      const tomorrow = sub(new Date(), { days: 1 });
      return [tomorrow, tomorrow];
    },
  },
  {
    label: 'Last 7 days',
    getValue: () => {
      const today = new Date();
      return [sub(today, { days: 7 }), sub(today, { days: 1 })];
    },
  },
  {
    label: 'Last month',
    getValue: () => {
      const today = new Date();
      const prevMonth = sub(startOfMonth(today), { months: 1 });
      return [prevMonth, endOfMonth(prevMonth)];
    },
  },
  {
    label: 'Month to date',
    getValue: () => {
      const today = new Date();
      return [startOfMonth(today), today];
    },
  },
  {
    label: 'Last year',
    getValue: () => {
      const today = new Date();
      return [startOfMonth(sub(today, { years: 1 })), today];
    },
  },
];

export const months = Array.from(new Array(12)).map((_, month) => month);
export const weekDays = Array.from(new Array(7)).map((_, weekDay) => weekDay);
export const occurrencies = Array.from(new Array(16))
  .map((_, occurrency) => occurrency)
  .filter((n) => n < 4 || n > 13);

export const weekStartsOn = { weekStartsOn: 1 as const };
