import React from 'react';
import { colord } from 'colord';
import Chip from '@mui/material/Chip';
import { Scenario } from '../entities/Scenario';

interface ScenarioChipProps {
  scenario: Pick<Scenario, 'name' | 'color'>;
}

function ScenarioChipComponent(props: ScenarioChipProps) {
  const { scenario } = props;
  return (
    <Chip
      label={scenario.name}
      sx={{
        fontWeight: 500,
        bgcolor: `#${scenario.color}`,
        color: colord(`#${scenario.color}`).isLight() ? '#000' : '#fff',
      }}
      size="small"
    />
  );
}

export const ScenarioChip = React.memo(ScenarioChipComponent);
