import { useMemo } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IntegerTextField } from '@top-solution/microtecnica-mui';
import { startOfToday, add, differenceInSeconds } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { TimePicker } from '@mui/x-date-pickers-pro';
import FormRow from '../../../../components/Form/FormRow';
import { EditingTask, TimeScheduleForm, TimeScheduleFormSchema } from '../../../../entities/Task';

type TimeSchedulingStepStepProps = Omit<DialogProps, 'onClose'> & {
  timeSchedule?: TimeScheduleForm;
  onClose: (timeSchedule: TimeScheduleForm) => void;
  onBack: () => void;
  task?: EditingTask;
  dialogActions: React.ReactNode;
};

const midnight = startOfToday();

export function TimeSchedulingStepStep(props: TimeSchedulingStepStepProps): JSX.Element {
  const { onClose, onBack, task, timeSchedule, dialogActions } = props;

  const initialValues = useMemo(() => {
    if (timeSchedule) {
      return {
        ...timeSchedule,
        isMachineTask: Boolean(timeSchedule.dueHourMachine),
      };
    }
    if (!task) {
      return {
        isMachineTask: false,
        duration: 0,
        durationMachine: 0,
      };
    }
    return {
      ...task,
      isMachineTask: false,
      duration: task.duration ?? 0,
      dueHourMachine: task.dueHourMachine ?? 0,
    };
  }, [task, timeSchedule]);

  const form = useForm<TimeScheduleForm>({
    defaultValues: initialValues,
    resolver: zodResolver(TimeScheduleFormSchema),
  });
  const { handleSubmit, control, watch } = form;
  const isMachineTask = watch('isMachineTask');

  const onSubmit = async (data: TimeScheduleForm) => {
    onClose(data);
  };

  return (
    <FormProvider {...form}>
      <Stack component="form" direction="column" onSubmit={handleSubmit(onSubmit)} flex={1}>
        <DialogContent>
          <FormRow sx={{ marginTop: 1 }}>
            <Controller
              control={control}
              name="dueHour"
              render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                <TimePicker
                  label="Due Hour"
                  value={value && value > 0 ? add(midnight, { seconds: value }) : null}
                  onChange={(value) => onChange(value ? differenceInSeconds(value, midnight) : undefined)}
                  slotProps={{
                    textField: {
                      error: Boolean(error),
                      helperText: error?.message,
                      fullWidth: true,
                      ...field,
                    },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="duration"
              render={({ field, fieldState: { error } }) => (
                <IntegerTextField
                  options={{ minimumFractionDigits: 0 }}
                  label="Duration manual (minutes)"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  min={0}
                  {...field}
                  value={field.value / 60}
                  onChange={(_, value) => field.onChange((value as number) * 60)}
                />
              )}
            />
          </FormRow>
          <FormRow sx={{ marginTop: 1 }}>
            <Controller
              control={control}
              name="isMachineTask"
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  sx={{ minWidth: 1 / 3, marginX: 0, alignSelf: 'baseline', paddingX: 1 }}
                  control={<Checkbox checked={value} {...field} />}
                  label="Machine Task"
                />
              )}
            />

            <Controller
              control={control}
              name="dueHourMachine"
              render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
                <TimePicker
                  label="Due Hour Machine"
                  value={value && value > 0 ? add(midnight, { seconds: value }) : null}
                  onChange={(value) => onChange(value ? differenceInSeconds(value, midnight) : undefined)}
                  disabled={!isMachineTask}
                  slotProps={{
                    textField: {
                      error: Boolean(error),
                      helperText: error?.message,
                      fullWidth: true,
                      ...field,
                    },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="durationMachine"
              render={({ field, fieldState: { error } }) => (
                <IntegerTextField
                  options={{ minimumFractionDigits: 0 }}
                  label="Duration machine (minutes)"
                  error={Boolean(error)}
                  helperText={error?.message || ' '}
                  fullWidth
                  min={0}
                  disabled={!isMachineTask}
                  {...field}
                  value={field.value ?? null}
                  onChange={(_, value) => field.onChange(value as number)}
                />
              )}
            />
          </FormRow>
        </DialogContent>
        <DialogActions>
          {dialogActions}
          <Box sx={{ flex: 1, height: 1 }} />
          <Button color="inherit" variant="outlined" onClick={() => onBack()}>
            Back
          </Button>
          <LoadingButton type="submit" color="primary" variant="contained">
            {'Next'}
          </LoadingButton>
        </DialogActions>
      </Stack>
    </FormProvider>
  );
}
