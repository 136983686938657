import { ReactNode } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import {
  AccountGroupIcon,
  AccountHardHatIcon,
  AccountPercentageIcon,
  ArrowUDownLeftIcon,
  CalendarIcon,
  ChartAreasplineIcon,
  ChartGanttIcon,
  ListStatusIcon,
  ViewDashboardVariantIcon,
} from '../components/Icons';
import { UserRoleName } from '../entities/User';

type Section = {
  url: string;
  icon: ReactNode;
  title: string;
};

export function useSectionList(): { adminSectionList: Section[]; userSectionList: Section[] } {
  const { isAdmin, hasRole, username } = useAuth();
  const isManager = hasRole(UserRoleName.MANAGER);

  const userSectionList: Section[] = [
    {
      url: '/overview',
      title: 'Overview',
      icon: <ViewDashboardVariantIcon />,
    },
    {
      url: '/gantt',
      title: 'Task Gantt',
      icon: <ChartGanttIcon />,
    },
  ];
  if (!isAdmin) {
    userSectionList.push({
      url: '/tasks',
      title: 'My tasks',
      icon: <ListStatusIcon />,
    });
  }
  if (isAdmin || username === 'monitor-fmrp') {
    userSectionList.push({
      url: '/people-load',
      title: 'People Load',
      icon: <AccountPercentageIcon />,
    });
  }
  userSectionList.push({
    url: '/performance',
    title: 'Performance',
    icon: <ChartAreasplineIcon />,
  });

  const managerSections = [
    {
      url: '/admin/processes',
      title: 'Manage Processes',
      icon: <CalendarIcon />,
    },
  ];

  const adminSectionList: Section[] = isAdmin
    ? [
        ...managerSections,
        {
          url: '/admin/working-days',
          title: 'Manage Working Days',
          icon: <AccountHardHatIcon />,
        },
        {
          url: '/admin/users',
          title: 'Manage Users',
          icon: <AccountGroupIcon />,
        },

        {
          url: '/tasks',
          title: 'Manage Tasks',
          icon: <ListStatusIcon />,
        },
        {
          url: '/admin/turnbacks',
          title: 'Manage Turnbacks',
          icon: <ArrowUDownLeftIcon />,
        },
      ]
    : isManager
      ? managerSections
      : [];

  return {
    adminSectionList,
    userSectionList,
  };
}
