import { useEffect, useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { GridButton, GridButtonGroup } from '../../../components/GridButtonGroup';
import { User, UserRoleName } from '../../../entities/User';
import { UserEditAdminForm } from './UserEditAdminForm';
import { UserEditGuestDialog } from './UserEditGuestDialog';

type UserEditDialogProps = Omit<DialogProps, 'onClose'> & {
  title: string;
  initialValues?: User;
  onClose: () => void;
};

const emptyUser: DefaultValues<User> = {
  email: '',
  firstName: '',
  lastName: '',
};

export function UserEditDialog(props: UserEditDialogProps): JSX.Element {
  const { title, initialValues, onClose, ...dialogProps } = props;

  const [role, setRole] = useState<UserRoleName | undefined>(initialValues?.role);

  const [activeStep, setActiveStep] = useState(initialValues ? 1 : 0);

  useEffect(() => {
    setActiveStep(initialValues ? 1 : 0);
    setRole(initialValues?.role);
  }, [initialValues]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        onExited: () => {
          setRole(undefined);
          setActiveStep(0);
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0, marginTop: 1, overflow: 'hidden' }}>
        <Stepper nonLinear activeStep={activeStep}>
          <Step>
            <StepLabel>{'User Role'}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{'User data'}</StepLabel>
          </Step>
        </Stepper>
      </DialogContent>
      {activeStep === 0 ? (
        <>
          <DialogContent>
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" paddingY={4}>
                  <GridButtonGroup wrapAfter={4} sx={{ minWidth: 512 }}>
                    <GridButton
                      checked={role === UserRoleName.ADMIN}
                      onClick={() => setRole(UserRoleName.ADMIN)}
                      disabled={Boolean(initialValues)}
                    >
                      {UserRoleName.ADMIN}
                    </GridButton>
                    <GridButton
                      checked={role === UserRoleName.MANAGER}
                      onClick={() => setRole(UserRoleName.MANAGER)}
                      disabled={Boolean(initialValues)}
                    >
                      {UserRoleName.MANAGER}
                    </GridButton>
                    <GridButton
                      checked={role === UserRoleName.USER}
                      onClick={() => setRole(UserRoleName.USER)}
                      disabled={Boolean(initialValues)}
                    >
                      {UserRoleName.USER}
                    </GridButton>
                    <GridButton
                      checked={role === UserRoleName.GUEST}
                      onClick={() => setRole(UserRoleName.GUEST)}
                      disabled={Boolean(initialValues)}
                    >
                      {UserRoleName.GUEST}
                    </GridButton>
                  </GridButtonGroup>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={() => onClose()} sx={{ marginRight: 'auto' }}>
              Cancel
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => setActiveStep(1)}
              disabled={role === undefined}
            >
              Next
            </Button>
          </DialogActions>
        </>
      ) : role === UserRoleName.GUEST ? (
        <UserEditGuestDialog initialValues={initialValues} onClose={onClose} />
      ) : role ? (
        <Grid item xs={12} sm={6}>
          <UserEditAdminForm initialValues={initialValues ?? emptyUser} role={role} onClose={onClose} />
        </Grid>
      ) : (
        <>
          <pre>{JSON.stringify({ initialValues }, null, 2)}</pre>
        </>
      )}
    </Dialog>
  );
}
