import { useMemo } from 'react';
import { UserRole } from '../entities/User';
import { useReadUserRoleListQuery } from '../services/userApi';

type UseUserRoleList = ReturnType<typeof useReadUserRoleListQuery> & {
  map?: Map<UserRole['id'], UserRole>;
  data?: UserRole[];
};

export function useUserRoleList(): UseUserRoleList {
  const userRoleList = useReadUserRoleListQuery();

  const map = useMemo(
    () => userRoleList.data?.reduce((map, role) => map.set(role.id, role), new Map<UserRole['id'], UserRole>()),
    [userRoleList.data],
  );

  return { ...userRoleList, map };
}
