import { useMemo } from 'react';
import { add, getDay, isSameDay, sub } from 'date-fns';
import { Task, TaskOverride, TaskSchedule } from '../entities/Task';
import { hasDay, hasMonth, hasWeekDay } from '../utils/scheduling';
import { DaysInMonthMap, IsWorkingDay, WorkingDaysMap, useWorkingDays } from './useWorkingDays';

// type IsWorkingDay = (date: Date) => boolean;
type YearOccurrences = Record<number, Record<number, Record<number, number>>>;

type Context = {
  isWorkingDay: IsWorkingDay;
  daysInMonth: DaysInMonthMap;
  yearOccurrences: YearOccurrences;
  yearNegativeOccurrences: YearOccurrences;
  workingDays: WorkingDaysMap;
};

function checkYearlySchedule(schedule: TaskSchedule, date: Date, isWorkingDay: IsWorkingDay): DayHasTaskReturn {
  if (!hasMonth(date, schedule.month)) {
    return { hasTask: false, reason: `month does not match` };
  }

  // // Use something like this ↓ to debug
  // if (date.getDate() === 1 && date.getMonth() === 11) {
  //   debugger;
  // }

  if (!isWorkingDay(date)) {
    return { hasTask: false, reason: `day (${date.toISOString()}) is not a working day` };
  }

  if (hasDay(date, schedule.day)) {
    return { hasTask: true };
  }

  if (schedule.shift === -1) {
    let nextWorkingDay = add(date, { days: 1 });
    for (let i = 1; isWorkingDay(nextWorkingDay) === false; i++) {
      if (hasMonth(nextWorkingDay, schedule.month) && hasDay(nextWorkingDay, schedule.day)) {
        return { hasTask: true };
      }
      nextWorkingDay = add(date, { days: i });
    }
  }

  if (schedule.shift === 1) {
    let previousWorkingDay = sub(date, { days: 1 });
    for (let i = 1; isWorkingDay(previousWorkingDay) === false; i++) {
      if (hasMonth(previousWorkingDay, schedule.month) && hasDay(previousWorkingDay, schedule.day)) {
        return { hasTask: true };
      }
      previousWorkingDay = sub(date, { days: i });
    }
  }

  return { hasTask: false, reason: 'neither day nor shifts do match' };
}

function checkWorkDaySchedule(
  schedule: TaskSchedule,
  date: Date,
  isWorkingDay: IsWorkingDay,
  daysInMonth: DaysInMonthMap,
  workingDays: WorkingDaysMap,
): DayHasTaskReturn {
  if (!hasMonth(date, schedule.month)) {
    return { hasTask: false, reason: `month does not match` };
  }

  if (!schedule.workDay) {
    return { hasTask: false, reason: `workday (${schedule.workDay}) is missing from schedule` };
  }

  if (!isWorkingDay(date)) {
    return { hasTask: false, reason: `day (${date.toISOString()}) is not a working day` };
  }

  const year = date.getFullYear();
  const month = date.getMonth();

  // All the working days of the month
  const monthWorkingDays = [...workingDays[year][month]];

  // The nth working day of the month
  const workDayDate =
    schedule.workDay > 0
      ? monthWorkingDays[schedule.workDay - 1]
      : monthWorkingDays[monthWorkingDays.length + schedule.workDay];

  // Check this is the nth working day of the month
  if (date.getDate() !== workDayDate) {
    return { hasTask: false, reason: `day (${date.getDate()}) does not match with the working day (${workDayDate})` };
  }

  return { hasTask: true };
}

function checkOccurrenceSchedule(schedule: TaskSchedule, date: Date, context: Context): DayHasTaskReturn {
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1;
  const dateDay = date.getDate();

  if (!hasMonth(date, schedule.month)) {
    return { hasTask: false, reason: `month does not match` };
  }

  if (!hasWeekDay(date, schedule.weekDay)) {
    return { hasTask: false, reason: `week day does not match` };
  }

  const occurrence = context.yearOccurrences[dateYear]?.[dateMonth]?.[dateDay];
  const negativeOccurrence = context.yearNegativeOccurrences[dateYear]?.[dateMonth]?.[dateDay];
  if (
    (schedule.occurrence & Math.pow(2, occurrence - 1)) !== 0 ||
    (schedule.occurrence & Math.pow(2, 16 + negativeOccurrence)) !== 0
  ) {
    return { hasTask: true };
  }
  return { hasTask: false, reason: `occurrence does not match` };
}

type DayHasTaskReturn = { hasTask: boolean; reason?: string; overrideTask?: TaskOverride };

function hasTask(schedule: TaskSchedule, from: Date, until: Date, context: Context): DayHasTaskReturn {
  return dayHasTask(schedule, from, context);
}

function dayHasTask(schedule: TaskSchedule, day: Date, context: Context): DayHasTaskReturn {
  if (schedule.day > 0) {
    return checkYearlySchedule(schedule, day, context.isWorkingDay);
  }
  if (schedule.workDay) {
    return checkWorkDaySchedule(schedule, day, context.isWorkingDay, context.daysInMonth, context.workingDays);
  }
  if (schedule.occurrence) {
    return checkOccurrenceSchedule(schedule, day, context);
  }
  return {
    hasTask: false,
    reason: `schedule has neither positive day (${schedule.day}) nor workday (${schedule.workDay}) nor occurrence (${schedule.occurrence})`,
  };
}

export function useTaskScheduling(startYear: number, endYear: number) {
  const { isWorkingDay, daysInMonth, workingDays } = useWorkingDays([startYear, endYear]);

  const [yearOccurrences, yearNegativeOccurrences]: [YearOccurrences, YearOccurrences] = useMemo(() => {
    const yearOccurrences: YearOccurrences = {};
    const yearNegativeOccurrences: YearOccurrences = {};

    for (let year = startYear; year <= endYear; year++) {
      yearOccurrences[year] = {};
      yearNegativeOccurrences[year] = {};

      for (let m = 0; m < 12; m++) {
        yearOccurrences[year][m + 1] = {};
        yearNegativeOccurrences[year][m + 1] = {};

        const weekDaysMap: Record<number, number> = {};
        for (let i = 0; i < 7; i++) {
          weekDaysMap[i] = 1;
        }
        for (let d = 1; d <= daysInMonth[year]?.[m]; d++) {
          const weekDay = getDay(new Date(year, m, d));

          yearOccurrences[year][m + 1][d] = weekDaysMap[weekDay];
          weekDaysMap[weekDay] = weekDaysMap[weekDay] + 1;
        }

        const weekDaysNegativeMap: Record<number, number> = {};
        for (let i = 0; i < 7; i++) {
          weekDaysNegativeMap[i] = -1;
        }
        for (let d = daysInMonth[year]?.[m] ?? 1; d >= 1; d--) {
          const weekDay = getDay(new Date(year, m, d));

          yearNegativeOccurrences[year][m + 1][d] = weekDaysNegativeMap[weekDay];
          weekDaysNegativeMap[weekDay] = weekDaysNegativeMap[weekDay] - 1;
        }
      }
    }

    return [yearOccurrences, yearNegativeOccurrences];
  }, [daysInMonth, endYear, startYear]);

  return useMemo(() => {
    return {
      hasTask: (schedule: TaskSchedule, from: Date, until: Date) =>
        hasTask(schedule, from, until, {
          isWorkingDay,
          yearOccurrences,
          yearNegativeOccurrences,
          daysInMonth,
          workingDays,
        }),
      dayHasTask: (task: Pick<Task, 'schedule' | 'overrides' | 'fatherId'>, day: Date) => {
        const hasTask = dayHasTask(task.schedule, day, {
          isWorkingDay,
          yearOccurrences,
          yearNegativeOccurrences,
          daysInMonth,
          workingDays,
        });

        if (task.overrides?.length) {
          for (let i = 0; i < task.overrides.length; i++) {
            const override = task.overrides[i];

            // The task should be scheduled for the current day, but there is an exception
            if (hasTask.hasTask && isSameDay(day, override.overrideOriginalDay)) {
              if (isSameDay(override.overrideDay, override.overrideOriginalDay)) {
                return {
                  hasTask: true,
                  reason: `Override, but the override is on the same day`,
                  overrideTask: override,
                };
              }
              return { hasTask: false, reason: `Cancelled by override, scheduled on ${override.overrideDay}` };
            }

            // The task is not scheduled for the current day, the exception is
            if (!hasTask.hasTask && isSameDay(day, override.overrideDay)) {
              return {
                hasTask: true,
                reason: `Override, was task #${override.fatherId} originally scheduled on day ${override.overrideOriginalDay}`,
                overrideTask: override,
              };
            }
          }
        }

        return hasTask;
      },
      isWorkingDay: isWorkingDay,
      workingDays: workingDays,
    };
  }, [daysInMonth, isWorkingDay, workingDays, yearNegativeOccurrences, yearOccurrences]);
}
