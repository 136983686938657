import { useMemo } from 'react';
import { Scenario } from '../entities/Scenario';
import { useReadScenarioListQuery } from '../services/scenarioApi';

export function useScenarioNames() {
  const { data: scenarioList } = useReadScenarioListQuery();

  return useMemo(() => {
    const scenarioNames = new Set<string>();
    const scenarioMap = new Map<string, Scenario[]>();

    if (scenarioList) {
      for (const scenario of scenarioList) {
        scenarioNames.add(scenario.name);
        const scenarioNameArray = scenarioMap.get(scenario.name) ?? [];
        scenarioNameArray.push(scenario);
        scenarioMap.set(scenario.name, scenarioNameArray);
      }
    }

    return {
      scenarioNames: Array.from(scenarioNames).sort((a, b) => {
        if (a === 'OTHER') {
          return 1;
        }
        if (b === 'OTHER') {
          return -1;
        }
        return a.localeCompare(b);
      }),
      scenarioMap: scenarioMap,
    };
  }, [scenarioList]);
}
