import { capitalize } from '@mui/material/utils';
import { GridColDef } from '@mui/x-data-grid-premium';
import { UserRoleChip } from '../components/UserRoleChip';
import { UserRole } from '../entities/User';
import { useUserRoleList } from './useUserRole';

export function useUserRoleColDef(): GridColDef {
  const { data, map } = useUserRoleList();

  return {
    field: 'role',
    headerName: 'Role',
    type: 'singleSelect',
    width: 90,
    align: 'center',
    headerAlign: 'center',
    valueOptions: data?.map((role) => ({ value: role.name, label: capitalize(role.name) })) ?? [],
    valueFormatter: (value: UserRole['id']) => map?.get(value)?.name ?? value,
    renderCell: ({ row }) => <UserRoleChip user={row} size="small" />,
  };
}
