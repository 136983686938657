import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { User } from '../../../entities/User';
import { GanttTasksUserStats } from '../../../hooks/useGanttTasksStats';
import { DEFAULT_TARGET, formatPercentage } from '../../../utils/valueHelpers';

function printShortUserName(user: Omit<User, 'role'> | null | undefined): string {
  if (!user) {
    return '—';
  }

  if (user?.lastName) {
    return `${user.lastName} ${user.firstName ? `${user.firstName[0]}.` : ''}`;
  }

  if ('username' in user && user.username) {
    return user.username;
  }

  return `${user.id}`;
}
interface UserStatsButtonProps {
  user: User;
  stats: GanttTasksUserStats;
  to: string;
}

export function UserStatsButton(props: UserStatsButtonProps) {
  const { user, stats, to } = props;
  const percent = stats.completion / stats.total;
  return (
    <Button
      component={Link}
      to={to}
      variant="contained"
      color={percent < DEFAULT_TARGET ? 'error' : 'success'}
      sx={{
        display: 'block',
        '& .MuiDivider-root': {
          borderColor: (theme) => alpha(theme.palette.background.default, 0.12),
        },
        maxWidth: (theme) => theme.breakpoints.values.sm,
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography flex={1} textAlign="left" textTransform="none">
          {printShortUserName(user)}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Stack
          direction="column"
          justifyContent="center"
          textAlign="center"
          fontSize={9}
          fontWeight="bold"
          minWidth="3ch"
          gap={0.25}
        >
          <div>{stats.completion}</div>
          <Divider flexItem />
          <div>{stats.total}</div>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Typography width="6ch" fontWeight="bold" textAlign="right">
          {formatPercentage(percent, 1)}
        </Typography>
      </Stack>
    </Button>
  );
}

type UserStatsButtonListProps = {
  title: string;
  children: React.ReactNode[];
};

export function UserStatsButtonList(props: UserStatsButtonListProps): JSX.Element {
  const { title } = props;
  return (
    <Box
      sx={{
        marginX: 'auto',
        marginBottom: 2,
        maxWidth: 'lg',
        width: '100%',
      }}
    >
      <Typography variant="h6" component="h2" gutterBottom>
        {title}:
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          width: '100%',
          gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
