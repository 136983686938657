import Chip, { ChipProps } from '@mui/material/Chip';
import { formatPercentage } from '../../utils/valueHelpers';

export function StatChip(props: ChipProps & { metricValue: number }) {
  const { metricValue, ...chipProps } = props;
  if (chipProps.disabled) {
    return <>{'—'}</>;
  }

  return (
    <Chip
      size="small"
      clickable
      {...chipProps}
      label={formatPercentage(metricValue ?? 0)}
      color={(metricValue ?? 0) > 1.1 ? 'error' : (metricValue ?? 0) < 0.8 ? 'warning' : 'success'}
      sx={{ fontWeight: 'bold', fontSize: 'inherit', ...props.sx }}
    />
  );
}
