import React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatPercentage } from '../../utils/valueHelpers';

function GanttTitleComponent(props: {
  title: string;
  onTime: number | undefined;
  completion: number | undefined;
  children: React.ReactNode;
}) {
  return (
    <Stack direction="row" alignItems="center" marginBottom={1}>
      <Typography variant="h5" lineHeight="1.8" sx={{ marginRight: 2 }}>
        {props.title}
      </Typography>
      <Paper variant="outlined" sx={{ paddingX: 1.5, paddingY: 1, width: 300 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="baseline" gap={2} flex={1}>
          <Typography component="div" typography="body1" fontWeight="bold" whiteSpace="nowrap" flex={1}>
            {'On time:'}
            <Typography
              component="span"
              typography="body1"
              fontWeight="bold"
              color={props.onTime === 1 || props.onTime === undefined ? undefined : 'error.main'}
              sx={{ marginLeft: 1 }}
            >
              {props.onTime === undefined ? '—' : formatPercentage(props.onTime)}
            </Typography>
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography component="div" typography="body1" fontWeight="bold" flex={1}>
            Completion:
            <Typography
              component="span"
              typography="body1"
              fontWeight="bold"
              color={props.completion === 1 || props.completion === undefined ? undefined : 'error.main'}
              sx={{ marginLeft: 1 }}
            >
              {props.completion === undefined ? '—' : formatPercentage(props.completion)}
            </Typography>
          </Typography>
        </Stack>
      </Paper>
      {/* <Box sx={{ marginRight: { xs: 0, md: 'auto' } }} /> */}
      {props.children}
    </Stack>
  );
}

export const GanttTitle = React.memo(GanttTitleComponent);
