import { Control, Controller, FieldError, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { startOfToday, add, differenceInSeconds, Day } from 'date-fns';
import { enGB } from 'date-fns/locale/en-GB';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';
import { Timetable, createEmptyTimetable } from '../../../entities/UserTimetable';
import { sortedDaysOfWeek } from '../Task/TaskEditDialog/ScheduleForm/utils';

const midnight = startOfToday();

interface TimePickerInputProps extends Omit<TimePickerProps<Date>, 'value' | 'onChange'> {
  value: number | null;
  onChange: (value: number | null) => void;
  error?: FieldError;
}

function TimePickerInput({ value, onChange, error, ...props }: TimePickerInputProps): JSX.Element {
  return (
    <TimePicker
      value={value && value > 0 ? add(midnight, { seconds: value }) : null}
      onChange={(value) => onChange(value ? differenceInSeconds(value, midnight) : null)}
      slotProps={{
        textField: {
          size: 'small',
          error: Boolean(error),
          helperText: error?.message,
        },
      }}
      {...props}
    />
  );
}

type WorkingHoursFormHandles = {
  control: Control<{ timetable: Timetable }>;
  watch: UseFormWatch<{ timetable: Timetable }>;
  setValue: UseFormSetValue<{ timetable: Timetable }>;
};

type WorkingHoursRowProps = WorkingHoursFormHandles & {
  dayOfWeek: number;
};

function WorkingHoursRow(props: WorkingHoursRowProps): JSX.Element {
  const { dayOfWeek, control, watch, setValue } = props;

  const morningEnabled = Boolean(
    watch('timetable')[dayOfWeek].startMorning && watch('timetable')[dayOfWeek].endMorning,
  );

  const afternoonEnabled = Boolean(
    watch('timetable')[dayOfWeek].startAfternoon && watch('timetable')[dayOfWeek].endAfternoon,
  );

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
      <TableCell variant="head">{enGB.localize?.day(dayOfWeek as Day) ?? dayOfWeek}</TableCell>
      <TableCell align="center">
        <Checkbox
          checked={morningEnabled}
          onChange={(e) => {
            if (e.target.checked) {
              const defaultTimetable = createEmptyTimetable();
              setValue(`timetable.${dayOfWeek}.startMorning`, defaultTimetable[1].startMorning);
              setValue(`timetable.${dayOfWeek}.endMorning`, defaultTimetable[1].endMorning);
            } else {
              setValue(`timetable.${dayOfWeek}.startMorning`, 0);
              setValue(`timetable.${dayOfWeek}.endMorning`, 0);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`timetable.${dayOfWeek}.startMorning`}
          render={({ field, fieldState }) => (
            <TimePickerInput
              {...field}
              value={field.value ?? 0}
              onChange={(value) => value && field.onChange(value)}
              error={fieldState.error}
              disabled={field.value === 0}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`timetable.${dayOfWeek}.endMorning`}
          render={({ field, fieldState }) => (
            <TimePickerInput
              {...field}
              value={field.value ?? 0}
              onChange={(value) => value && field.onChange(value)}
              error={fieldState.error}
              disabled={field.value === 0}
            />
          )}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={afternoonEnabled}
          onChange={(e) => {
            if (e.target.checked) {
              const defaultTimetable = createEmptyTimetable();
              setValue(`timetable.${dayOfWeek}.startAfternoon`, defaultTimetable[1].startAfternoon);
              setValue(`timetable.${dayOfWeek}.endAfternoon`, defaultTimetable[1].endAfternoon);
            } else {
              setValue(`timetable.${dayOfWeek}.startAfternoon`, 0);
              setValue(`timetable.${dayOfWeek}.endAfternoon`, 0);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`timetable.${dayOfWeek}.startAfternoon`}
          render={({ field, fieldState }) => (
            <TimePickerInput
              {...field}
              value={field.value ?? 0}
              onChange={(value) => value && field.onChange(value)}
              error={fieldState.error}
              disabled={field.value === 0}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`timetable.${dayOfWeek}.endAfternoon`}
          render={({ field, fieldState }) => (
            <TimePickerInput
              {...field}
              value={field.value ?? 0}
              onChange={(value) => value && field.onChange(value)}
              error={fieldState.error}
              disabled={field.value === 0}
            />
          )}
        />
      </TableCell>
    </TableRow>
  );
}

export function WorkingHoursEditor(props: WorkingHoursFormHandles): JSX.Element {
  return (
    <Controller
      control={props.control}
      name="timetable"
      render={() => (
        <TableContainer component={Paper} variant="outlined">
          <Table
            size="small"
            sx={{
              '& th': {
                textAlign: 'center',
              },
              '& td:nth-of-type(1), & td:nth-of-type(4), .separator': {
                borderRight: 1,
                borderColor: 'divider',
              },
              '& td:nth-of-type(1)': {
                lineHeight: '28px',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="separator"></TableCell>
                <TableCell colSpan={3} align="center" className="separator">
                  Morning
                </TableCell>
                <TableCell colSpan={3} align="center">
                  Afternoon
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell className="separator"></TableCell>
                <TableCell>Working</TableCell>
                <TableCell>Start</TableCell>
                <TableCell className="separator">End</TableCell>
                <TableCell>Working</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '.MuiTableCell-root': { verticalAlign: 'top', paddingX: 0.5 },
                '.MuiTableCell-head': { paddingTop: 1.5 },
              }}
            >
              {sortedDaysOfWeek.map(({ index }) => <WorkingHoursRow key={index} dayOfWeek={index} {...props} />) ?? []}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    />
  );
}
