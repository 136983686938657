import { User, UserRole, UserRoleSchema, UserSchema } from '../entities/User';
import { Timetable } from '../entities/UserTimetable';
import { api, TAG_TYPES } from './baseApi';

const url = 'users';

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readUserList: builder.query<User[], void>({
      query: () => ({ url }),
      transformResponse: (data) => UserSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.USER, id: 'LIST' }],
    }),
    createUser: builder.mutation<User, Pick<User, 'email' | 'firstName' | 'lastName' | 'role'>>({
      query: (user) => ({
        url,
        method: 'POST',
        body: user,
      }),
      transformResponse: (data) => UserSchema.parse(data),
      // After creating a user, it is very likely that updateUserTimetable is called immediately after.
      // Invalidation here is empty to let updateUserTimetable invalidete the user list
      invalidatesTags: () => [],
    }),
    updateUser: builder.mutation<void, User>({
      query: (user) => ({
        url: `${url}/${user.username}`,
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.USER, id: 'LIST' }],
    }),
    removeUser: builder.mutation<void, User>({
      query: (user) => ({ url: `${url}/${user.username}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: TAG_TYPES.USER, id: 'LIST' }],
    }),
    updateUserTimetable: builder.mutation<void, { username: string; timetable: Timetable }>({
      query: ({ username, timetable }) => ({
        url: `${url}/${username}/timetable`,
        method: 'PUT',
        body: { timetable },
      }),
      invalidatesTags: () => [{ type: TAG_TYPES.USER, id: 'LIST' }],
    }),
    readUserRoleList: builder.query<UserRole[], void>({
      query: () => ({ url: 'roles' }),
      transformResponse: (data) => UserRoleSchema.array().parse(data),
      providesTags: () => [{ type: TAG_TYPES.USER_ROLE, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadUserListQuery,
  useReadUserRoleListQuery,
  useCreateUserMutation,
  useRemoveUserMutation,
  useUpdateUserMutation,
  useUpdateUserTimetableMutation,
} = userApi;
