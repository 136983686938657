import { memo, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DataGridWrapper } from '@top-solution/microtecnica-mui';
import Stack from '@mui/material/Stack';
import {
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { StyledDataGrid } from '../../../components/StyledDataGrid';
import { GanttTask } from '../../../entities/Task';
import { useAreaColDef } from '../../../hooks/useAreaColDef';
import { useCategoryColDef } from '../../../hooks/useCategoryColDef';
import { useDepartmentColDef } from '../../../hooks/useDepartmentColDef';
import { useEscapeColDef } from '../../../hooks/useEscapeColDef';
import { usePlannerConfig } from '../../../hooks/usePlannerConfig';
import { useProcessColDef } from '../../../hooks/useProcessColDef';
import { useScenarioColDef } from '../../../hooks/useScenarioColDef';
import { useScheduleColDefs } from '../../../hooks/useScheduleColDefs';
import { useSubprocessColDef } from '../../../hooks/useSubprocessColDef';
import { useTaskStatusColDef } from '../../../hooks/useTaskStatusColDef';
import { useTurnbackColDef } from '../../../hooks/useTurnbackColDef';
import { useUsersColDefs } from '../../../hooks/useUsersColDefs';
import { useReadScenarioListQuery } from '../../../services/scenarioApi';
import { stripedGetRowClassName } from '../../../utils/datagrid';
import { dateColumn, durationColumn, hourColumn } from '../../../utils/date';
import { OverviewInnerLayout } from '../layout/OverviewInnerLayout';
import { useOverviewGanttTasks } from '../OverviewGanttTasks';

function TaskDescriptionCell(props: { ganttTask: GanttTask }) {
  const { ganttTask } = props;
  const apiRef = useGridApiContext();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        alignItems: 'center',
        gap: 1,
        lineHeight: 1.5,
        textAlign: 'left',
        justifyContent: 'flex-start',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingY: 0.75,
        ...(apiRef.current.state.density === 'compact'
          ? {
              display: 'block',
            }
          : {
              whiteSpace: 'break-spaces',
              display: '-webkit-box',
              ['-webkit-box-orient']: 'vertical',
              ['-webkit-line-clamp']: apiRef.current.state.density === 'standard' ? '2' : '3',
            }),
      }}
    >
      {ganttTask.task.description}
    </Stack>
  );
}

function OverviewTasksDatagridComponent(): JSX.Element {
  const processColumn = useProcessColDef();
  const categoryColumn = useCategoryColDef();
  const scenarioColumn = useScenarioColDef();
  const areaColumn = useAreaColDef();
  const departmentColumn = useDepartmentColDef();
  const subprocessColumn = useSubprocessColDef();
  const turnbackColumn = useTurnbackColDef();
  const escapeColumn = useEscapeColDef();
  const taskStatusColumn = useTaskStatusColDef();
  const { ownerColumn, backupColumn, reviewerColumn } = useUsersColDefs<GanttTask>();
  const { monthColumn, dayColumn, occurrenceColumn, weekDayColumn, workDayColumn } = useScheduleColDefs();
  const params = useParams<{
    processId?: string;
    scenarioName?: string;
    subprocessId?: string;
    ownerUserName?: string;
    escapeNot?: string;
  }>();

  const scenarioList = useReadScenarioListQuery();

  const [query] = useSearchParams();
  const { datagridProps, datagridInitialState } = usePlannerConfig();

  const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>({ items: [] });
  const [qsFiltersApplied, setQsFiltersApplied] = useState(false);

  useEffect(() => {
    const filterModelItems: GridFilterItem[] = [];

    if (params.processId !== undefined) {
      filterModelItems.push({
        id: 'process',
        field: 'process',
        value: Number(params.processId),
        operator: 'is',
      });
    }

    if (query.has('status')) {
      filterModelItems.push({
        id: 'status',
        field: 'status',
        value: query.get('status'),
        operator: 'is',
      });
    }

    if (query.has('escapeNot')) {
      query
        .get('escapeNot')
        ?.split(',')
        .forEach((value) => {
          filterModelItems.push({
            id: `escapeTypeId-${value}`,
            field: 'escapeTypeId',
            value: Number(value),
            operator: 'not',
          });
        });
    }

    if (params.scenarioName !== undefined) {
      const scenario = (scenarioList.data ?? []).find((s) => s.name === params.scenarioName);

      if (scenario) {
        filterModelItems.push({
          id: 'scenario',
          field: 'scenario',
          value: scenario?.name,
          operator: 'is',
        });
      }
    }

    if (params.subprocessId !== undefined) {
      filterModelItems.push({
        id: 'subprocess',
        field: 'subprocess',
        value: Number(params.subprocessId),
        operator: 'is',
      });
    }

    if (params.ownerUserName !== undefined) {
      filterModelItems.push({
        id: 'owner',
        field: 'owner',
        value: params.ownerUserName,
        operator: 'contains',
      });
    }

    setQsFiltersApplied(true);
    setGridFilterModel({ items: filterModelItems });
  }, [
    params.escapeNot,
    params.ownerUserName,
    params.processId,
    params.scenarioName,
    params.subprocessId,
    query,
    scenarioList,
  ]);

  const { ganttTasks, error: ganttTasksError, isLoading: ganttTasksIsLoading } = useOverviewGanttTasks();

  const columns: GridColDef[] = [
    {
      field: 'taskId',
      headerName: 'Task ID',
      width: 90,
      valueGetter: (value, row: GanttTask) => row.task.id,
    },
    {
      field: 'description',
      headerName: 'Description',
      valueGetter: (value, row: GanttTask) => row.task.description,
      type: 'string',
      width: 300,
      cellClassName: 'description-cell',
      renderCell: ({ row }: GridRenderCellParams<GanttTask, string>) => <TaskDescriptionCell ganttTask={row} />,
    },
    {
      ...taskStatusColumn,
      valueGetter: (value, row: GanttTask) => row.status,
    },
    ownerColumn((row) => row.task.owner),
    backupColumn((row) => row.task.backup),
    reviewerColumn((row) => row.task.reviewer),
    workDayColumn,
    {
      ...dateColumn,
      field: 'dueDate',
      headerName: 'Due date',
      valueGetter: (value, row: GanttTask) => new Date(row.date),
    },
    { ...turnbackColumn, width: 200 },
    { ...escapeColumn, width: 200 },
    {
      field: 'note',
      headerName: 'Note',
      valueGetter: (value, row: GanttTask) => row.completedTask?.note,
      width: 300,
    },
    {
      ...backupColumn((row) => row.completedTask?.closedBy),
      field: 'complete',
      headerName: 'Completed By',
    },
    {
      ...dateColumn,
      field: 'closeDate',
      headerName: 'Completed Date',
      width: 120,
      valueGetter: (value, row: GanttTask) =>
        row.completedTask?.closedDate ? new Date(row.completedTask.closedDate) : new Date(0),
    },
    {
      ...hourColumn,
      field: 'closeTime',
      headerName: 'Completed Hour',
      valueGetter: (value, row: GanttTask) =>
        row.completedTask?.closedDate ? new Date(row.completedTask.closedDate) : null,
      width: 120,
    },
    //
    // Hidden by default and always on the right of the visible columns
    //
    { ...processColumn },
    { ...scenarioColumn },
    { ...categoryColumn },
    { ...areaColumn },
    { ...departmentColumn },
    { ...subprocessColumn },
    monthColumn,
    dayColumn,
    occurrenceColumn,
    weekDayColumn,
    {
      field: 'entryNumber',
      headerName: 'Journal Entry',
      type: 'number',
      valueGetter: (value, row: GanttTask) =>
        row.task.entryNumber && row.task.entryNumber > 0 ? row.task.entryNumber : undefined,
    },
    {
      field: 'entryType',
      headerName: 'Entry Type',
      width: 90,
      valueGetter: (value, row: GanttTask) => row.task.entryType,
    },
    {
      field: 'reversal',
      headerName: 'Reversal',
      valueGetter: (value, row: GanttTask) => row.task.reversal,
    },
    {
      ...hourColumn,
      field: 'dueHour',
      headerName: 'Due hour',
      valueGetter: (value, row: GanttTask) => row.task.dueHour,
    },
    {
      ...durationColumn,
      field: 'duration',
      headerName: 'Activity duration (min)',
      valueGetter: (value, row: GanttTask) => row.task.duration,
    },
    {
      ...hourColumn,
      field: 'dueHourMachine',
      headerName: 'Machine due hour',
    },
    {
      ...durationColumn,
      field: 'durationMachine',
      headerName: 'Machine activity duration (min)',
      valueGetter: (value, row: GanttTask) => row.task.durationMachine,
    },
  ];

  const ganttTasksWithId = useMemo(() => {
    // In Overview, all tasks with scenario Other must to be ignored in task stats
    // So it should make sense to replacate the same logic here
    return ganttTasks
      .map((ganttTask) => ({
        ...ganttTask,
        id: `${ganttTask.task.id}__${ganttTask.date}`,
      }))
      .filter((t) => t.task.scenario !== undefined);
  }, [ganttTasks]);

  const initialState = datagridInitialState((config) => config.pages?.overview?.datagrid);

  return (
    <OverviewInnerLayout
      error={ganttTasksError}
      inProgress={ganttTasksIsLoading || scenarioList.isLoading || !qsFiltersApplied}
    >
      <DataGridWrapper
        sx={{
          '.MuiDataGrid-cell .MuiChip-root': {
            marginX: 0.15,
          },
        }}
      >
        <StyledDataGrid
          sessionStorageId="overview-task-grid"
          columns={columns}
          rows={ganttTasksWithId}
          initialState={{
            ...initialState,
            filter: {
              filterModel: gridFilterModel,
            },
            columns: {
              columnVisibilityModel: initialState.columns.columnVisibilityModel ?? {
                process: false,
                scenario: false,
                category: false,
                area: false,
                department: false,
                subprocess: false,
                day: false,
                months: false,
                occurrence: false,
                weekDay: false,
                entryNumber: false,
                entryType: false,
                reversal: false,
                dueHour: false,
                duration: false,
                dueHourMachine: false,
                durationMachine: false,
              },
            },
          }}
          loading={ganttTasksIsLoading}
          disableAggregation
          disableRowGrouping
          filterModel={gridFilterModel}
          onFilterModelChange={setGridFilterModel}
          getRowClassName={stripedGetRowClassName}
          paginationMode="client"
          disableRowSelectionOnClick={false}
          {...datagridProps((datagridConfig) => ({
            pages: { overview: { datagrid: datagridConfig } },
          }))}
        />
      </DataGridWrapper>
    </OverviewInnerLayout>
  );
}

export const OverviewTasksDatagrid = memo(OverviewTasksDatagridComponent);
