import { useMemo } from 'react';
import { GanttTask } from '../../entities/Task';
import { GanttTasksStats, classifyTask } from '../../hooks/useGanttTasksStats';

export function usePerformanceTasksStats(fromDate: Date, toDate: Date, ganttTasks: GanttTask[]) {
  const tasksStatsByMonth = useMemo(() => {
    const ganttTasksByMonth: Record<number, GanttTask[]> = {};

    for (const ganttTask of ganttTasks) {
      const month = new Date(ganttTask.date).getMonth();
      if (!ganttTasksByMonth[month]) {
        ganttTasksByMonth[month] = [];
      }
      ganttTasksByMonth[month].push(ganttTask);
    }

    const tasksStatsByMonth: Record<number, GanttTasksStats> = {};
    for (let i = 0; i < 12; i++) {
      const ganttTasks = ganttTasksByMonth[i];
      const tasksStats: GanttTasksStats = {
        cardColor: 'success',
        finance: {
          total: 0,
          onTime: 0,
          completion: 0,
          escapeCount: 0,
        },
        supplier: {
          total: 0,
          onTime: 0,
          completion: 0,
          escapeCount: 0,
        },
        count: {
          overdue: [],
          open: [],
          notApplicable: [],
          onTime: [],
          late: [],
          total: [],
        },
        byUser: {},
      };

      if (ganttTasks) {
        for (const ganttTask of ganttTasks) {
          classifyTask(ganttTask, tasksStats, { excludeFutureTasks: false, ignoreOtherScenario: false });
        }
      }

      if (tasksStats.count.overdue.length > 0) {
        tasksStats.cardColor = 'error';
      }

      tasksStatsByMonth[i] = tasksStats;
    }

    return tasksStatsByMonth;
  }, [ganttTasks]);

  return useMemo(
    () => ({
      fromDate,
      toDate,
      tasksStatsByMonth,
    }),
    [fromDate, tasksStatsByMonth, toDate],
  );
}
