import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { EditingTask, TaskConfigurationForm, TaskSchedule, TimeScheduleForm } from '../../../../entities/Task';
import { ScheduleFormComponent } from './ScheduleForm/ScheduleForm';
import { TaskConfigurationStep } from './TaskConfiguration/TaskConfigurationStep';
import { TimeSchedulingStepStep } from './TimeSchedulingStep';

type TaskEditDialogProps = Omit<DialogProps, 'onClose'> & {
  task?: EditingTask | undefined;
  onClose: (value?: TaskConfigurationForm) => void;
  editMode?: boolean;
};

export function TaskEditDialog(props: TaskEditDialogProps): JSX.Element {
  const { onClose, task, ...dialogProps } = props;

  const [dateSchedule, setDateSchedule] = useState<TaskSchedule | null>((task?.schedule as TaskSchedule) ?? null);
  const [timeSchedule, setTimeSchedule] = useState<TimeScheduleForm | null>(
    task
      ? ({
          dueHour: task?.dueHour,
          duration: task?.duration,
          dueHourMachine: task?.dueHourMachine,
          durationMachine: task?.durationMachine,
        } as TimeScheduleForm)
      : null,
  );

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const title = task?.id ? `Edit task` : 'Add Task';

  const closeButton = (
    <Button color="inherit" onClick={() => onClose()}>
      Cancel
    </Button>
  );

  return (
    <Dialog maxWidth="xl" fullWidth {...dialogProps} sx={{ minHeight: 786 }}>
      <DialogTitle sx={{ paddingBottom: 1 }}>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 1, flex: '0 0 auto', minHeight: 24 }}>
        {task && (
          <Typography variant="body2" color="text.secondary" sx={{ paddingTop: 0, paddingBottom: 2 }}>
            {task.description}
          </Typography>
        )}
        {task ? (
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            {'Edited tasks will still remain visible after the update'}
          </Alert>
        ) : null}
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{'Day scheduling'}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{'Time scheduling'}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{'Task configuration'}</StepLabel>
          </Step>
        </Stepper>
      </DialogContent>
      <Stack direction="column" justifyContent="space-between" flex={1} sx={{ paddingX: 1 }}>
        {activeStep === 0 ? (
          <ScheduleFormComponent
            initialValues={dateSchedule}
            onSubmit={(data) => {
              setDateSchedule(data as unknown as TaskSchedule);
              handleNext();
            }}
            onClose={onClose}
            dialogActions={closeButton}
          />
        ) : activeStep === 1 ? (
          <>
            {dateSchedule ? (
              <TimeSchedulingStepStep
                open={false}
                task={task}
                onBack={handleBack}
                timeSchedule={timeSchedule ?? undefined}
                onClose={(timeSchedule) => {
                  setTimeSchedule(timeSchedule);
                  handleNext();
                }}
                dialogActions={closeButton}
              />
            ) : null}
          </>
        ) : (
          <>
            {dateSchedule && timeSchedule ? (
              <TaskConfigurationStep
                open={false}
                dateSchedule={dateSchedule}
                timeSchedule={timeSchedule}
                task={task}
                onBack={handleBack}
                onClose={onClose}
                dialogActions={closeButton}
              />
            ) : null}
          </>
        )}
      </Stack>
    </Dialog>
  );
}
