import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef } from '@mui/x-data-grid-premium';
import { FileDocumentArrowRightOutlineIcon, OpenInNewIcon } from '../components/Icons';

export function useDocumentUrlColDef(): GridColDef {
  return {
    field: 'documentUrl',
    headerName: 'Document link',
    width: 72,
    groupable: false,
    valueGetter: (value, row) => row.documentUrl,
    renderCell: ({ value }) =>
      value ? (
        <Tooltip
          title={
            <Stack direction="row" gap="0.5em" alignItems="center">
              {'Document link'}
              <OpenInNewIcon fontSize="inherit" sx={{ position: 'relative', bottom: '0.1em' }} />
            </Stack>
          }
        >
          <Button
            size="small"
            LinkComponent="a"
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ minWidth: 52 }}
          >
            <FileDocumentArrowRightOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
      ) : null,
  };
}
