import React, { useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { PlusIcon } from '../../../components/Icons';
import { TaskConfigurationForm } from '../../../entities/Task';
import { TaskEditDialog } from './TaskEditDialog/TaskEditDialog';

const emptyProcessTask: DefaultValues<TaskConfigurationForm> = {
  description: '',
};

function TaskAddButtonComponent(props: IconButtonProps): JSX.Element {
  const { ...buttonProps } = props;
  const [initialValues, setInitialValues] = useState<DefaultValues<TaskConfigurationForm> | null>(null);
  return (
    <>
      <Tooltip title={'Add task'}>
        <IconButton color="primary" onClick={() => setInitialValues({ ...emptyProcessTask })} {...buttonProps}>
          <PlusIcon />
        </IconButton>
      </Tooltip>
      {initialValues && <TaskEditDialog open={Boolean(initialValues)} onClose={() => setInitialValues(null)} />}
    </>
  );
}

export const TaskAddButton = React.memo(TaskAddButtonComponent);
