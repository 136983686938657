import { forwardRef, useMemo } from 'react';
import { ServerError } from '@top-solution/microtecnica-utils';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Category } from '../../entities/Category';
import { Process } from '../../entities/Process';
import { useReadCategoryListQuery } from '../../services/categoryApi';

type CategoryAutocompleteProps = Omit<
  AutocompleteProps<Omit<Category, 'processId'>, false, false, false>,
  'options' | 'renderInput'
> &
  Pick<TextFieldProps, 'error' | 'helperText' | 'label'> & { process?: Process };

function CategoryAutocompleteComponent(props: CategoryAutocompleteProps, ref: React.Ref<HTMLDivElement>) {
  const { disabled, error, helperText, label, process, ...autocompleteProps } = props;
  const categoryList = useReadCategoryListQuery();

  const apiError = categoryList.error as ServerError | undefined;

  const options = useMemo(() => {
    return [...(categoryList.data ?? [])]
      .filter((category) => category.process.id === process?.id)
      .sort((a, b) => (a.name === 'OTHER' ? 1 : b.name === 'OTHER' ? -1 : a.name.localeCompare(b.name)));
  }, [process?.id, categoryList.data]);

  return (
    <Autocomplete
      disabled={disabled || categoryList?.data?.length === 0 || categoryList.isError || categoryList.isFetching}
      {...autocompleteProps}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error || Boolean(apiError)}
          label={label ?? 'Category'}
          helperText={helperText || apiError?.data?.message || apiError?.message}
          disabled={!process || params.disabled}
          ref={ref}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

export const CategoryAutocomplete = forwardRef(CategoryAutocompleteComponent);
