import { useId, useMemo, useState } from 'react';
import { DataGrid } from '@top-solution/microtecnica-mui';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { DefinitionList } from '../components/DefinitionList';
import { AlphaDBoxOutlineIcon, AlphaEBoxOutlineIcon, AlphaUBoxOutlineIcon, CloseIcon } from '../components/Icons';
import { Percent } from '../components/Value';
import { GanttTask, Task } from '../entities/Task';
import { useReadTurnbackListQuery } from '../services/turnbackApi';
import { formatDate, formatDateHour, formatHour, months, occurrencies, parseISODate, weekDays } from '../utils/date';
import { useScheduleColDefs } from './useScheduleColDefs';

function TaskDescriptionCell(props: { ganttTask?: GanttTask }) {
  const { ganttTask } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showContent, setShowContent] = useState(false);
  const scheduleColDefs = useScheduleColDefs();
  const id = useId();

  const apiRef = useGridApiContext();

  const { data: turnbackList } = useReadTurnbackListQuery();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowContent(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!ganttTask) {
    return '—';
  }

  const closeDate = parseISODate(ganttTask.completedTask?.closedDate);
  const turnbackId = ganttTask.completedTask?.turnbackId;

  if (ganttTask.lunchBreak) {
    return 'Lunch break';
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ width: '100%', height: '100%', padding: 0, alignItems: 'center', gap: 1 }}
    >
      <Button
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        size="small"
        fullWidth
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
          textTransform: 'none',
          fontWeight: 400,
          lineHeight: 1.5,
          textAlign: 'left',
          justifyContent: 'flex-start',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          borderRadius: 0,
          height: '100%',
          paddingX: 1.5,
          ...(apiRef.current.state.density === 'compact'
            ? {
                display: 'block',
              }
            : {
                whiteSpace: 'break-spaces',
                display: '-webkit-box',
                ['-webkit-box-orient']: 'vertical',
                ['-webkit-line-clamp']: apiRef.current.state.density === 'standard' ? '2' : '3',
              }),
        }}
      >
        {ganttTask.task.description}
      </Button>
      {ganttTask.task.hasUpdatedTask ? (
        <Tooltip title="This task was updated">
          <Box sx={{ height: '1.4em', '& svg': { display: 'block' } }}>
            <AlphaUBoxOutlineIcon fontSize="small" />
          </Box>
        </Tooltip>
      ) : ganttTask.task.deletedDate ? (
        <Tooltip title="This task was deleted">
          <Box sx={{ height: '1.4em', '& svg': { display: 'block' } }}>
            <AlphaDBoxOutlineIcon fontSize="small" />
          </Box>
        </Tooltip>
      ) : null}
      {ganttTask.overrideTask ? (
        <Tooltip title="This is an exception to a planned task">
          <Box sx={{ height: '1.4em', '& svg': { display: 'block' } }}>
            <AlphaEBoxOutlineIcon fontSize="small" />
          </Box>
        </Tooltip>
      ) : null}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        TransitionProps={{
          onExited: () => setShowContent(false),
        }}
      >
        {showContent && (
          <Box sx={{ padding: 2, maxWidth: '50vw', overflow: 'auto', minWidth: 630 }}>
            <Stack direction="row" justifyContent="space-between" alignItems={'flex-start'} width="100%">
              <Typography variant="h6">{ganttTask.task.description}</Typography>
              <IconButton size="small" color="inherit" onClick={() => setAnchorEl(null)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 1 }}>
              <DefinitionList>
                <Typography component="dt">{'Task ID'}</Typography>
                <Typography component="dd">{ganttTask.task.id}</Typography>
                <Typography component="dt">{'Due on'}</Typography>
                <Typography component="dd">
                  <span>{formatDate(new Date(ganttTask.date), 'P')}</span>
                  <Typography fontWeight="normal">{' at '}</Typography>
                  <span>{formatHour(ganttTask.overrideTask?.dueHour ?? ganttTask.task.dueHour ?? 0)}</span>
                </Typography>
                <Typography component="dt">{'Closed Task ID'}</Typography>
                <Typography component="dd">{ganttTask.completedTask ? ganttTask.completedTask.id : '-'}</Typography>
                {closeDate ? (
                  <>
                    <Typography component="dt">{'Completed on '}</Typography>
                    <Typography component="dd">
                      <span>{formatDate(closeDate, 'P')}</span>
                      <Typography fontWeight="normal">{' at '}</Typography>
                      <span>{formatDateHour(closeDate)}</span>
                    </Typography>
                  </>
                ) : null}
              </DefinitionList>
            </Paper>
            {turnbackId ? (
              <>
                <Typography fontWeight={500} gutterBottom sx={{ marginTop: 2 }}>
                  {'Turnback'}
                </Typography>
                {turnbackList?.find((t) => t.id === turnbackId)?.name}
              </>
            ) : null}
            <Typography fontWeight={500} gutterBottom sx={{ marginTop: 2 }}>
              {'Task schedule'}
            </Typography>
            <DataGrid
              columns={[
                {
                  ...scheduleColDefs.monthColumn,
                  valueGetter: (value, row: Task) =>
                    months
                      .map((_, month) => ((row.schedule?.month & Math.pow(2, month)) === 0 ? -1 : month))
                      .filter((v) => v >= 0)
                      .map((v) => `${v}`),
                },
                {
                  ...scheduleColDefs.dayColumn,
                  valueGetter: (value, row: Task) => row.schedule?.day,
                },
                {
                  ...scheduleColDefs.workDayColumn,
                  valueGetter: (value, row: Task) => row.schedule?.workDay,
                },
                {
                  ...scheduleColDefs.occurrenceColumn,
                  valueGetter: (value, row: Task) =>
                    occurrencies
                      .map((_, occurrence) =>
                        (row.schedule?.occurrence & Math.pow(2, occurrence)) === 0 ? -1 : occurrence,
                      )
                      .filter((v) => v >= 0)
                      .map((v) => `${v}`),
                },
                {
                  ...scheduleColDefs.weekDayColumn,
                  valueGetter: (value, row: Task) =>
                    weekDays
                      .map((_, weekDay) => ((row.schedule?.weekDay & Math.pow(2, weekDay)) === 0 ? -1 : weekDay))
                      .filter((v) => v >= 0)
                      .map((v) => `${v}`),
                },
              ]}
              rows={[ganttTask.task]}
              density="compact"
              hideFooter
              disableRowSelectionOnClick
              disableAggregation
              disableColumnMenu
              disableColumnReorder
            />
          </Box>
        )}
      </Popover>
    </Stack>
  );
}

export function useGanttTaskDescriptionColDef<T extends GanttTask>(onTime: number | undefined): GridColDef {
  return useMemo(() => {
    const colDef: GridColDef<T> = {
      type: 'string',
      field: 'description',
      headerName: 'Description',
      width: 380,
      cellClassName: 'description-cell',
      headerAlign: 'right',
      hideable: false,
      renderHeader: () => (
        <Typography variant="h6">
          {'On time: '}
          {onTime === undefined ? '—' : <Percent value={onTime} sx={{ fontWeight: 'bold' }} />}
        </Typography>
      ),
      valueGetter: (value, row: GanttTask) => row.task.description,
      renderCell: ({ row }: GridRenderCellParams<T, string>) => <TaskDescriptionCell ganttTask={row} />,
    };
    return colDef;
  }, [onTime]);
}
