import { memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui/src/components/errors/ErrorAlert';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Category, CategorySchema } from '../../../entities/Category';
import { Process } from '../../../entities/Process';
import { useCreateCategoryMutation, useUpdateCategoryMutation } from '../../../services/categoryApi';

interface EditCategoryDialogProps {
  disabled: boolean;
  category?: Omit<Category, 'processId'>;
  onClose: () => void;
  processId: Process['id'];
}

function EditCategoryDialogComponent(props: EditCategoryDialogProps) {
  const { category, onClose, disabled, processId } = props;
  const [open, setOpen] = useState(false);
  const [createCategory, createCategoryStatus] = useCreateCategoryMutation();
  const [updateCategory, updateCategoryStatus] = useUpdateCategoryMutation();

  const { control, reset, handleSubmit, setValue } = useForm<Pick<Category, 'name'>>({
    defaultValues: { name: '' },
    resolver: zodResolver(CategorySchema.pick({ name: true })),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = (data: Pick<Category, 'name'>) => {
    if (props.category) {
      updateCategory({ id: props.category.id, name: data.name, processId });
    } else {
      createCategory({ name: data.name, processId });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (category) {
      setOpen(true);
      setValue('name', category.name);
    }
  }, [category, setValue]);

  const error = createCategoryStatus.error ?? updateCategoryStatus.error;

  return (
    <>
      <Button variant="outlined" color="primary" size="small" onClick={handleClickOpen} disabled={disabled}>
        {'Add category'}
      </Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        TransitionProps={{
          onExited: () => {
            reset();
            createCategoryStatus.reset();
            updateCategoryStatus.reset();
            onClose();
          },
        }}
      >
        <form
          onSubmit={handleSubmit(handleCreate)}
          onReset={() => {
            reset();
            handleClose();
          }}
        >
          <DialogTitle>{`${category ? 'Edit' : 'Add'} Category`}</DialogTitle>
          <DialogContent>
            {error && <ErrorAlert error={error} sx={{ marginBottom: 2 }} />}
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField autoFocus margin="dense" id="name" label="Category name" type="text" fullWidth {...field} />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button type="reset">{'Cancel'}</Button>
            <LoadingButton type="submit" variant="contained" loading={createCategoryStatus.isLoading}>
              {category ? 'Update' : 'Add'}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export const EditCategoryDialog = memo(EditCategoryDialogComponent);
