import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { FullPageProgress } from '../../components/Progress';
import { User, UserRoleName } from '../../entities/User';
import { GanttTasksUserStats, useGanttTasksStats } from '../../hooks/useGanttTasksStats';
import { useReadUserListQuery } from '../../services/userApi';
import { UserStatsButtonList, UserStatsButton } from './components/UserStatsButtonList';
import { OverviewInnerLayout } from './layout/OverviewInnerLayout';
import { useOverviewGanttTasks } from './OverviewGanttTasks';
import { useOverviewQueryParams } from './overviewQueryParams';

export function CompletionByUser(): JSX.Element {
  const { searchParams, excludeFutureTasks } = useOverviewQueryParams();
  const { ganttTasks, error: ganttTasksError, isLoading: ganttTasksIsLoading } = useOverviewGanttTasks();
  const userList = useReadUserListQuery();

  const params = useParams<{ processId?: string; scenarioName?: string; subprocessId?: string }>();

  const ganttTasksStats = useGanttTasksStats(
    ganttTasks,
    {
      processId: params.processId ? Number(params.processId) : undefined,
      scenarioName: params.scenarioName,
      subprocessId: params.subprocessId ? Number(params.subprocessId) : undefined,
    },
    { excludeFutureTasks, ignoreOtherScenario: true },
  );

  const [financeByUser, usersByUser] = useMemo(() => {
    const financeByUser: Array<{ user: User; stats: GanttTasksUserStats }> = [];
    const usersByUser: Array<{ user: User; stats: GanttTasksUserStats }> = [];
    for (const username in ganttTasksStats.byUser) {
      const user = userList.data?.find((u) => u.username === username);
      if (user) {
        if (user.role === UserRoleName.GUEST) {
          usersByUser.push({ user, stats: ganttTasksStats.byUser[username] });
        } else {
          financeByUser.push({ user, stats: ganttTasksStats.byUser[username] });
        }
      }
    }

    financeByUser.sort((a, b) => (a.user.lastName ?? '')?.localeCompare(b.user.lastName ?? ''));
    usersByUser.sort((a, b) => (a.user.lastName ?? '')?.localeCompare(b.user.lastName ?? ''));

    return [financeByUser, usersByUser];
  }, [ganttTasksStats.byUser, userList.data]);

  if (userList.isLoading) {
    return <FullPageProgress />;
  }

  return (
    <OverviewInnerLayout error={ganttTasksError ?? userList.error} inProgress={ganttTasksIsLoading}>
      {!ganttTasksIsLoading ? (
        financeByUser.length === 0 && usersByUser.length === 0 ? (
          <Typography variant="h6" color="text.disabled" textAlign="center" paddingY={4}>
            No user to show
          </Typography>
        ) : (
          <>
            {financeByUser.length ? (
              <UserStatsButtonList title="Finance">
                {financeByUser.map(({ user, stats }) => (
                  <UserStatsButton
                    key={user.username}
                    user={user}
                    stats={stats}
                    to={`./${user.username}/tasks?${searchParams}`}
                  />
                ))}
              </UserStatsButtonList>
            ) : null}
            {usersByUser.length ? (
              <UserStatsButtonList title="Suppliers">
                {usersByUser.map(({ user, stats }) => (
                  <UserStatsButton
                    key={user.username}
                    user={user}
                    stats={stats}
                    to={`./${user.username}/tasks?${searchParams}`}
                  />
                ))}
              </UserStatsButtonList>
            ) : null}
          </>
        )
      ) : null}
    </OverviewInnerLayout>
  );
}
