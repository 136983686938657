import { Department, DepartmentSchema } from '../entities/Department';
import { api } from './baseApi';

const url = 'departments';

const departmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readDepartmentList: builder.query<Department[], void>({
      query: () => ({ url }),
      transformResponse: (data) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mockedData = (data as any[]).map((d) => ({
          ...d,
          id: d.id,
        }));
        return DepartmentSchema.array().parse(mockedData);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useReadDepartmentListQuery } = departmentApi;
